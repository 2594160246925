import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import hi from "asset/company/hi.jpeg";
import ceo from "asset/company/ceo.jpeg";
import challenge from "asset/company/challenge.png";
import chips from "asset/company/chips.jpeg";
import connect from "asset/company/connect.jpeg";
import investors from "asset/company/investors.jpeg";
import ipo1 from "asset/company/ipo1.jpeg";
import ipo2 from "asset/company/ipo2.jpeg";
import ipo3 from "asset/company/ipo3.jpeg";
import office from "asset/company/office.jpeg";
//careers
import tractor from "asset/careers/tractor.webp";

import meeting1 from "asset/careers/meeting1.jpeg";
import meeting2 from "asset/careers/meeting2.jpeg";
import office9 from "asset/careers/office9.jpeg";
import office8 from "asset/careers/office8.jpeg";
import office10 from "asset/careers/office10.jpeg";
import test3 from "asset/careers/test3.jpeg";
import test4 from "asset/careers/test4.jpeg";
import test5 from "asset/careers/test5.jpeg";
import test6 from "asset/careers/test6.jpeg";
import test7 from "asset/careers/test7.jpeg";
import work8 from "asset/careers/work8.jpeg";
import work9 from "asset/careers/work9.jpeg";
import work10 from "asset/careers/work10.jpeg";
import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
import "./test.css";
function Company(props) {
  const { t } = useTranslation();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    console.log("렌더를 어떻게 유발 시키지", lang);
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   if (fixedBoxRef.current) {
  //     setRefState(fixedBoxRef);
  //   }
  //   if (textBoxRef.current) {
  //     setTextRefState(textBoxRef);
  //   }
  //   if (lastTextRef.current) {
  //     setLastTextRefState(lastTextRef);
  //   }
  //   if (boxRef.current) {
  //     setBoxRefState(boxRef);
  //   }
  // }, [fixedBoxRef]);

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgWidthFitBox.lgtheme, height: height ?? "" }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        {props.children}
      </Container>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}>
        {children}
      </Box>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }

  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  return (
    <React.Fragment>
      {/* 빈공간 */}
      {/* 스티키 헤더 */}
      <div id="two">
        <p>I speak</p>
        <div class="box">
          <ul>
            <li class="item-1">~600um</li>
            <li class="item-2">450um</li>
            <li class="item-3">300um</li>
            <li class="item-4">100um</li>
            <li class="item-2">450um</li>
            <li class="item-3">300um</li>
            <li class="item-4">100um</li>
            <li class="item-5">30</li>
          </ul>
        </div>
      </div>
      {/* <BasicAppBar /> */}
      <EmptySpace height={matches ? "300px" : "83px"} />
      {/* <ImgWidthFit src={hi} /> */}
      {/* <ImgScaleFit src={connect} /> */}
      <div style={{ width: "100%", minHeight: "500px" }}>
        <img
          src={tractor}
          style={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            objectFit: "cover",
          }}
        ></img>
      </div>

      <EmptySpace height={matches ? "900px" : "900px"} />

      <ImgWidthFit src={hi} />
      <EmptySpace height={matches ? "200px" : "28px"} />

      <CustomContainer>
        <PaddingBox>
          <Typography variant="h2">
            {t(`하이딥의 불꽃같은 여정에 당신을 초대합니다.

언제나 근본적인 '어떻게?' 를 찾아가는 하이딥은
함께하는 사람들의 가치를 가장 중요하게 생각합니다.`)}
          </Typography>
          <EmptySpace height={matches ? "100px" : "36px"} />
          <Typography variant="h4Alias">
            {t(`"

누구나 이미 알고 있다고



모두가 방법을 찾을 수는 없습니다.



길이 보이지 않는다면 



내딛는 첫 걸음으로 길이 열립니다.

`)}
          </Typography>

          <Typography variant="h4Alias">
            {t(`"

여러 사람들의 다양한 관점과 인사이트를 통해



새로운 방법을 찾는 짜릿한 여정에 당신을 초대합니다.


`)}
          </Typography>
          <EmptySpace height={matches ? "30px" : "9px"} />
          <Typography variant="h2Alias">
            {t("높은 곳에서, 넓게 보고")}
          </Typography>
          <EmptySpace height={matches ? "15px" : "0px"} />

          <Typography variant="h2Alias">{t("더 깊게 생각합니다.")}</Typography>
          <EmptySpace height={matches ? "60px" : "40px"} />
          {matches ? (
            <>
              <Typography variant="body3Alias">
                {t(
                  `하이딥은 우리가 더 편하고 더 유용하도록 개발해 내는 기술과 제품이`
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "사람의 삶에 도움이 되고 이로 인해 조금이라도 더 나은 세상을 만들어갈 수 있기를 바랍니다."
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "하이딥의 사람들은 모든 문제를 있는 그대로 마주하고 함께 깊이 고민하고 토론하여"
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "그 핵심을 파악하고 근본적인 해결책을 찾아내려는 노력을 합니다."
                )}
              </Typography>
              <EmptySpace height={matches ? "34px" : "40px"} />
              <Typography variant="body3Alias">
                {t(
                  "결국 이렇게 확보되는 해결책만이 혁신으로 이어질 수 있다는 것을 알고"
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "이러한 과정이 쉽지 않지만 이 과정 동안 우리 모두가 회사와 함께 몇 단계를 훌쩍 넘어"
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t("앞으로 나아갈 수 있다는 믿음을 가지고 있습니다.")}
              </Typography>
              <Typography variant="body3Alias">
                {t("실패를 두려워하지 않고 끊임없이 도전하려는 하이딥 입니다.")}
              </Typography>
            </>
          ) : (
            <>
              {" "}
              <Typography variant="body3Alias">
                {t(
                  `하이딥은 우리가 더 편하고 더 유용하도록 개발해 내는 ${snl()}기술과 제품이 사람의 삶에 도움이 되고 이로 인해
              조금이라도 더 나은 세상을 만들어갈 수 있기를 바랍니다.
              하이딥의 사람들은 모든 문제를 있는 그대로 마주하고 ${snl()}함께 깊이 고민하고 토론하여 그 핵심을 파악하고${snl()}근본적인 해결책을 찾아내려는 노력을 합니다.`
                )}
              </Typography>
              <Typography variant="body3Alias">{t(``)}</Typography>
              <EmptySpace height={matches ? "60px" : "20px"} />
              <Typography variant="body3Alias">
                {t(
                  `이렇게 확보되는 해결책만이 혁신으로 이어질 수 있다는 것을 알고${snl()}이러한 과정이 쉽지 않지만 이 과정 동안 우리 모두가 회사와 함께 ${snl()}몇 단계를 훌쩍 넘어 앞으로 나아갈 수 있다는 믿음을 가지고 있습니다.${snl()}실패를 두려워하지 않고 끊임없이 도전하려는 하이딥 입니다.`
                )}
              </Typography>
            </>
          )}
        </PaddingBox>
      </CustomContainer>

      <Box sx={{}}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <ImgWidthFit src={meeting1} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImgWidthFit src={meeting2} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{}}>
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
          <Grid item xs={12} md={12}>
            {/* <ImgWidthFit src={test3} /> */}
            <div
              style={{
                overflow: "hidden",
                width: "100%",
                height: "300px",
                position: "relative",
                aspectRatio: "1/1",
              }}
            >
              <img
                src={test3}
                style={{
                  position: "absolute",
                  top: "50%",
                  objectFit: "cover",
                  objectPosition: "",
                  left: "50%",
                  msTransform: " translate(-50%, -50%)",
                  transform: "translate(-50%, -50%)",
                }}
              ></img>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <ImgScaleFit src={test4} /> */}
            <div
              style={{
                width: "1000px",
                height: "1000px",
                backgroundColor: "red",
              }}
            ></div>
          </Grid>
          <Grid item xs={12} md={6}>
            <ImgWidthFit src={test5} />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <ImgWidthFit src={test6} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImgWidthFit src={test7} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImgWidthFit src={test6} />
          </Grid> */}
        </Grid>
      </Box>
      <EmptySpace height={matches ? "200px" : "110px"} />
      <ImgWidthFit src={office} />
      <EmptySpace height={matches ? "180px" : "30px"} />

      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">{t("Leadership")}</Typography>
          <EmptySpace height={matches ? "100px" : "38px"} />

          <Typography variant="h2Alias">{t("세상을 바꿀 혁신이")}</Typography>
          <EmptySpace height={matches ? "15px" : "0px"} />

          <Typography variant="h2Alias">
            {t("하이딥에서 시작되고 있습니다")}
          </Typography>
          <EmptySpace height={matches ? "22px" : "20px"} />

          <Typography variant="h4Alias">{t("CEO / 고범규")}</Typography>
          <EmptySpace height={matches ? "50px" : "40px"} />
          {match(
            <Box sx={{}}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <ImgWidthFit src={ceo} />
                </Grid>
                <Grid item xs={0} md={6}></Grid>
              </Grid>
            </Box>,
            ""
          )}
        </PaddingBox>
      </CustomContainer>
      {/* img */}

      {match("", <ImgWidthFit src={ceo} />)}
      {onlyLg(
        <CustomContainer>
          <PaddingBox>
            {/* img */}
            <EmptySpace height={matches ? "50px" : "40px"} />
            <Typography variant="body4">
              {t(
                "하이딥은 약 18억 여개의 전 세계 모바일 기기 시장을 목표로 합니다.11"
              )}
            </Typography>
            <Typography variant="body4">
              {t(
                "하이딥은 모바일 기기의 핵심인 User Interface solution 을 제공합니다.              "
              )}
            </Typography>
            <Typography variant="body4">
              {t("하이딥은 모바일 기기 사용의 핵심인 사용자가 더 쓰기 편하고")}
            </Typography>
            <Typography variant="body4">
              {t("더 유용하게 만들어주는 기술을 혁신적인 방법으로 제공합니다.")}
            </Typography>
            <Typography variant="body4">
              {t(
                "전례가 없는 기술에 대한 도전을 통하여 파괴적 혁신을 추구합니다."
              )}
            </Typography>
            <EmptySpace height={matches ? "50px" : "40px"} />

            <Typography variant="body3Alias">
              {t(
                "고범규 대표이사(Ph.D.)는 22년이 넘는 기술기반 비즈니스의 성공 경험과 노하우를 바탕으로, 2010년 5월에 ㈜하이딥을 창립하였습니다.              "
              )}
            </Typography>
            <Typography variant="body3Alias">
              {t(
                "㈜하이딥 창립 이전에는 Mobile TV 방송서비스 시장에 대한 선견지명으로, 2000년 인티그런트테크놀로지즈를 설립하고, 위성 및 지상파 DMB, ISDB-T, DVB-H and CMMB를 포함한, RF CMOS mobile TV tuner IC를 세계 최초로 개발하고 론칭하였습니다. 세계 최초의 혁신적인 RF CMOS 튜너칩은 전 세계에 4천만개이상 공급되었고, 설립후 6년 동안 200억원의 투자금을 유치하였고, 임직원은 6명에서 90여명으로 늘어났으며, 이들과 함께 매출 400억원의 대단한 성과를 이루었습니다. 2006년 6월 인티그런트테크놀로지즈는 160MUSD(한화 1,700억원상당)규모로 미국 아나로그디바이스(ADI)에 성공적으로 인수합병이 되었으며, 합병 후 고범규 대표이사는 미국 ADI의 고속신호 처리조직에 편입돼 해당 제품개발과 전략을 담당하는 Director로 근무하였습니다. 고범규 대표이사는 카이스트에서 전자공학 석사와 박사 과정을 거친 후, 삼성전자에서 반도체시스템 LSI 사업부에서 RF(Radio Frequency) Analog IC Design 책임연구원으로 근무하였습니다.              "
              )}
            </Typography>

            <EmptySpace height={matches ? "200px" : "40px"} />
          </PaddingBox>
        </CustomContainer>
      )}
      {onlySm(
        <CustomContainer>
          <PaddingBox>
            {/* img */}
            <EmptySpace height={matches ? "50px" : "38px"} />
            <Typography variant="body4">
              {t(
                `하이딥은 약 18억 여개의 전 세계 모바일 기기 시장을 목표로 합니다.
              하이딥은 모바일 기기의 핵심인
              User Interface solution 을 제공합니다.
              하이딥은 모바일 기기 사용의 핵심인 사용자가 더 쓰기 편하고
              더 유용하게 만들어주는 기술을 혁신적인 방법으로 제공합니다.
              전례가 없는 기술에 대한 도전을 통하여 파괴적 혁신을 추구합니다.
              `
              )}
            </Typography>

            <EmptySpace height={matches ? "50px" : "40px"} />

            <Typography variant="body3Alias">
              {t(
                "고범규 대표이사(Ph.D.)는 22년이 넘는 기술기반 비즈니스의 성공 경험과 노하우를 바탕으로, 2010년 5월에 ㈜하이딥을 창립하였습니다.              "
              )}
            </Typography>

            <Typography variant="body3Alias">
              {t(
                `㈜하이딥 창립 이전에는 Mobile TV 방송서비스 시장에 대한 선견지명으로, 2000년 인티그런트테크놀로지즈를 설립하고, 위성 및 지상파 DMB, ISDB-T, DVB-H and CMMB를 포함한, RF CMOS mobile TV tuner IC를 세계 최초로 개발하고 론칭하였습니다.`
              )}
            </Typography>
            <EmptySpace height={matches ? "200px" : "22.5px"} />

            <Typography variant="body3Alias">
              {t(
                `세계 최초의 혁신적인 RF CMOS 튜너칩은 전 세계에 4천만개이상 공급되었고, 설립후 6년 동안 200억원의 투자금을 유치하였고, 임직원은 6명에서 90여명으로 늘어났으며, 이들과 함께 매출 400억원의 대단한 성과를 이루었습니다.`
              )}
            </Typography>

            <EmptySpace height={matches ? "200px" : "22.5px"} />

            <Typography variant="body3Alias">
              {t(
                `2006년 6월 인티그런트테크놀로지즈는 160MUSD(한화 1,700억원상당)규모로 미국 아나로그디바이스(ADI)에 성공적으로 인수합병이 되었으며, 합병 후 고범규 대표이사는 미국 ADI의 고속신호 처리조직에 편입돼 해당 제품개발과 전략을 담당하는 Director로 근무하였습니다. 고범규 대표이사는 카이스트에서 전자공학 석사와 박사 과정을 거친 후, 삼성전자에서 반도체시스템 LSI 사업부에서 RF(Radio Frequency) Analog IC Design 책임연구원으로 근무하였습니다.`
              )}
            </Typography>

            <EmptySpace height={matches ? "200px" : "100px"} />
          </PaddingBox>
        </CustomContainer>
      )}

      <ImgScaleFit height={matches ? "692px" : "268.63px"} src={chips} />
      <EmptySpace height={matches ? "200px" : "40px"} />

      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">{t("Investor Relations")}</Typography>

          <EmptySpace height={matches ? "30px" : "40px"} />
        </PaddingBox>
      </CustomContainer>
      {/* img */}
      <ImgScaleFit height={matches ? "157px" : "77px"} src={investors} />
      <EmptySpace height={matches ? "100px" : "110px"} />

      {/* img */}

      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">{t("Stock Price")}</Typography>
          <EmptySpace height={matches ? "100px" : "40px"} />

          {/* img */}
          {/* apple stock*/}

          <Box
            sx={
              matches
                ? {
                    fontFamily: "-apple-system, SFProDisplay, sans-serif",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <Box
              sx={
                matches
                  ? {
                      width: "50%",
                    }
                  : {}
              }
            >
              <Typography
                sx={
                  matches
                    ? {
                        fontSize: "48px",
                        lineHeight: "1",
                        fontWeight: "600",
                        letterSpacing: "-0.005em",
                        color: "#1a1e24",
                        padding: "0",
                      }
                    : {}
                }
              >
                {t("Stock Price")}
              </Typography>
              <Typography
                variant="body3Alias"
                sx={
                  matches
                    ? {
                        fontSize: "21px",
                        letterSpacing: "-0.011em",
                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.2",

                        padding: "0",
                      }
                    : {}
                }
              >
                {t("January 26, 2023 4:00 p.m. ET")}
              </Typography>

              <EmptySpace height={matches ? "26px" : "40px"} />

              <Typography
                sx={
                  matches
                    ? {
                        fontSize: "17px",

                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.5",
                        color: "#111111",
                        fontFamily: "-apple-system, SFProDisplay, sans-serif",
                        letterSpacing: "-0.011em",
                      }
                    : {}
                }
              >
                {t(
                  "The stock information provided is for informational purposes only and is not intended for trading purposes. The stock information and charts are provided by Tickertech, a third party service, and Apple does not provide information to this service."
                )}
              </Typography>
            </Box>
            <Box
              sx={
                matches
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }
                  : {}
              }
            >
              <Typography
                sx={
                  matches
                    ? {
                        textAlign: "right",

                        fontSize: "21px",
                        letterSpacing: "-0.011em",

                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1",
                        color: "#111111",
                      }
                    : {}
                }
              >
                {t("Pricing delayed by 20 minutes")}
              </Typography>
              <Typography
                sx={
                  matches
                    ? {
                        textAlign: "right",

                        fontSize: "44px",
                        letterSpacing: "-0.011em",

                        fontWeight: "600",
                        color: "#1a1e24",
                        lineHeight: "1.3",
                        color: "#111111",
                        letterSpacing: "-0.011em",
                      }
                    : {}
                }
              >
                {t("$155.00")}
              </Typography>
              <EmptySpace height={matches ? "8px" : "40px"} />

              <Typography
                sx={
                  matches
                    ? {
                        textAlign: "right",

                        fontSize: "17px",
                        letterSpacing: "-0.011em",

                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.5",
                        color: "#111111",
                      }
                    : {}
                }
              >
                {t("Change")}
              </Typography>
              <EmptySpace height={matches ? "8px" : "40px"} />

              <div>
                <Typography
                  sx={
                    matches
                      ? {
                          fontSize: "44px",
                          letterSpacing: "-0.011em",

                          fontWeight: "500",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                          backgroundColor: "#1d882e",
                          color: "#fff",
                          padding: "10px 16px",
                          borderRadius: "5px",
                          display: "inline-block",
                          fontSize: "44px",

                          letterSpacing: "-0.002em",
                        }
                      : {}
                  }
                >
                  {t("+ $0.85")}
                </Typography>
              </div>
              <EmptySpace height={matches ? "8px" : "40px"} />

              <Typography
                sx={
                  matches
                    ? {
                        fontSize: "17px",
                        letterSpacing: "-0.011em",

                        fontWeight: "400",
                        color: "#1a1e24",
                        color: "#111111",
                      }
                    : {}
                }
              >
                {t("Volume")}
              </Typography>
              <EmptySpace height={matches ? "8px" : "40px"} />

              <Typography
                sx={
                  matches
                    ? {
                        fontSize: "44px",
                        letterSpacing: "-0.011em",

                        fontWeight: "500",
                        color: "#1a1e24",
                        lineHeight: "1",
                        color: "#111111",
                      }
                    : {}
                }
              >
                {t("98.9M")}
              </Typography>
            </Box>
          </Box>
          <EmptySpace height={matches ? "70px" : "40px"} />

          {/* img */}
        </PaddingBox>
      </CustomContainer>
      <EmptySpace height={matches ? "100px" : "40px"} />

      {onlyLg(
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <ImgWidthFit height={"692px"} src={ipo1} />
          <ImgWidthFit height={"692px"} src={ipo2} />
          <ImgWidthFit height={"692px"} src={ipo3} />
        </Box>
      )}
      <EmptySpace height={matches ? "100px" : "40px"} />

      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">{t("Finantial Data")}</Typography>
          <EmptySpace height={matches ? "25px" : "40px"} />
          <Typography
            variant={"body4"}
            sx={matches ? {} : { fontSize: "12px" }}
          >
            {t("상세 재무자료는 금감원 (DART) 공시자료를 참고해 주세요.")}
          </Typography>

          {/* img */}
        </PaddingBox>
        <EmptySpace height={matches ? "200px" : "40px"} />

        <Box sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <FadeInSection>
                <EmptySpace height={matches ? "0px" : "60px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          color: "rgb(0, 0, 0)",
                          fontWeight: "700",
                          fontSize: "48px",
                          lineHeight: "1.2",
                          letterSpacing: "0px",
                        }
                      : {
                          color: "#1a1e24",
                          fontWeight: "600",
                          fontSize: "26px",
                          lineHeight: "1.2",
                          letterSpacing: "0px",
                          padding: "0 15px",
                        }
                  }
                >
                  {t("공고")}
                </Typography>
              </FadeInSection>
              <EmptySpace height={matches ? "0px" : "40px"} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Box>
                <Accordion
                  disableGutters={true}
                  elevation={0}
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      borderTop: "1px solid black",
                    },
                  }}
                >
                  <AccordionSummary
                    disableGutters={true}
                    elevation={0}
                    square={true}
                    sx={{
                      "&.MuiAccordion-root": {
                        borderTop: "1px solid black",
                      },
                    }}
                    expandIcon={
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", margin: "0" }}
                      >
                        ∨
                      </Typography>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography
                      sx={
                        matches
                          ? { fontWeight: "bold" }
                          : {
                              letterSpacing: "-1px",
                              fontWeight: "700",
                              color: "#1a1e24",
                              fontSize: "13px",
                              padding: "5px 0px",
                            }
                      }
                    >
                      {t("(주) 하이딥 공시정보관리규정 공고")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={matches ? { padding: "30px 0px" } : {}}>
                    <Typography variant="body2">
                      {
                        "  <합병보고총회에 갈음하는 공고> ① 엔에이치기업인수목적18호 주식회사(갑)는 2022년3월23일 개최한 임시주주총회에서, 주식회사 하이딥(을)은 2022년3월23일 임시주주총회 결의로 (갑)은 (을)을 합병하여, 그 권리의무 일체를 승계하고 (을)은 해산하기로 결의하였습니다."
                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  disableGutters={true}
                  elevation={0}
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      borderTop: "1px solid black",
                    },
                  }}
                >
                  <AccordionSummary
                    disableGutter={true}
                    sx={
                      matches
                        ? {
                            padding: "30px 0px ",
                            margin: "0",

                            "& .MuiAccordionSummary-content": {
                              margin: "0",
                            },
                          }
                        : {}
                    }
                    expandIcon={
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", margin: "0" }}
                      >
                        ∨
                      </Typography>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={matches ? { fontWeight: "bold" } : {}}>
                      {t("전환우선주 일괄전환에 따른 전환기준일 공고")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={matches ? { padding: "30px 0px" } : {}}>
                    <Typography variant="body2">
                      {
                        "  <합병보고총회에 갈음하는 공고> ① 엔에이치기업인수목적18호 주식회사(갑)는 2022년3월23일 개최한 임시주주총회에서, 주식회사 하이딥(을)은 2022년3월23일 임시주주총회 결의로 (갑)은 (을)을 합병하여, 그 권리의무 일체를 승계하고 (을)은 해산하기로 결의하였습니다."
                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  disableGutters={true}
                  elevation={0}
                  square={true}
                  sx={{
                    "&.MuiAccordion-root": {
                      borderTop: "1px solid black",
                    },
                  }}
                >
                  <AccordionSummary
                    disableGutters={true}
                    elevation={0}
                    square={true}
                    sx={{
                      "&.MuiAccordion-root": {
                        borderTop: "1px solid black",
                      },
                    }}
                    expandIcon={
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", margin: "0" }}
                      >
                        ∨
                      </Typography>
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography sx={matches ? { fontWeight: "bold" } : {}}>
                      {t("(주) 하이딥 공시정보관리규정 공고")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={matches ? { padding: "30px 0px" } : {}}>
                    <Typography variant="body2">
                      {
                        "  <합병보고총회에 갈음하는 공고> ① 엔에이치기업인수목적18호 주식회사(갑)는 2022년3월23일 개최한 임시주주총회에서, 주식회사 하이딥(을)은 2022년3월23일 임시주주총회 결의로 (갑)은 (을)을 합병하여, 그 권리의무 일체를 승계하고 (을)은 해산하기로 결의하였습니다."
                      }
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </Grid>

          {/* 라인 */}
          {/* 아코디언 */}
        </Box>
      </CustomContainer>
      <EmptySpace height={matches ? "100px" : "40px"} />

      {/* 공고 */}

      <ImgWidthFit src={challenge} />
      <EmptySpace height={matches ? "100px" : "40px"} />
      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">
            {t("Quality and Env. Policy")}
          </Typography>
          <EmptySpace height={matches ? "50px" : "40px"} />

          <Typography variant="bodyGray">{t("품질 방침")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "우리는 고객에게 최고의 품질과 서비스를 제공하기 위하여 다음의 품질 원칙을 추구합니다."
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t(
              "일관성 있는 품질 및 신뢰성을 보장하기 위하여 업무 프로세스를 준수 합니다."
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t(
              "무결점 품질을 달성하기 위하여 사전 품질관리 및 개선 활동을 지속합니다.              "
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t(
              "임직원들의 직무 전문성 개발을 적극적으로 장려하고 교육 프로그램을 제공합니다.              "
            )}
          </Typography>

          <EmptySpace height={matches ? "50px" : "40px"} />

          <Typography variant="bodyGray">{t("환경 방침")}</Typography>

          <Typography variant="body3Alias">
            {t(
              "우리는 인간과 자연에게 미칠 수 있는 유해한 영향을 최소화하기 위하여 다음의 환경 원칙을 추구합니다.              "
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t("국가 환경 법규 및 고객의 환경관련 요구사항을 준수합니다.")}
          </Typography>
          <Typography variant="body3Alias">
            {t(
              "자원의 효율적인 사용과 정기적인 평가를 통하여 환경 영향을 최소화하는 것을 추구합니다."
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t("친환경 제품 생산을 위한 노력을 지속합니다.")}
          </Typography>
        </PaddingBox>
      </CustomContainer>
      <EmptySpace height={matches ? "100px" : "145px"} />
      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">
            {t("Conflict Mineral Policy")}
          </Typography>
          <EmptySpace height={matches ? "50px" : "40px"} />
          {onlyLg(
            <>
              <Typography variant="bodyGray">
                {t("하이딥 분쟁광물 정책")}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "우리는 Dodd-Frank Act를 지지하며, 우리 제품의 구성 소재가 DRC지역으로부터 기원 되지 않았음을 보장하기 위하여 아래의 원칙을 준수합니다."
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "모든 협력업체에게 문서화 된 실사 자료(EICC-GeSI 분쟁광물 조사 양식)의 제출을 요구합니다. "
                )}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  "모든 협력업체에게 분쟁광물과 관련된 정책 및 경영시스템을 도입하도록 장려합니다."
                )}
              </Typography>
            </>
          )}
          {onlySm(
            <>
              <Typography variant="bodyGray">
                {t("하이딥 분쟁광물 정책")}
              </Typography>
              <Typography variant="body3Alias">
                {t(
                  `우리는 Dodd-Frank Act를 지지하며,
                  우리 제품의 구성 소재가 DRC지역으로부터 기원 되지 않았음을
                  보장하기 위하여 아래의 원칙을 준수합니다.
                  모든 협력업체에게 문서화 된 실사 자료
                  (EICC-GeSI 분쟁광물 조사 양식)의 제출을 요구합니다. 
                  모든 협력업체에게 분쟁광물과 관련된 정책 및
                  경영시스템을 도입하도록 장려합니다.`
                )}
              </Typography>
            </>
          )}
        </PaddingBox>
      </CustomContainer>
      <EmptySpace height={matches ? "100px" : "145px"} />
      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">{t("Ethical management")}</Typography>
          <EmptySpace height={matches ? "50px" : "40px"} />
          <Typography variant="bodyGray">{t("기본 윤리")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "임직원은 자신의 권한과 책임을 명확히 인식하고 정직과 신뢰의 원칙에 입각하여 성실하게 업무를 수행해야 한다."
            )}
          </Typography>
          <EmptySpace height={matches ? "30px" : "22.5px"} />
          <Typography variant="bodyGray">{t("고객에 대한 책임")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "고객의 요구사항을 정확히 파악하기 위한 모든 노력을 다하여야 하며, 고객의 정당한 요구와 합리적인 제안을 적극적으로 수용하고, 고객과의 약속은 반드시 이행해야 한다."
            )}
            {/* 고객 */}
          </Typography>
          <EmptySpace height={matches ? "30px" : "22.5px"} />
          <Typography variant="bodyGray">{t("구성원에 대한 책임")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "회사는 임직원의 인격을 존중하고 역량과 성과에 따라 공정하고 합리적으로 대우한다.              "
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t(
              "회사는 임직원이 상호 신뢰와 자긍심을 갖고, 도전정신과 창의성을 발현하여, 동료와 더불어 행복을 추구할 수 있도록 최선의 노력을 다해야 한다."
            )}
          </Typography>
          <EmptySpace height={matches ? "30px" : "22.5px"} />
          <Typography variant="bodyGray">{t("주주에 대한 책임")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "회사는 끊임없는 혁신과 투명하고 효율적인 경영으로 기업가치를 극대화하며, 그 성과를 주주와 공유한다."
            )}
          </Typography>{" "}
          <EmptySpace height={matches ? "30px" : "22.5px"} />
          <Typography variant="bodyGray">{t("협력회사와의 관계")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "회사는 협력회사를 상호 신뢰의 기반 위에서 고객행복이라는 공동가치를 추구하는 전략적 동반자로 인식한다."
            )}
          </Typography>{" "}
          <EmptySpace height={matches ? "30px" : "22.5px"} />
          <Typography variant="bodyGray">{t("사회에 대한 책임")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "회사는 환경보호 관련 국제 기준 및 관련 법령, 내부 규정 등을 준수하고, 환경친화적 경영을 실천한다.              "
            )}
          </Typography>{" "}
          <EmptySpace height={matches ? "30px" : "22.5px"} />
          <Typography variant="bodyGray">
            {t("윤리상담·신고 및 제보자 보호")}
          </Typography>
          <Typography variant="body3Alias">
            {t(
              "회사는 상담·신고자 보호 및 상담·신고 장려를 위해 최선의 노력을 다한다.              "
            )}
          </Typography>
        </PaddingBox>
      </CustomContainer>
      <EmptySpace height={matches ? "200px" : "110px"} />

      <ImgScaleFit height={"919px"} src={connect} />
      <EmptySpace height={matches ? "300px" : "40px"} />

      <CustomContainer>
        <PaddingBox>
          <Typography variant="h1Alias">{t("Contact us")}</Typography>
          <EmptySpace height={matches ? "50px" : "40px"} />

          <Typography variant="bodyGray">{t("ADDRESS")}</Typography>
          <Typography variant="body3Alias">
            {t(
              "- 경기도 성남시 분당구 대왕판교로644번길 49 DTC타워 10층 하이딥"
            )}
          </Typography>
          <Typography variant="body3Alias">
            {t("- 中国 深圳龙华新区民治星河盛世B1-2 601室 518131")}
          </Typography>

          <EmptySpace height={matches ? "50px" : "44px"} />

          <Typography variant="bodyGray">{t("TEL")}</Typography>
          <Typography variant="body3Alias">{t("+82-31-717-5775")}</Typography>

          <EmptySpace height={matches ? "50px" : "44px"} />

          <Typography variant="bodyGray">{t("E - MAIL")}</Typography>
          <Typography variant="body3Alias">
            {t("contact@hideep.com")}
          </Typography>
          <Typography variant="body3Alias">{t("sales@hideep.com")}</Typography>
        </PaddingBox>
      </CustomContainer>
      <EmptySpace height={matches ? "110px" : "44px"} />

      {/* MAP */}
      {onlySm(
        <div style={{ width: "100%" }}>
          <GoogleMap />
        </div>
      )}

      {onlyLg(
        <CustomContainer>
          <PaddingBox>
            <GoogleMap />
          </PaddingBox>
        </CustomContainer>
      )}
      <EmptySpace height={matches ? "300px" : "100px"} />
    </React.Fragment>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;

import React, { useEffect, useState } from "react";
import { app, db } from "firebaseConfig";
import moment from "moment";
import { _ } from "lodash";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from "firebase/auth";

export const AuthContext = React.createContext();
export function useAuth() {
  return React.useContext(AuthContext);
}
//2.

//3.

export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [userExtraInfo, setUserExtraInfo] = useState(null);
  const [userId, setUserId] = useState(null);

  const [isLogin, setIsLogin] = useState(false);
  const [userStations, setUserStations] = useState([]);
  const [pending, setPending] = useState(true);
  const [secondPending, setSecondPending] = useState(true);
  //필요있는지 생각해보기
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [bExtraInfo, setBExtraInfo] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        console.log(uid);
        setIsLogin(true);
        setPending(false);
        localStorage.setItem("auth", uid);

        // ...
      } else {
        // User is signed out
        // ...
        localStorage.removeItem("auth");
        console.log("일로");
        setIsLogin(false);
        setPending(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const logOut = async () => {
    try {
      await signOut(auth);
      // delete all context info
      return { code: 200 };
      // An error happened
    } catch (e) {
      console.log(e);
      return { code: 400, msg: "시스템 에러 고객센터에 문의해주세요" };
    }
  };

  const sendPasswordReset = async (email) => {
    try {
      const result = await sendPasswordResetEmail(auth, email);
      return { success: true };
    } catch (e) {
      return { success: false, msg: e };
    }
  };

  const sendEmailVerfication = () => {
    user
      .sendEmailVerification()
      .then(function () {
        // Email sent.
      })
      .catch(function (error) {
        // An error happened.
      });
  };
  const singUpWithEmail = async (email, password) => {
    try {
      const result = await app
        .auth()
        .createUserWithEmailAndPassword(email, password);
      return { code: 200, data: result };
    } catch (e) {
      return { code: 400, msg: e };
    }
  };
  const singInWithEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      sessionStorage.setItem("AuthToken", "true");

      return { code: 200 };

      // ...
    } catch (error) {
      console.log(error);
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // window.alert(errorMessage);
      // ...
      return { code: 400, msg: errorCode };
      console.log("1", errorCode);
      console.log("2", errorMessage);
    }
  };

  const fetchUserStatus = async () => {
    try {
      console.log(user, "user");
      if (!user.email) {
        return { code: 200, data: null };
      }
      if (!user) {
        console.log(user, "user1");

        return { code: 200, data: null };
      }
      console.log(user, "user2");
    } catch (e) {
      console.log("5", e);
      return { code: 400, msg: "시스템 오류 다시 시도해주세요" };
    }
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  return (
    <AuthContext.Provider
      value={{
        user,
        userId,
        isLogin,
        setIsLogin,
        userExtraInfo,
        newMessageCount,
        bExtraInfo,
        logOut,
        sendPasswordReset,
        sendEmailVerfication,

        singUpWithEmail,
        singInWithEmail,
        // setUserExtraInfo,

        // 디비 업데이트 함수
        userStations,
        // fUser: app.auth(),
      }}
    >
      {!pending && children}
    </AuthContext.Provider>
  );
};

import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BlackEmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

//careers
import logo_black from "asset/2023-HIDEEP-logo-black.png";
import logo_white from "asset/2023-HIDEEP-logo-gray.png";
import tech1 from "asset/technology/tech1.jpeg";
import tech2 from "asset/technology/tech2.jpeg";
import tech3 from "asset/technology/tech3.png";
import tech4 from "asset/technology/tech4.jpeg";
import tech5 from "asset/technology/tech5.png";
import tech6 from "asset/technology/tech6.png";
import tech7 from "asset/technology/tech7.jpeg";
import tech8 from "asset/technology/tech8.jpeg";
import tech9 from "asset/technology/tech9.jpeg";
import tech10 from "asset/technology/tech10.jpeg";
import tech11 from "asset/technology/tech11.jpeg";
import tech12 from "asset/technology/tech12.jpeg";
import tech13 from "asset/technology/tech13.jpeg";
import tech600 from "asset/technology/tech600.png";
import techDispl from "asset/technology/techDispl.png";
import techNew1 from "asset/technology/techNew1.png";

import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";
import { app, db } from "firebaseConfig";
import logo_sm from "asset/logo_sm.png";
import logo_sm_white from "asset/logo_sm_white.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GlobalContext from "context/globalContext";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router";

import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
function Company(props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [white, setWhite] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position === 0) {
      setWhite(true);
    } else {
      setWhite(false);
    }
  };

  function AppBarLG({ white }) {
    console.log("화이트", white);

    return (
      <Box
        className={white ? `black backtransition` : `white backtransition`}
        style={{
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: "9999999999",
          backgroundColor: white ? "#000" : "#fff",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Link
          sx={{ padding: "0", marginTop: "8px" }}
          underline="none"
          href="/main"
        >
          <img
            src={white ? logo_white : logo_black}
            style={{
              height: "auto",
              maxWidth: "139.27px",
            }}
          ></img>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed",
            top: "65px",
            left: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: white ? "#000" : "#fff",
          }}
        >
          <Link
            href="/technology"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "500",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Technology
          </Link>

          <Link
            href="/company"
            variant="h4"
            sx={{
              fontFamily: "Inter",
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Company
          </Link>
          <Link
            href="/careers"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Careers
          </Link>
          <Link
            href="/newsroom"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Newsroom
          </Link>
        </Box>
      </Box>
    );
  }

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  const [newsroom, setNewsroom] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        await init();
        setRefresh(false);
      }
    })();
  }, [refresh]);

  async function init() {
    const car = await getFirebaseDoc("newsroom");
    setNewsroom(car.posts);
  }
  const { drawerIsOpen, setDrawerIsOpen } = React.useContext(GlobalContext);
  const openDrawer = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  async function getFirebaseDoc(table) {
    try {
      const querySnapshot = await getDocs(collection(db, table));

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function MainLikeImg2({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
          backgroundColor: "#000",
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
            backgroundColor: "#000",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? {
                ...styles.imgWidthFitBox.lgtheme,
                height: height ?? "",
                backgroundColor: "#000",
              }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
                backgroundColor: "#000",
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? {
                ...styles.imgScaleFitBox.lgtheme,
                height,
                overflow: "hidden",
                backgroundColor: "#000",
              }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
                backgroundColor: "#000",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <div style={{ backgroundColor: "black" }}>
        <Container
          disableGutters={true}
          maxWidth={false}
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}>
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function UmAnimation(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div id="two" ref={domRef}>
        <div className="box">
          {console.log("isvisible", isVisible)}
          <ul className={`${isVisible ? "is-visiblea" : "ull"}`}>
            <li className="item-1">~600um</li>
            <li className="item-2">500um</li>
            <li className="item-3">400um</li>
            <li className="item-4">300um</li>
            <li className="item-2">200um</li>
            <li className="item-3">100um</li>
            <li className="item-4">50um</li>
            <li className="item-5">30um</li>
          </ul>
        </div>
      </div>
    );
  }

  function UmAnimationMini(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div id="two2" ref={domRef}>
        <div className="box2">
          {console.log("isvisible", isVisible)}
          <ul className={`${isVisible ? "is-visiblea2" : "ull"}`}>
            <li className="item-1">~600um</li>
            <li className="item-2">500um</li>
            <li className="item-3">400um</li>
            <li className="item-4">300um</li>
            <li className="item-2">200um</li>
            <li className="item-3">100um</li>
            <li className="item-4">50um</li>
            <li className="item-5">30um</li>
          </ul>
        </div>
      </div>
    );
  }

  function DisplayAnimation(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div id="two" ref={domRef}>
        <div className="box">
          {console.log("isvisible", isVisible)}
          <ul className={`${isVisible ? "is-visiblea" : "ull"}`}>
            <li className="item-1">x1</li>
            <li className="item-2">x2</li>
            <li className="item-3">x3</li>
            <li className="item-4">x5</li>
            <li className="item-2">x7</li>
            <li className="item-3">x8</li>
            <li className="item-4">x9</li>
            <li className="item-5">x10</li>
          </ul>
        </div>
      </div>
    );
  }
  function DisplayAnimationMini(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div id="two3" ref={domRef}>
        <div className="box3">
          {console.log("isvisible", isVisible)}
          <ul className={`${isVisible ? "is-visiblea3" : "ull"}`}>
            <li className="item-1">x1</li>
            <li className="item-2">x2</li>
            <li className="item-3">x3</li>
            <li className="item-4">x5</li>
            <li className="item-2">x7</li>
            <li className="item-3">x8</li>
            <li className="item-4">x9</li>
            <li className="item-5">x10</li>
          </ul>
        </div>
      </div>
    );
  }
  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    marginLeft: "-22px",
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 0,
      width: "100%",
      backgroundColor: "#979797",
      height: "1.5px",
    },
    marginLeft: matches ? "-12px" : "-22px",
  });

  const StyledTab = styled((props) => (
    <Tab
      disableRipple
      onClick={() => history.push(props.label.split(" ").join(""))}
      {...props}
    />
  ))(({ theme }) =>
    matches
      ? {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "18px",

          marginRight: theme.spacing(-1),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
      : {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "14px",

          marginRight: theme.spacing(-3),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
  );
  const [value, setValue] = React.useState(5);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabs() {
    return (
      <Box sx={{ bgcolor: "#000" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="3D Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="Stylus">
            <Link></Link>
          </StyledTab>
        </StyledTabs>
        <Box sx={matches ? { p: 3 } : {}} />
      </Box>
    );
  }
  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  const [counter, setCounter] = React.useState(0);
  const increment = () => {
    setCounter(counter + 1);
  };

  // console.log("화이트 리랜더", white);
  function LgPage(white) {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white.white} /> */}
        <BlackEmptySpace height={matches ? "252px" : "83px"} />
        <CustomContainer>
          <PaddingBox>
            <CustomTabs />
            <BlackEmptySpace height={matches ? "78px" : "60px"} />
          </PaddingBox>
        </CustomContainer>
        {/* <ImgScaleFit height={"1000px"} src={tech1} /> */}
        <MainLikeImg2 height="1000px" img={tech1} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "160px" : "83px"} />

            <Typography variant={"tech1"}>
              {t(
                `우리는 '왜?' 와 '어떻게?'에 집중합니다.\n기존과는 전혀 다른 방법에 대한 도전.\n하이딥은 끊임없이 깊은 고민과 새로운 혁신을 추구합니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "150px" : "83px"} />
          </PaddingBox>
        </CustomContainer>

        {/* <ImgScaleFit height={"760px"} src={tech2} /> */}
        <MainLikeImg2 height="1000px" img={tech2} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "155px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(
                `Display 기술은 점점 더 얇아지고 더 커지며 구부려질 수 있는\nform factor 로 발전해 나가고 있습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(
                `하지만 이와 함께 display noise 가 매우 커져서\n정확한 터치가 어려운 문제가 발생했습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "83px"} />
            <Typography variant={"techBody1"}>
              {t(
                `Rigid OLED display 대비 Flexible OLED display 에서\nOncell touch sensor와 display 사이의 거리가\n약 1/20로 가까워졌기 때문에`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "160px" : "83px"} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={tech600}
                style={{
                  backgroundSize: "fit",

                  width: "auto",
                  height: "500px",
                }}
              ></img>
            </Box>
            <UmAnimation />

            <BlackEmptySpace height={matches ? "85px" : "36px"} />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "80%",
                }}
              >
                <ImgWidthFit src={techNew1} />
              </Box>
            </Box>
            <Typography
              variant={"techBody1"}
              sx={{
                fontSize: "14px",
                color: "#a5a5a5",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              {t(
                `Rigid OLED Panel과 Flexible OLED Panel 두께 비교\n(출처: UBI Research "Key Issue and The Market Analysis for Foldable OLED - Flexible OLED Annual Report")`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "165px" : "83px"} />
        <CustomContainer>
          <PaddingBox>
            <Typography variant={"techBody1"}>
              {t(
                `Touch sensor 와 noise source 사이의 거리가 가까워져\ndisplay noise 는 약 10배 커졌습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "160px" : "83px"} />
            <ImgScaleFit height={"750px"} src={tech4} />
          </PaddingBox>
        </CustomContainer>
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "150px" : "83px"} />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={techDispl}
                style={{
                  backgroundSize: "fit",

                  width: "auto",
                  height: "500px",
                }}
              ></img>
            </Box>
            <DisplayAnimation />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "130px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(
                `이렇게 변화되고 있는 디스플레이 구조에서\ntouch 신호를 깨끗하게 수신한다는 것은 매우 어려운 일입니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "32px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(
                `전 세계에서도 이를 극복할 수 있는\ntouch 기술 기업은 아주 희소하며`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "32px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(
                `지금까지 touch sensor 에 영향을 주는 이런 noise 는\n소프트웨어적으로 걸러내는 방법이 사용되고 있습니다. `
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "165px" : "83px"} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <ImgWidthFit src={tech5} />

            <BlackEmptySpace height={matches ? "110px" : "83px"} />

            <Typography
              variant={"careerWhiteTitle"}
              sx={matches ? { textAlign: "center" } : ""}
            >
              {t(`우리는 생각의 방향을 전환했습니다.`)}
            </Typography>
            <BlackEmptySpace height={matches ? "120px" : "83px"} />
            <ImgWidthFit src={tech6} />
          </PaddingBox>
        </CustomContainer>
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "150px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(
                `소프트웨어를 이용해서 근본적으로 noise 문제를\n해결할 수 없다면, 하드웨어적인 설계를 통해\nnoise 가 제거된 신호를 받을 수 없을까?`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "160px" : "36px"} />
        <MainLikeImg2 height="919px" img={tech7} />

        {/* <ImgScaleFit height={"919px"} src={tech7} /> */}

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "160px" : "83px"} />

            <Typography variant={"techBody1"}>
              {t(`이렇게 개발된 하이딥의 독자 터치 기술 솔루션.`)}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "83px"} />
            <Typography variant={"techBody1"}>
              {t(
                `Display noise를 원천적으로 제거한 신호를 수신하는\n하이딥의 독보적인 기술입니다.`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "150px" : "83px"} />

        <Box sx={{}}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <Box
                style={{
                  overflow: "hidden",
                  width: "100%",
                  position: "relative",
                  aspectRatio: "1.448 / 1",

                  backgroundImage: `url(${tech8})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                }}
              ></Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box
                style={{
                  overflow: "hidden",
                  width: "100%",
                  position: "relative",

                  aspectRatio: "1.448 / 1",
                  backgroundImage: `url(${tech9})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 50%",
                }}
              ></Box>{" "}
            </Grid>
          </Grid>
        </Box>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "210px" : "83px"} />

            <Typography variant={"techh2"}>{t(`Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "10px" : "83px"} />
            <Link underline="none" href="/Touch">
              <Typography variant={"techLink"}>
                {t(`Learn more   ⟩
`)}
              </Typography>
            </Link>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />

            <Typography variant={"techBody3"}>
              {t(
                `하이딥은 다릅니다.\nDisplay noise 를 제거해 버리는 독보적 기술.\nFoldable, tablet, notebook 등의 더 큰 화면의 기기들 용으로도\n독자적인 기술 솔루션 개발을 계속해 나가겠습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <ImgWidthFit src={tech10} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "200px" : "83px"} />

            <Typography variant={"techh2"}>{t(`3D Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "10px" : "83px"} />
            <Link underline="none" href="/3DTouch">
              <Typography variant={"techLink"}>
                {t(`Learn more   ⟩`)}
              </Typography>
            </Link>
            <BlackEmptySpace height={matches ? "58px" : "83px"} />

            <Typography variant={"techBody3"}>
              {t(
                `하이딥은 다릅니다.\nSmartphone의 숨어있는 틈을 찾아 터치의 힘을 센싱하는 3D Touch 기술.\nMulti-touch에서 Single-touch로의 새로운 UI UX 세상을 열어갑니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <ImgWidthFit src={tech11} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "200px" : "83px"} />

            <Typography variant={"techh2"}>{t(`Stylus`)}</Typography>
            <BlackEmptySpace height={matches ? "7px" : "83px"} />

            <Link underline="none" href="/stylus">
              <Typography variant={"techLink"}>
                {t(`Learn more   ⟩`)}
              </Typography>
            </Link>
            <BlackEmptySpace height={matches ? "58px" : "83px"} />

            <Typography variant={"techBody3"}>
              {t(
                `하이딥은 다릅니다.\nActive pen과 Passive pen의 장점만을 취합한\n완전히 새로운 Stylus.\nStylus 기술의 표준을 만들어 갑니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <ImgWidthFit src={tech12} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "200px" : "83px"} />

            <Typography variant={"techh2"}>{t(`Potential`)}</Typography>

            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <Typography variant={"techBody3"}>
              {t(
                `A total solution provider for digital input interfaces.\n하이딥의 기술에 대한 집요함, 그 무한한 확장성과 가능성으로\n새로운 digital 시대의 Input interface 의 표준을 만들어 나갑니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "83px"} />
            <ImgWidthFit src={tech13} />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "300px" : "36px"} />
      </React.Fragment>
    );
  }
  function SmPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "300px" : "74px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "20px" : "36px"} />
          </PaddingBox>
        </CustomContainer>

        <ImgWidthFit src={tech1} />
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "150px" : "30px"} />

            <Typography variant={"tech1"}>
              {t(
                `우리는 '왜?' 와 '어떻게?'에 집중합니다.\n기존과는 전혀 다른 방법에 대한 도전.\n하이딥은 끊임없이 깊은 고민과\n새로운 혁신을 추구합니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "150px" : "110px"} />
          </PaddingBox>
        </CustomContainer>

        <Box
          style={{
            overflow: "hidden",
            width: "100%",
            position: "relative",

            aspectRatio: "2.13 / 1",
            backgroundImage: `url(${tech2})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "150px" : "40px"} />

            <Typography variant={"techBody1"}>
              {t(
                `Display 기술은 점점 더 얇아지고 더 커지며 구부려질 수 있는\nform factor 로 발전해 나가고 있습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "22.5px"} />

            <Typography variant={"techBody1"}>
              {t(
                `하지만 이와 함께 display noise 가 매우 커져서\n정확한 터치가 어려운 문제가 발생했습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "20px"} />
            <Typography variant={"techBody1"}>
              {t(
                `Rigid OLED display 대비 Flexible OLED display 에서\nOncell touch sensor와 display 사이의 거리가\n약 1/20로 가까워졌기 때문에\n`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "60px"} />
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={tech600}
                style={{
                  backgroundSize: "fit",

                  width: "auto",
                  height: "150px",
                }}
              ></img>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UmAnimationMini />
            </Box>
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "200px" : "50px"} />
        <ImgWidthFit src={techNew1} />
        <CustomContainer>
          <PaddingBox>
            <Typography variant={"techRef"} sx={{ mt: -1 }}>
              {t(
                `Rigid OLED Panel과 Flexible OLED Panel 두께 비교\n(출처: UBI Research "Key Issue and The Market Analysis for Foldable OLED - Flexible OLED Annual Report")`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "150px" : "110px"} />

            <Typography variant={"techBody1"}>
              {t(
                `Touch sensor 와 noise source 사이의 거리가 가까워져\ndisplay noise 는 약 10배 커졌습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "160px" : "40px"} />
          </PaddingBox>
        </CustomContainer>
        <ImgWidthFit src={tech4} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "150px" : "40px"} />

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={techDispl}
                style={{
                  backgroundSize: "fit",

                  width: "auto",
                  height: "125px",
                }}
              ></img>
            </Box>
            <DisplayAnimationMini />
          </PaddingBox>
        </CustomContainer>
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "160px" : "95px"} />

            <Typography variant={"techBody1"}>
              {t(
                `이렇게 변화되고 있는 디스플레이 구조에서 \ntouch 신호를 깨끗하게 수신한다는 것은 매우 어려운 일입니다.\n\n전 세계에서도 이를 극복할 수 있는\ntouch 기술 기업은 아주 희소하며\n\n지금까지 touch sensor 에 영향을 주는 이런 noise 는\n소프트웨어적으로 걸러내는 방법이 사용되고 있습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "160px" : "40px"} />
          </PaddingBox>
        </CustomContainer>

        <ImgWidthFit src={tech5} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "25px"} />
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography
                variant={"careerWhiteTitle"}
                sx={{ textAlign: "center", width: "100%", fontSize: "18px" }}
              >
                {t(`우리는 생각의 방향을 전환했습니다.`)}
              </Typography>
            </Box>
            <BlackEmptySpace height={matches ? "100px" : "40px"} />
          </PaddingBox>
        </CustomContainer>
        <ImgScaleFit src={tech6} />
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "100px" : "97px"} />

            <Typography variant={"techBody1"}>
              {t(
                `소프트웨어를 이용해서 근본적으로 noise 문제를\n해결할 수 없다면, 하드웨어적인 설계를 통해\nnoise 가 제거된 신호를 받을 수 없을까?`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "160px" : "30px"} />

        <ImgWidthFit src={tech7} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "150px" : "30px"} />

            <Typography variant={"techBody1"}>
              {t(`이렇게 개발된 하이딥의 독자 터치 기술 솔루션.`)}
            </Typography>
            <BlackEmptySpace height={matches ? "30px" : "22.5px"} />
            <Typography variant={"techBody1"}>
              {t(
                `Display noise를 원천적으로 제거한 신호를 수신하는 \n하이딥의 독보적인 기술입니다.`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "150px" : "40px"} />

        <Box sx={{}}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ImgWidthFit src={tech8} />
            </Grid>
          </Grid>
        </Box>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "200px" : "95px"} />

            <Typography variant={"techh2"}>{t(`Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "5px" : "0px"} />
            <Link underline="none" href="/Touch">
              <Typography variant={"techLink"}>
                {t(`Learn more   ⟩`)}
              </Typography>
            </Link>
            <BlackEmptySpace height={matches ? "60px" : "40px"} />

            <Typography variant={"techBody3"}>
              {t(
                `하이딥은 다릅니다.\nDisplay noise 를 제거해 버리는 독보적 기술.\nFoldable, tablet, notebook 등의 더 큰 화면의 기기들 용으로도\n독자적인 기술 솔루션 개발을 계속해 나가겠습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "20px"} />
          </PaddingBox>
        </CustomContainer>

        <ImgWidthFit src={tech10} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "210px" : "96px"} />

            <Typography variant={"techh2"}>{t(`3D Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "5px" : "0px"} />
            <Link underline="none" href="/3DTouch">
              <Typography variant={"techLink"}>
                {t(`Learn more   ⟩`)}
              </Typography>
            </Link>
            <BlackEmptySpace height={matches ? "60px" : "40px"} />

            <Typography variant={"techBody3"}>
              {t(
                `하이딥은 다릅니다.\nSmartphone의 숨어있는 틈을 찾아\n터치의 힘을 센싱하는 3D Touch 기술.\nMulti-touch에서 Single-touch로의 새로운 UI UX 세상을 열어갑니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "20px"} />
          </PaddingBox>
        </CustomContainer>
        <ImgWidthFit src={tech11} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "210px" : "100px"} />

            <Typography variant={"techh2"}>{t(`Stylus`)}</Typography>
            <BlackEmptySpace height={matches ? "5px" : "0px"} />
            <Link underline="none" href="/Stylus">
              <Typography variant={"techLink"}>
                {t(`Learn more   ⟩`)}
              </Typography>
            </Link>
            <BlackEmptySpace height={matches ? "60px" : "40px"} />

            <Typography variant={"techBody3"}>
              {t(
                `하이딥은 다릅니다.\nActive pen과 Passive pen의 장점만을 취합한\n완전히 새로운 Stylus.\nStylus 기술의 표준을 만들어 갑니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "20px"} />
          </PaddingBox>
        </CustomContainer>
        <Box
          style={{
            overflow: "hidden",
            width: "100%",
            position: "relative",

            aspectRatio: "2.13 / 1",
            backgroundImage: `url(${tech12})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        />
        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "200px" : "100px"} />

            <Typography variant={"techh2"}>{t(`Potential`)}</Typography>

            <BlackEmptySpace height={matches ? "60px" : "40px"} />
            <Typography variant={"techBody3"}>
              {t(
                `A total solution provider for digital input interfaces.\n하이딥의 기술에 대한 집요함, 그 무한한 확장성과 가능성으로\n새로운 digital 시대의 Input interface 의 표준을 만들어 나갑니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "60px" : "20px"} />
          </PaddingBox>
        </CustomContainer>
        <Box
          style={{
            overflow: "hidden",
            width: "100%",
            position: "relative",

            aspectRatio: "2.13 / 1",
            backgroundImage: `url(${tech13})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        />

        <BlackEmptySpace height={matches ? "300px" : "100px"} />
      </React.Fragment>
    );
  }

  return matches ? (
    <>
      <Box
        // className={white ? `black backtransition` : `white backtransition`}
        style={{
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: "9999999999",
          backgroundColor: white ? "#000" : "#fff",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Link
          sx={{ padding: "0", marginTop: "8px" }}
          underline="none"
          href="/main"
        >
          <img
            src={white ? logo_white : logo_black}
            style={{
              height: "auto",
              maxWidth: "139.27px",
            }}
          ></img>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed",
            top: "65px",
            left: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: white ? "#000" : "#fff",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Link
            href="/technology"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "500",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Technology
          </Link>

          <Link
            href="/company"
            variant="h4"
            sx={{
              fontFamily: "Inter",
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Company
          </Link>
          <Link
            href="/careers"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Careers
          </Link>
          <Link
            href="/InvestorRelations"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Investor Relations
          </Link>
          <Link
            href="/newsroom"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Newsroom
          </Link>
        </Box>
      </Box>{" "}
      <LgPage white={white} />{" "}
    </>
  ) : (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "48px",
          zIndex: "999",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: white ? "black" : "#fff",
            boxShadow: "none",
            height: "48px",
            minHeight: "48px",
            maxHeight: "48px",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Toolbar
            style={{
              backgroundColor: white ? "black" : "#fff",
              transition: "background-color 1000ms linear",
              boxShadow: "none",
              justifyContent: "space-between",
              display: "flex",
              alignContent: "center",
              height: "48px",
              minHeight: "48px",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
            }}
          >
            <IconButton
              size="samll"
              edge="start"
              color={white ? "#000" : "#fff"}
              aria-label="menu"
              sx={{ mr: 2, color: "black", maxHeight: "48px" }}
              onClick={openDrawer}
            >
              <MenuIcon color={white ? "white" : "black"} />
            </IconButton>
            <Link
              underline="none"
              href="/main"
              sx={{ padding: "0", marginTop: "8px" }}
            >
              <img
                src={white ? logo_sm_white : logo_sm}
                style={{
                  marginRight: "45px",
                  height: "auto",
                  maxWidth: "56.2px",
                }}
              ></img>
            </Link>

            <Typography sx={{ height: "48px" }} color="inherit">
              {" "}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <SmPage />
    </>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;

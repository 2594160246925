import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BlackEmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory } from "react-router";
import logo_sm from "asset/logo_sm.png";
import logo_sm_white from "asset/logo_sm_white.png";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GlobalContext from "context/globalContext";
import logo_black from "asset/2023-HIDEEP-logo-black.png";
import logo_white from "asset/2023-HIDEEP-logo-gray.png";
//careers

import touch1 from "asset/technology/touch/touch1.png";
import touch2 from "asset/technology/touch/touch2.png";
import touch3 from "asset/technology/touch/touch3.png";
import touch4 from "asset/technology/touch/touch4.png";
import touch5 from "asset/technology/touch/touch5.png";
import touch6 from "asset/technology/touch/touch6.png";

import touch7 from "asset/technology/touch/touch7.jpeg";
import touch8 from "asset/technology/touch/touch8.jpeg";
import touch9 from "asset/technology/touch/touch9.jpeg";
import touch10 from "asset/technology/touch/touch10.jpeg";
import touch11 from "asset/technology/touch/touch11.jpeg";
import touch12 from "asset/technology/touch/touch12.jpeg";
import touch13 from "asset/technology/touch/touch13.jpeg";

import mobile from "asset/technology/touch/mobile0.jpeg";
import mobilegrid1 from "asset/technology/touch/mobilegrid1.jpeg";
import mobilegrid2 from "asset/technology/touch/mobilegrid2.jpeg";
import mobilegrid3 from "asset/technology/touch/mobilegrid3.jpeg";
import mobilegrid4 from "asset/technology/touch/mobilegrid4.jpeg";
import mobilegrid5 from "asset/technology/touch/mobilegrid5.jpeg";
import mobilegrid6 from "asset/technology/touch/mobilegrid6.jpeg";
import newtouch00 from "asset/technology/touch/newtouch00.jpeg";

import newtouch0 from "asset/technology/touch/newtouch0.jpeg";
import newtouch1 from "asset/technology/touch/newtouch1.jpeg";

import { Link } from "@mui/material";

import tech1 from "asset/technology/tech1.jpeg";
import tech2 from "asset/technology/tech2.jpeg";
import tech3 from "asset/technology/tech3.png";
import tech4 from "asset/technology/tech4.jpeg";
import tech5 from "asset/technology/tech5.png";
import tech6 from "asset/technology/tech6.png";
import tech7 from "asset/technology/tech7.jpeg";
import tech8 from "asset/technology/tech8.jpeg";
import tech9 from "asset/technology/tech9.jpeg";
import tech10 from "asset/technology/tech10.jpeg";
import tech11 from "asset/technology/tech11.jpeg";
import tech12 from "asset/technology/tech12.jpeg";
import tech13 from "asset/technology/tech13.jpeg";
import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";
import lastMobile from "asset/technology/touch/lastmobile.jpeg";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
function Company(props) {
  const history = useHistory();

  const { t } = useTranslation();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    console.log("렌더를 어떻게 유발 시키지", lang);
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const [white, setWhite] = React.useState(true);
  const { drawerIsOpen, setDrawerIsOpen } = React.useContext(GlobalContext);
  const openDrawer = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position === 0) {
      setWhite(true);
    } else {
      setWhite(false);
    }
  };
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  function MainLikeImg({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
          backgroundColor: "#000",
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
            backgroundColor: "#000",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }

  function ImgRatio({ src, height, aspectRatio }) {
    return (
      <div style={{ width: "100%", height: "auto", lineHeight: "0" }}>
        <img
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: aspectRatio,
            objectFit: "cover",
          }}
          src={src}
          alt=""
        ></img>
      </div>
    );
  }

  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? {
                ...styles.imgWidthFitBox.lgtheme,
                height: height ?? "",
                backgroundColor: "black",
              }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
                backgroundColor: "black",
                lineHeight: "0",
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <div style={{ backgroundColor: "black" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}>
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }

  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  const AntTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: "#1890ff",
    },
  });

  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      minWidth: 0,
      [theme.breakpoints.up("sm")]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.85)",
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&.Mui-focusVisible": {
        backgroundColor: "#d1eaff",
      },
    })
  );

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    marginLeft: "-16px",
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      maxWidth: 0,
      width: "100%",
      backgroundColor: "#979797",
      height: "1.5px",
    },
    marginLeft: matches ? "-14px" : "-24px",
  });

  const StyledTab = styled((props) => (
    <Tab
      disableRipple
      onClick={() => history.push(props.label.split(" ").join(""))}
      {...props}
    />
  ))(({ theme }) =>
    matches
      ? {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "18px",

          marginRight: theme.spacing(-1),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
      : {
          textTransform: "none",
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: "Inter",

          textTransform: "none",
          fontWeight: "700",
          fontSize: "14px",

          marginRight: theme.spacing(-3),
          color: "#fff",
          "&.Mui-selected": {
            color: "#979797",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "rgba(100, 95, 228, 0.32)",
          },
        }
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function CustomTabs() {
    return (
      <Box sx={{ bgcolor: "#000" }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab label="Touch" />
          <StyledTab label="3D Touch">
            <Link></Link>
          </StyledTab>
          <StyledTab label="Stylus" />
        </StyledTabs>
      </Box>
    );
  }

  function LgPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}

        <BlackEmptySpace height={matches ? "192px" : "83px"} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "60px" : "60px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "50px" : "60px"} />

            <Typography variant={"componetsh1"}>{t(`Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "105px" : "83px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `기본적인 터치 센서의 동작은\n직교 좌표계의 X 센서에 순차적으로 구동신호를 인가하고 Y 센서에서 신호를 수신합니다.\n손 터치가 있을 경우 Y 센서에서 수신되는 신호에 왜곡이 발생하여 손 터치의 X, Y 위치를 알아냅니다.\n터치 IC는 X 센서에 구동신호를 인가하고 Y 센서에서 신호를 수신하는 수신기 역할을 합니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "95px" : "60px"} />
            <Grid container>
              <Grid item xs={6} md={2}></Grid>
              <Grid item xs={6} md={8}>
                <Box>
                  <img
                    style={{ width: "auto", height: "602px" }}
                    src={touch1}
                  ></img>
                </Box>
              </Grid>
              <Grid item xs={6} md={2}></Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "60px" : "83px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Touch 에서의 성능은 noise에 대하여 얼마나 내성이 있는가가 가장 중요합니다.\n특히 touch sensor 바로 아래에 배치되는 display에서 화면이 바뀔 때마다 noise가 크게 발생합니다.\n기존의 Rigid OLED display 의 On-cell 구조에서는 encap glass를 사용하여 touch sensor 와 display 사이에 충분한 거리가 있어서 display noise에 대하여 덜 민감하였습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "70px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `하지만 Flexible OLED display의 On-cell 구조에서는 flexible 하도록 display 를 만들기 위해 encap film 을 사용하여\ntouch sensor와 display 사이의 거리가 기존의 약 ~1/20까지 줄어들게 되어서, touch sensor 에 영향을 끼치는 display로 부터의 noise가 약 10배 가량 커지는 환경이 되었습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "130px" : "83px"} />
            <Grid container>
              <Grid item xs={6} md={6}>
                <ImgWidthFit src={newtouch00} />
              </Grid>
              <Grid item xs={6} md={6}>
                <ImgWidthFit src={newtouch0} />
              </Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "215px" : "83px"} />

            <Typography variant={"componentsh6"}>
              {t(`Touch 일체형, On-cell 이란?`)}
            </Typography>

            <BlackEmptySpace height={matches ? "62px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `Touch 일체형은 Touch sensor를 디스플레이 안으로 넣는 구조입니다. 터치 Sensor는 화면을 터치하는 위치의 좌표값을 파악하도록 하는 용도입니다. 이 Touch sensor의 위치 구조에 따라 내장형 (On-cell type)과 외장형 (Add on type)으로 구분됩니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "30px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `내장형은 외장형 대비 display 패널을 얇게 만들 수 있습니다. Touch sensor를 내장하여 두께나 빛 투과율 부분에서 유리하게 됩니다. Display 패널이 얇아지면 공간 활용이 용이 해집니다. OLED 구조 층을 단순화해 접는 (Foldable, 폴더블) 스마트폰에도 적합하게 됩니다. 또한 Touch sensor panel (TSP)을 외부 회사에서 제작하게 하여 납품 받아 이를 디스플레이에 부착하는 등의 모든 절차, 공정 단계도 생략이 됨으로 원가를 낮추는 데에도 유리합니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "30px" : "83px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `OLED display에 대한 Touch 일체형 구조는 유기물층의 공기와 수분 침투를 막는 박막봉지(TFE)에 touch sensor 전극을 증착해서 디스플레이 내부에 구현합니다. `
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "110px" : "83px"} />
            <Grid container>
              <Grid item xs={6} md={6}>
                <ImgWidthFit src={touch4} />
              </Grid>
              <Grid item xs={6} md={6}>
                <ImgWidthFit src={touch5} />
              </Grid>
            </Grid>
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "83px"} />

            <BlackEmptySpace height={matches ? "20px" : "83px"} />

            {/* 2 */}
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "124px" : "83px"} />

            <Typography variant={"componentsh6"}>
              {t(`하이딥의 Touch IC 가 적용될 수 있는 기기는?`)}
            </Typography>

            <BlackEmptySpace height={matches ? "65px" : "83px"} />
            <Grid container>
              <Grid item xs={6} md={2}>
                {" "}
              </Grid>
              <Grid item xs={6} md={4}>
                <Box>
                  <img
                    style={{ width: "auto", height: "732px" }}
                    src={newtouch1}
                  ></img>
                </Box>
              </Grid>
              <Grid item xs={6} md={12} sx={{ backgroundColor: "#000" }}></Grid>
            </Grid>
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "0px" : "83px"} />

            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <ImgRatio aspectRatio={"1.3335"} src={touch7} />
              </Grid>
              <Grid item xs={6} md={4}>
                <ImgRatio aspectRatio={"1.3335"} src={touch8} />
              </Grid>
              <Grid item xs={6} md={4}>
                <ImgRatio aspectRatio={"1.3335"} src={touch9} />
              </Grid>
              <Grid item xs={6} md={4}>
                <ImgRatio aspectRatio={"1.3335"} src={touch10} />
              </Grid>
              <Grid item xs={6} md={4}>
                <ImgRatio aspectRatio={"1.3335"} src={touch11} />
              </Grid>
              <Grid item xs={6} md={4}>
                <ImgRatio aspectRatio={"1.3335"} src={touch12} />
              </Grid>
            </Grid>
            <BlackEmptySpace height={matches ? "50px" : "83px"} />

            <Typography variant={"componentsh5"}>{t(`Application`)}</Typography>
            <BlackEmptySpace height={matches ? "37px" : "83px"} />
            <Typography variant={"componentsbodyApplication"}>
              {t(
                `• Smartphones\n• Foldable phones\n• Smartwatches\n• Tablets\n• 대형 터치 devices`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "215px" : "83px"} />

        <MainLikeImg height="1000px" img={touch13} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "83px"} />

            <CustomTabs />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "180px" : "36px"} />
      </React.Fragment>
    );
  }
  function SmPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        {/* <BasicAppBar white={white} /> */}

        <BlackEmptySpace height={matches ? "192px" : "15px"} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "60px" : "62px"} />
            <CustomTabs />
            <BlackEmptySpace height={matches ? "20px" : "30px"} />

            <Typography variant={"componetsh1"}>{t(`Touch`)}</Typography>
            <BlackEmptySpace height={matches ? "105px" : "48px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `기본적인 터치 센서의 동작은\n직교 좌표계의 X 센서에 순차적으로 구동신호를 인가하고 Y 센서에서\n신호를 수신합니다. 손 터치가 있을 경우 Y 센서에서 수신되는 신호에 왜곡이 발생하여 손 터치의 X, Y 위치를 알아냅니다.\n터치 IC는 X 센서에 구동신호를 인가하고\nY 센서에서 신호를 수신하는 수신기 역할을 합니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "95px" : "40px"} />
            <Grid container>
              {/* <Grid item xs={6} md={2}></Grid> */}
              <Grid item xs={12} md={8}>
                <Box>
                  <ImgWidthFit src={touch1} />
                </Box>
              </Grid>
              {/* <Grid item xs={6} md={2}></Grid> */}
            </Grid>

            <BlackEmptySpace height={matches ? "60px" : "5px"} />

            <Typography variant={"componentsbody1"}>
              {t(
                `Touch 에서의 성능은 noise에 대하여 얼마나 내성이 있는가가 가장 중요합니다.\n특히 touch sensor 바로 아래에 배치되는 display에서 화면이 바뀔 때마다 noise가 크게 발생합니다.\n\n기존의 Rigid OLED display 의 On-cell 구조에서는 encap glass를 사용하여 touch sensor 와 display 사이에 충분한 거리가 있어서 display noise에 대하여 덜 민감하였습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "70px" : "0px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `\n하지만 Flexible OLED display의 On-cell 구조에서는 flexible 하도록 display 를 만들기 위해 encap film 을 사용하여 touch sensor와 display 사이의 거리가 기존의 약 ~1/20까지 줄어들게 되어서, touch sensor 에 영향을 끼치는 display로 부터의 noise가 약 10배 가량 커지는 환경이 되었습니다.`
              )}
            </Typography>
            <BlackEmptySpace height={matches ? "130px" : "40px"} />
            <Grid container>
              <Grid item xs={12} md={6}>
                <ImgWidthFit src={newtouch00} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImgWidthFit src={newtouch0} />
              </Grid>
            </Grid>

            <BlackEmptySpace height={matches ? "215px" : "100px"} />

            <Typography variant={"componentsh6"}>
              {t(`Touch 일체형, On-cell 이란?`)}
            </Typography>

            <BlackEmptySpace height={matches ? "62px" : "40px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `Touch 일체형은 Touch sensor를 디스플레이 안으로 넣는 구조입니다. 터치 Sensor는 화면을 터치하는 위치의 좌표값을 파악하도록 하는 용도입니다. 이 Touch sensor의 위치 구조에 따라 내장형 (On-cell type)과 외장형 (Add on type)으로 구분됩니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "30px" : "0px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `\n내장형은 외장형 대비 display 패널을 얇게 만들 수 있습니다. Touch sensor를 내장하여 두께나 빛 투과율 부분에서 유리하게 됩니다. Display 패널이 얇아지면 공간 활용이 용이 해집니다. OLED 구조 층을 단순화해 접는 (Foldable, 폴더블) 스마트폰에도 적합하게 됩니다. 또한 Touch sensor panel (TSP)을 외부 회사에서 제작하게 하여 납품 받아 이를 디스플레이에 부착하는 등의 모든 절차, 공정 단계도 생략이 됨으로 원가를 낮추는 데에도 유리합니다.`
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "30px" : "0px"} />
            <Typography variant={"componentsbody1"}>
              {t(
                `\nOLED display에 대한 Touch 일체형 구조는 유기물층의 공기와 수분 침투를 막는 박막봉지(TFE)에 touch sensor 전극을 증착해서 디스플레이 내부에 구현합니다. `
              )}
            </Typography>

            <BlackEmptySpace height={matches ? "110px" : "40px"} />
            <Grid container>
              <Grid item xs={12} md={6}>
                <ImgWidthFit src={touch4} />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImgWidthFit src={touch5} />
              </Grid>
            </Grid>
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "20px" : "100px"} />
            {/* 2 */}
          </PaddingBox>
        </CustomContainer>

        <CustomContainer>
          <PaddingBox>
            <Typography variant={"componentsh6"}>
              {t(`하이딥의 Touch IC 가\n적용될 수 있는 기기는?`)}
            </Typography>

            <BlackEmptySpace height={matches ? "65px" : "40px"} />
            <Grid container>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ width: "auto", height: "300px" }}
                    src={newtouch1}
                  ></img>
                </Box>
              </Grid>
            </Grid>
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "0px" : "20px"} />
        <Box sx={{ backgroundColor: "#000", px: 0.6 }}>
          <Grid container spacing={0.6}>
            <Grid item xs={4} md={4}>
              <ImgRatio aspectRatio={"1.1836"} src={mobilegrid2} />
            </Grid>
            <Grid item xs={4} md={4}>
              <ImgRatio aspectRatio={"1.1836"} src={mobilegrid1} />
            </Grid>
            <Grid item xs={4} md={4}>
              <ImgRatio aspectRatio={"1.1836"} src={mobilegrid3} />
            </Grid>
            <Grid item xs={4} md={4}>
              <ImgRatio aspectRatio={"1.1836"} src={mobilegrid4} />
            </Grid>
            <Grid item xs={4} md={4}>
              <ImgRatio aspectRatio={"1.1836"} src={mobilegrid5} />
            </Grid>
            <Grid item xs={4} md={4}>
              <ImgRatio aspectRatio={"1.1836"} src={touch12} />
            </Grid>
          </Grid>
        </Box>
        <BlackEmptySpace height={matches ? "50px" : "60px"} />
        <CustomContainer>
          <PaddingBox>
            <Typography variant={"componentsh5"}>{t(`Application`)}</Typography>
            <BlackEmptySpace height={matches ? "37px" : "20px"} />
            <Typography variant={"componentsbodyApplication"}>
              {t(
                `• Smartphones\n• Foldable phones\n• Smartwatches\n• Tablets\n• 대형 터치 devices`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <BlackEmptySpace height={matches ? "215px" : "58px"} />

        <ImgWidthFit src={lastMobile} />

        <CustomContainer>
          <PaddingBox>
            <BlackEmptySpace height={matches ? "110px" : "52px"} />

            <CustomTabs />
          </PaddingBox>
        </CustomContainer>

        <BlackEmptySpace height={matches ? "180px" : "110px"} />
      </React.Fragment>
    );
  }

  return matches ? (
    <>
      <Box
        // className={white ? `black backtransition` : `white backtransition`}
        style={{
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: "9999999999",
          backgroundColor: white ? "#000" : "#fff",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Link
          sx={{ padding: "0", marginTop: "8px" }}
          underline="none"
          href="/main"
        >
          <img
            src={white ? logo_white : logo_black}
            style={{
              height: "auto",
              maxWidth: "139.27px",
            }}
          ></img>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed",
            top: "65px",
            left: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: white ? "#000" : "#fff",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Link
            href="/technology"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "500",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Technology
          </Link>

          <Link
            href="/company"
            variant="h4"
            sx={{
              fontFamily: "Inter",
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Company
          </Link>
          <Link
            href="/careers"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Careers
          </Link>
          <Link
            href="/newsroom"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Newsroom
          </Link>
        </Box>
      </Box>{" "}
      <LgPage white={white} />{" "}
    </>
  ) : (
    <>
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "48px",
          zIndex: "999",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: white ? "black" : "#fff",
            boxShadow: "none",
            height: "48px",
            minHeight: "48px",
            maxHeight: "48px",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
            transition: "all .5s ease",
            WebkitTransition: "all .5s ease",
            MozTransition: "all .5s ease",
          }}
        >
          <Toolbar
            style={{
              backgroundColor: white ? "black" : "#fff",
              transition: "background-color 1000ms linear",
              boxShadow: "none",
              justifyContent: "space-between",
              display: "flex",
              alignContent: "center",
              height: "48px",
              minHeight: "48px",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
            }}
          >
            <IconButton
              size="samll"
              edge="start"
              color={white ? "#000" : "#fff"}
              aria-label="menu"
              sx={{ mr: 2, color: "black", maxHeight: "48px" }}
              onClick={openDrawer}
            >
              <MenuIcon color={white ? "white" : "black"} />
            </IconButton>
            <Link
              underline="none"
              href="/main"
              sx={{ padding: "0", marginTop: "8px" }}
            >
              <img
                src={white ? logo_sm_white : logo_sm}
                style={{
                  marginRight: "45px",
                  height: "auto",
                  maxWidth: "56.2px",
                }}
              ></img>
            </Link>

            <Typography sx={{ height: "48px" }} color="inherit">
              {" "}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <SmPage />
    </>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;

const ko = {
  translation: {
    // main

    main_mobile_1_하이딥의: `하이딥의 터치 기술로 Digital Transformation이 시작됩니다.
    Flexible OLED 디스플레이용 터치, 스타일러스 기술과 모든
    디스플레이에 사용 가능한 3D 터치, 그리고 그 이상의 가능성.
    지금 세상이 필요로 하는 유의미한 사용자 터치 경험을 제공합니다.`,

    //company

    "company_lg_본 주식정보": `본 주식 정보는 정보 제공의 목적으로만 제공되며 거래를 위한 목적이 아닙니다.
    본 주식 정보는 코스닥의 정보를 그대로 공유하며
    하이딥은 이 서비스를 제공하지 않습니다.`,
    company_lg_현재주가는: `*현재주가는 자동으로 갱신되지 않으며 페이지를
    새로고침 하면 가장 최근 업데이트된 주가를 표시합니다.`,
    //company

    //company

    //company

    //company

    //company

    Philosophy: "Philosophy",
    "Fly Higher, Think Deeper": "Fly Higher, Think Deeper",

    "높은 곳에서, 넓게 보고": "높은 곳에서, 넓게 보고",

    "더 깊게 생각합니다.": "더 깊게 생각합니다.",

    "하이딥은 우리가 더 편하고 더 유용하도록 개발해 내는 기술과 제품이":
      "하이딥은 우리가 더 편하고 더 유용하도록 개발해 내는 기술과 제품이",

    "사람의 삶에 도움이 되고 이로 인해 조금이라도 더 나은 세상을 만들어갈 수 있기를 바랍니다.":
      "사람의 삶에 도움이 되고 이로 인해 조금이라도 더 나은 세상을 만들어갈 수 있기를 바랍니다.",

    "하이딥의 사람들은 모든 문제를 있는 그대로 마주하고 함께 깊이 고민하고 토론하여":
      "하이딥의 사람들은 모든 문제를 있는 그대로 마주하고 함께 깊이 고민하고 토론하여",

    "그 핵심을 파악하고 근본적인 해결책을 찾아내려는 노력을 합니다.":
      "그 핵심을 파악하고 근본적인 해결책을 찾아내려는 노력을 합니다.",

    "결국 이렇게 확보되는 해결책만이 혁신으로 이어질 수 있다는 것을 알고":
      "결국 이렇게 확보되는 해결책만이 혁신으로 이어질 수 있다는 것을 알고",

    "이러한 과정이 쉽지 않지만 이 과정 동안 우리 모두가 회사와 함께 몇 단계를 훌쩍 넘어":
      "이러한 과정이 쉽지 않지만 이 과정 동안 우리 모두가 회사와 함께 몇 단계를 훌쩍 넘어",

    "앞으로 나아갈 수 있다는 믿음을 가지고 있습니다. ":
      "앞으로 나아갈 수 있다는 믿음을 가지고 있습니다. ",

    "실패를 두려워하지 않고 끊임없이 도전하려는 하이딥 입니다.":
      "실패를 두려워하지 않고 끊임없이 도전하려는 하이딥 입니다.",

    Leadership: "Leadership",
    "세상을 바꿀 혁신이": "세상을 바꿀 혁신이",
    "하이딥에서 시작되고 있습니다": "하이딥에서 시작되고 있습니다",
    "CEO / 고범규": "CEO / 고범규",
    "하이딥은 약 18억 여개의 전 세계 모바일 기기 시장을 목표로 합니다.":
      "하이딥은 약 18억 여개의 전 세계 모바일 기기 시장을 목표로 합니다.",
    "하이딥은 모바일 기기의 핵심인 User Interface solution 을 제공합니다.":
      "하이딥은 모바일 기기의 핵심인 User Interface solution 을 제공합니다.",
    "하이딥은 모바일 기기 사용의 핵심인 사용자가 더 쓰기 편하고":
      "하이딥은 모바일 기기 사용의 핵심인 사용자가 더 쓰기 편하고",
    "더 유용하게 만들어주는 기술을 혁신적인 방법으로 제공합니다.":
      "더 유용하게 만들어주는 기술을 혁신적인 방법으로 제공합니다.",
    "전례가 없는 기술에 대한 도전을 통하여 파괴적 혁신을 추구합니다.":
      "전례가 없는 기술에 대한 도전을 통하여 파괴적 혁신을 추구합니다.",
    "고범규 대표이사(Ph.D.)는 22년이 넘는 기술기반 비즈니스의 성공 경험과 노하우를 바탕으로, 2010년 5월에 ㈜하이딥을 창립하였습니다.":
      "고범규 대표이사(Ph.D.)는 22년이 넘는 기술기반 비즈니스의 성공 경험과 노하우를 바탕으로, 2010년 5월에 ㈜하이딥을 창립하였습니다.",
    "㈜하이딥 창립 이전에는 Mobile TV 방송서비스 시장에 대한 선견지명으로, 2000년 인티그런트테크놀로지즈를 설립하고, 위성 및 지상파 DMB, ISDB-T, DVB-H and CMMB를 포함한, RF CMOS mobile TV tuner IC를 세계 최초로 개발하고 론칭하였습니다. 세계 최초의 혁신적인 RF CMOS 튜너칩은 전 세계에 4천만개이상 공급되었고, 설립후 6년 동안 200억원의 투자금을 유치하였고, 임직원은 6명에서 90여명으로 늘어났으며, 이들과 함께 매출 400억원의 대단한 성과를 이루었습니다. 2006년 6월 인티그런트테크놀로지즈는 160MUSD(한화 1,700억원상당)규모로 미국 아나로그디바이스(ADI)에 성공적으로 인수합병이 되었으며, 합병 후 고범규 대표이사는 미국 ADI의 고속신호 처리조직에 편입돼 해당 제품개발과 전략을 담당하는 Director로 근무하였습니다. 고범규 대표이사는 카이스트에서 전자공학 석사와 박사 과정을 거친 후, 삼성전자에서 반도체시스템 LSI 사업부에서 RF(Radio Frequency) Analog IC Design 책임연구원으로 근무하였습니다.":
      "㈜하이딥 창립 이전에는 Mobile TV 방송서비스 시장에 대한 선견지명으로, 2000년 인티그런트테크놀로지즈를 설립하고, 위성 및 지상파 DMB, ISDB-T, DVB-H and CMMB를 포함한, RF CMOS mobile TV tuner IC를 세계 최초로 개발하고 론칭하였습니다. 세계 최초의 혁신적인 RF CMOS 튜너칩은 전 세계에 4천만개이상 공급되었고, 설립후 6년 동안 200억원의 투자금을 유치하였고, 임직원은 6명에서 90여명으로 늘어났으며, 이들과 함께 매출 400억원의 대단한 성과를 이루었습니다. 2006년 6월 인티그런트테크놀로지즈는 160MUSD(한화 1,700억원상당)규모로 미국 아나로그디바이스(ADI)에 성공적으로 인수합병이 되었으며, 합병 후 고범규 대표이사는 미국 ADI의 고속신호 처리조직에 편입돼 해당 제품개발과 전략을 담당하는 Director로 근무하였습니다. 고범규 대표이사는 카이스트에서 전자공학 석사와 박사 과정을 거친 후, 삼성전자에서 반도체시스템 LSI 사업부에서 RF(Radio Frequency) Analog IC Design 책임연구원으로 근무하였습니다.",
    "상세 재무자료는 금감원 (DART) 공시자료를 참고해 주세요.":
      "상세 재무자료는 금감원 (DART) 공시자료를 참고해 주세요.",
    "(주)하이딥 공시정보관리규정 공고": "(주)하이딥 공시정보관리규정 공고",
    "합병보고총회에 갈음하는 공고": "합병보고총회에 갈음하는 공고",
    "<합병보고총회에 갈음하는 공고>": "<합병보고총회에 갈음하는 공고>",
    " ": " ",
    "① 엔에이치기업인수목적18호 주식회사(갑)는 2022년3월23일 개최한 임시주주총회에서, ":
      "① 엔에이치기업인수목적18호 주식회사(갑)는 2022년3월23일 개최한 임시주주총회에서, ",
    "주식회사 하이딥(을)은 2022년3월23일 임시주주총회 결의로 (갑)은 (을)을 합병하여, 그 권리의무 일체를 승계하고 (을)은 해산하기로 결의하였습니다.":
      "주식회사 하이딥(을)은 2022년3월23일 임시주주총회 결의로 (갑)은 (을)을 합병하여, 그 권리의무 일체를 승계하고 (을)은 해산하기로 결의하였습니다.",
    " ": " ",
    "② 위 결의에 의하여 양 회사는 상법 소정의 합병절차를 완료하고, 2022년4월26일 개최된 이사회에서 상법 제526조 3항에 의하여 합병보고총회에 갈음하여, ":
      "② 위 결의에 의하여 양 회사는 상법 소정의 합병절차를 완료하고, 2022년4월26일 개최된 이사회에서 상법 제526조 3항에 의하여 합병보고총회에 갈음하여, ",
    "이사회 결의와 본 공고로서 합병보고총회를 대체하기로 결의하였으므로 이에 합병완료 사실을 각 주주들에게 공고로서 보고합니다.":
      "이사회 결의와 본 공고로서 합병보고총회를 대체하기로 결의하였으므로 이에 합병완료 사실을 각 주주들에게 공고로서 보고합니다.",
    " ": " ",
    "2022년 4월 26일": "2022년 4월 26일",
    " ": " ",
    "주식회사 하이딥": "주식회사 하이딥",
    "경기도 성남시 분당구 대왕판교로644번길 49, 3층(삼평동, 다산타워)":
      "경기도 성남시 분당구 대왕판교로644번길 49, 3층(삼평동, 다산타워)",
    "대표이사  고범규": "대표이사  고범규",
    "다운로드   ": "다운로드   ",
    "합병보고총회에 갈음하는 공고.pdf": "합병보고총회에 갈음하는 공고.pdf",
    "품질 방침": "품질 방침",
    "우리는 고객에게 최고의 품질과 서비스를 제공하기 위하여 다음의 품질 원칙을 추구합니다.":
      "우리는 고객에게 최고의 품질과 서비스를 제공하기 위하여 다음의 품질 원칙을 추구합니다.",
    "일관성 있는 품질 및 신뢰성을 보장하기 위하여 업무 프로세스를 준수 합니다.":
      "일관성 있는 품질 및 신뢰성을 보장하기 위하여 업무 프로세스를 준수 합니다.",
    "무결점 품질을 달성하기 위하여 사전 품질관리 및 개선 활동을 지속합니다.":
      "무결점 품질을 달성하기 위하여 사전 품질관리 및 개선 활동을 지속합니다.",
    "임직원들의 직무 전문성 개발을 적극적으로 장려하고 교육 프로그램을 제공합니다.":
      "임직원들의 직무 전문성 개발을 적극적으로 장려하고 교육 프로그램을 제공합니다.",
    "환경 방침": "환경 방침",
    "우리는 인간과 자연에게 미칠 수 있는 유해한 영향을 최소화하기 위하여 다음의 환경 원칙을 추구합니다.":
      "우리는 인간과 자연에게 미칠 수 있는 유해한 영향을 최소화하기 위하여 다음의 환경 원칙을 추구합니다.",
    "국가 환경 법규 및 고객의 환경관련 요구사항을 준수합니다.":
      "국가 환경 법규 및 고객의 환경관련 요구사항을 준수합니다.",
    "자원의 효율적인 사용과 정기적인 평가를 통하여 환경 영향을 최소화하는 것을 추구합니다.":
      "자원의 효율적인 사용과 정기적인 평가를 통하여 환경 영향을 최소화하는 것을 추구합니다.",
    "친환경 제품 생산을 위한 노력을 지속합니다.":
      "친환경 제품 생산을 위한 노력을 지속합니다.",
    "하이딥 분쟁광물 정책": "하이딥 분쟁광물 정책",
    "우리는 Dodd-Frank Act를 지지하며, 우리 제품의 구성 소재가 DRC지역으로부터 기원 되지 않았음을 보장하기 위하여 아래의 원칙을 준수합니다.":
      "우리는 Dodd-Frank Act를 지지하며, 우리 제품의 구성 소재가 DRC지역으로부터 기원 되지 않았음을 보장하기 위하여 아래의 원칙을 준수합니다.",
    "모든 협력업체에게 문서화 된 실사 자료(EICC-GeSI 분쟁광물 조사 양식)의 제출을 요구합니다. ":
      "모든 협력업체에게 문서화 된 실사 자료(EICC-GeSI 분쟁광물 조사 양식)의 제출을 요구합니다. ",
    "모든 협력업체에게 분쟁광물과 관련된 정책 및 경영시스템을 도입하도록 장려합니다.":
      "모든 협력업체에게 분쟁광물과 관련된 정책 및 경영시스템을 도입하도록 장려합니다.",
    "기본 윤리": "기본 윤리",
    "임직원은 자신의 권한과 책임을 명확히 인식하고 정직과 신뢰의 원칙에 입각하여 성실하게 업무를 수행해야 한다.":
      "임직원은 자신의 권한과 책임을 명확히 인식하고 정직과 신뢰의 원칙에 입각하여 성실하게 업무를 수행해야 한다.",
    "고객에 대한 책임": "고객에 대한 책임",
    "고객의 요구사항을 정확히 파악하기 위한 모든 노력을 다하여야 하며, 고객의 정당한 요구와 합리적인 제안을 적극적으로 수용하고, 고객과의 약속은 반드시 이행해야 한다.":
      "고객의 요구사항을 정확히 파악하기 위한 모든 노력을 다하여야 하며, 고객의 정당한 요구와 합리적인 제안을 적극적으로 수용하고, 고객과의 약속은 반드시 이행해야 한다.",
    "구성원에 대한 책임": "구성원에 대한 책임",
    "회사는 임직원의 인격을 존중하고 역량과 성과에 따라 공정하고 합리적으로 대우한다.":
      "회사는 임직원의 인격을 존중하고 역량과 성과에 따라 공정하고 합리적으로 대우한다.",
    "회사는 임직원이 상호 신뢰와 자긍심을 갖고, 도전정신과 창의성을 발현하여, 동료와 더불어 행복을 추구할 수 있도록 최선의 노력을 다해야 한다.":
      "회사는 임직원이 상호 신뢰와 자긍심을 갖고, 도전정신과 창의성을 발현하여, 동료와 더불어 행복을 추구할 수 있도록 최선의 노력을 다해야 한다.",
    "주주에 대한 책임": "주주에 대한 책임",
    "회사는 끊임없는 혁신과 투명하고 효율적인 경영으로 기업가치를 극대화하며, 그 성과를 주주와 공유한다.":
      "회사는 끊임없는 혁신과 투명하고 효율적인 경영으로 기업가치를 극대화하며, 그 성과를 주주와 공유한다.",
    "협력회사와의 관계": "협력회사와의 관계",
    "회사는 협력회사를 상호 신뢰의 기반 위에서 고객행복이라는 공동가치를 추구하는 전략적 동반자로 인식한다.":
      "회사는 협력회사를 상호 신뢰의 기반 위에서 고객행복이라는 공동가치를 추구하는 전략적 동반자로 인식한다.",
    "사회에 대한 책임": "사회에 대한 책임",
    "회사는 환경보호 관련 국제 기준 및 관련 법령, 내부 규정 등을 준수하고, 환경친화적 경영을 실천한다.":
      "회사는 환경보호 관련 국제 기준 및 관련 법령, 내부 규정 등을 준수하고, 환경친화적 경영을 실천한다.",
    "윤리상담·신고 및 제보자 보호": "윤리상담·신고 및 제보자 보호",
    "회사는 상담·신고자 보호 및 상담·신고 장려를 위해 최선의 노력을 다한다.":
      "회사는 상담·신고자 보호 및 상담·신고 장려를 위해 최선의 노력을 다한다.",
    ADDRESS: "ADDRESS",
    "- 경기도 성남시 분당구 대왕판교로644번길 49 DTC타워 10층 하이딥":
      "- 경기도 성남시 분당구 대왕판교로644번길 49 DTC타워 10층 하이딥",
    "• Room 1801 , Building 7C, LongGuang JiuLongTai, West GuanGuang Road, GuangMing new District, ShenZhen City, China. 518132":
      "• Room 1801 , Building 7C, LongGuang JiuLongTai, West GuanGuang Road, GuangMing new District, ShenZhen City, China. 518132",
    TEL: "TEL",
    "+82-31-717-5775": "+82-31-717-5775",
    "E - MAIL": "E - MAIL",
    "contact@hideep.com": "contact@hideep.com",
    "sales@hideep.com ": "sales@hideep.com ",
    // careers
    // careers
    // careers
    // careers
    // careers
    // careers

    "하이딥의 불꽃같은 여정에 당신을 초대합니다.":
      "하이딥의 불꽃같은 여정에 당신을 초대합니다.",

    "언제나 근본적인 '어떻게?' 를 찾아가는 하이딥은":
      "언제나 근본적인 '어떻게?' 를 찾아가는 하이딥은",

    "함께하는 사람들의 가치를 가장 중요하게 생각합니다.":
      "함께하는 사람들의 가치를 가장 중요하게 생각합니다.",

    "누구나 이미 알고 있다고": "누구나 이미 알고 있다고",

    "모두가 방법을 찾을 수는 없습니다.": "모두가 방법을 찾을 수는 없습니다.",

    "길이 보이지 않는다면 ": "길이 보이지 않는다면 ",

    "내딛는 첫 걸음으로 길이 열립니다.": "내딛는 첫 걸음으로 길이 열립니다.",

    "하이딥 주요 복지": "하이딥 주요 복지",

    "하이딥 주요 복지": "하이딥 주요 복지",

    "PS (Profit Sharing) / PI (Productivity Incentive)":
      "PS (Profit Sharing) / PI (Productivity Incentive)",

    "일정 기준에 따라 회사의 이익을": "일정 기준에 따라 회사의 이익을",

    "직원들과 공유합니다.": "직원들과 공유합니다.",

    "공로상 & 열정상": "공로상 & 열정상",

    "공로상 & 열정상 등 업무에 열정적인 분들을":
      "공로상 & 열정상 등 업무에 열정적인 분들을",

    "매년 선정하여 보상합니다.": "매년 선정하여 보상합니다.",

    "전 임직원에게 스톡 혹은 옵션 지급": "전 임직원에게 스톡 혹은 옵션 지급",

    "전 임직원에게 스톡 혹은 옵션을 지급하여":
      "전 임직원에게 스톡 혹은 옵션을 지급하여",

    "모두가 하이딥의 주인으로 일을 합니다.":
      "모두가 하이딥의 주인으로 일을 합니다.",

    "자율 출퇴근제": "자율 출퇴근제",

    "컴퓨터 환경 제공": "컴퓨터 환경 제공",

    "넓고 쾌적한 사무환경": "넓고 쾌적한 사무환경",

    "자율 출퇴근제로 각자의 사정에 따라": "자율 출퇴근제로 각자의 사정에 따라",

    "업무 시간 조정이 가능합니다.": "업무 시간 조정이 가능합니다.",

    "일하기 편리하도록 원하는 사양의": "일하기 편리하도록 원하는 사양의",

    "컴퓨터 환경을 제공합니다. (Mac 포함)":
      "컴퓨터 환경을 제공합니다. (Mac 포함)",

    "판교에 환하고 넓고 쾌적한 사무 환경에서":
      "판교에 환하고 넓고 쾌적한 사무 환경에서",

    "일을 합니다. (초록 나무들과 다량의 공기청정기 설치)":
      "일을 합니다. (초록 나무들과 다량의 공기청정기 설치)",

    "도보 10분 거리 기숙사 제공": "도보 10분 거리 기숙사 제공",

    "판교 내 도보 10분 거리의 신규 아파트를":
      "판교 내 도보 10분 거리의 신규 아파트를",

    "기숙사로 제공합니다.": "기숙사로 제공합니다.",

    "건강하고 맛있는 간식": "건강하고 맛있는 간식",

    "건강하고 맛있는 간식과 음료가": "건강하고 맛있는 간식과 음료가",

    "상시 제공됩니다.": "상시 제공됩니다.",

    "매년 종합검진 지원": "매년 종합검진 지원",

    "100만원 상당의 종합검진을 매년 실시하여":
      "100만원 상당의 종합검진을 매년 실시하여",

    "건강하게 일을 할 수 있도록 지원합니다.":
      "건강하게 일을 할 수 있도록 지원합니다.",

    "주차장 비용 지원": "주차장 비용 지원",

    "휘트니스 센터 비용 지원": "휘트니스 센터 비용 지원",

    "콘도 회원가 이용": "콘도 회원가 이용",

    "회사 인근 주차장 이용 시 한도 제한 없이":
      "회사 인근 주차장 이용 시 한도 제한 없이",

    "총비용의 50% 지원합니다.": "총비용의 50% 지원합니다.",

    "사내 휘트니스센터 및 외부 운동시설 이용 비용의 50%,":
      "사내 휘트니스센터 및 외부 운동시설 이용 비용의 50%,",

    "최대 3만원을 지원합니다.": "최대 3만원을 지원합니다.",

    "쏠비치, 소노펠리체, 휘닉스 아일랜드 등 (대명 및 휘닉스 계열 콘도)":
      "쏠비치, 소노펠리체, 휘닉스 아일랜드 등 (대명 및 휘닉스 계열 콘도)",

    "회원가로 이용할 수 있습니다.": "회원가로 이용할 수 있습니다.",

    "시간 외 실비 제공": "시간 외 실비 제공",

    "시간 외 실비 (식사 & 교통비)": "시간 외 실비 (식사 & 교통비)",

    "별도 지급합니다.": "별도 지급합니다.",

    "경조사 휴가 제공": "경조사 휴가 제공",

    "결혼 (5일), 회갑 등 경조사 휴가 제공 및":
      "결혼 (5일), 회갑 등 경조사 휴가 제공 및",

    "유연한 연차 제도를 운영합니다.": "유연한 연차 제도를 운영합니다.",

    "장기 근속 임직원 포상제도 실시": "장기 근속 임직원 포상제도 실시",

    "장기 근속 임직원에게 포상을": "장기 근속 임직원에게 포상을",

    "제공하여 함께 성장합니다. ": "제공하여 함께 성장합니다. ",

    "내부 보상제도 운영": "내부 보상제도 운영",

    "축구 동호회 활동 지원": "축구 동호회 활동 지원",

    "하이딥 IC 탑재 제품 구매 지원": "하이딥 IC 탑재 제품 구매 지원",

    "인재 추천, 직무 발명 등 ": "인재 추천, 직무 발명 등 ",

    "각종 내부 보상제도를 운영합니다.": "각종 내부 보상제도를 운영합니다.",

    "건강하고 즐거운 회사 생활을 위해": "건강하고 즐거운 회사 생활을 위해",

    "축구 동호회 활동을 지원합니다.": "축구 동호회 활동을 지원합니다.",

    "때때로 하이딥 IC가 탑재되는": "때때로 하이딥 IC가 탑재되는",

    "제품의 구매를 지원합니다.": "제품의 구매를 지원합니다.",

    "여러 사람들의 다양한 관점과 인사이트를 통해":
      "여러 사람들의 다양한 관점과 인사이트를 통해",

    "새로운 방법을 찾는 짜릿한 여정에 당신을 초대합니다.":
      "새로운 방법을 찾는 짜릿한 여정에 당신을 초대합니다.",

    "채용 정보": "채용 정보",

    "상시채용   Digital Enginner": "상시채용   Digital Enginner",
    "상시채용   Admin": "상시채용   Admin",
    담당업무: "담당업무",

    "공시 / IR / 주식업무": "공시 / IR / 주식업무",

    필수요건: "필수요건",

    "상장사 공시 및 IR, 주식 업무 3년 이상":
      "상장사 공시 및 IR, 주식 업무 3년 이상",

    우대조건: "우대조건",

    "- 외부투자유치 실무업무 수행자": "- 외부투자유치 실무업무 수행자",

    "- 영어 가능자": "- 영어 가능자",

    "지원 방법": "지원 방법",

    "궁금한 내용이 있으시면 편하게 연락 주세요.":
      "궁금한 내용이 있으시면 편하게 연락 주세요.",

    "담당  |  김경영 부장": "담당  |  김경영 부장",

    "전화  |  070-4164-7036": "전화  |  070-4164-7036",

    "이메일  |  mariana.kim@hideep.com": "이메일  |  mariana.kim@hideep.com",

    // Tech
    // Tech
    // Tech
    // Tech
    // Tech
    // Tech
  },
};

export default ko;

import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

//careers
import meeting1 from "asset/careers/meeting1.jpeg";
import meeting2 from "asset/careers/meeting2.jpeg";
import office9 from "asset/careers/office9.jpeg";
import office8 from "asset/careers/office8.jpeg";
import office10 from "asset/careers/office10.jpeg";
import test3 from "asset/careers/test3.jpeg";
import test4 from "asset/careers/test4.jpeg";
import test5 from "asset/careers/test5.jpeg";
import test6 from "asset/careers/test6.jpeg";
import test7 from "asset/careers/test7.jpeg";
import work8 from "asset/careers/work8.jpeg";
import work9 from "asset/careers/work9.jpeg";
import work10 from "asset/careers/work10.jpeg";
import newsLoading from "asset/newsLoading.png";
import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { app, db } from "firebaseConfig";
import {
  getStorage,
  ref,
  deleteObject,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";
import { Viewer } from "@toast-ui/react-editor";
import "prismjs/themes/prism.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import Prism from "prismjs";
import Link from "@mui/material/Link";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
// import { UsePagination } from "component/components";
import usePagination from "@mui/material/usePagination";

function Company(props) {
  const { t } = useTranslation();
  const location = useLocation();
  let page = queryString.parseUrl(window.location.search).query?.page ?? "1";
  console.log("페이지", page);
  const List = styled("ul")({
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  });
  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");

  const [newsroomPage, setNewRoomPage] = React.useState(page);
  const newsroomChange = (e, number) => {
    console.log("l");
    setNewRoomPage(number);
  };

  const itemsPerPage = 10;

  const [newsroom, setNewsroom] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        await init();
        setRefresh(false);
      }
    })();
  }, [refresh]);
  React.useEffect(() => {
    (async () => {
      console.log("바뀜", newsroomPage);
    })();
  }, [newsroomPage]);
  async function init() {
    const car = await getFirebaseDoc("newsroom");
    setNewsroom(car.posts);
  }
  async function getFirebaseDoc(table) {
    try {
      const querySnapshot = await getDocs(collection(db, table));

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }
  async function download(path) {
    const storage = getStorage(app);
    try {
      console.log("시작");
      const httpsReference = ref(storage, path);
      console.log(ref);
      const metadata = await getMetadata(httpsReference);
      const fileName = metadata.name;
      console.log("ff", fileName);
      const url = await getDownloadURL(httpsReference);

      console.log(url);
      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      handleDownload(url, fileName);
      return;
    } catch (e) {
      alert(e.message);
      return;
    }
  }
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        fileDownload(res.data, filename);
      });
  };
  function getFileName(path) {
    const storage = getStorage(app);
    const httpsReference = ref(storage, path);
    console.log(httpsReference);
    console.log("파일", httpsReference.name);
    return httpsReference.name;
    // getMetadata(httpsReference).then((meta) => meta.name);
  }

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgWidthFitBox.lgtheme, height: height ?? "" }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          maxWidth: "1294px",
          padding: "0",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        {props.children}
      </Container>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, padding: "0 0px" }
            : { ...styles.paddingBox.smtheme, padding: "0 5px" }
        }
      >
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }

  const { items } = usePagination({
    page: Number(newsroomPage),

    count: Math.ceil(newsroom?.length / itemsPerPage),
    onChange: (e, page) => {
      setNewRoomPage(page);
    },
  });
  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }
  console.log(newsroom && newsroom.length);
  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  return (
    <React.Fragment>
      {/* 빈공간 */}
      {/* 스티키 헤더 */}
      <BasicAppBar />
      <EmptySpace height={matches ? "250px" : "83px"} />
      {/* <ImgWidthFit src={hi} /> */}
      {/* <ImgScaleFit src={connect} /> */}
      <CustomContainer>
        <PaddingBox>
          <Typography variant="newsh2">{t(`HIDEEP`)}</Typography>
          <EmptySpace height={matches ? "7px" : "0px"} />

          <Typography variant="newsh1">{t(`Newsroom`)}</Typography>
          <EmptySpace height={matches ? "115px" : "45px"} />
          <Box sx={{}}>
            <Grid
              container
              spacing={matches ? 5 : 0}
              columnSpacing={matches ? 4 : 0}
            >
              {newsroom &&
                newsroom
                  .slice(0)
                  .reverse()
                  .map((v, i) => (
                    <>
                      {itemsPerPage * newsroomPage >= i + 1 &&
                        itemsPerPage * newsroomPage - itemsPerPage < i + 1 && (
                          <>
                            <Grid key={i} item xs={12} md={3}>
                              <Link
                                underline="none"
                                button
                                href={`/news/${v.id}?page=${newsroomPage}`}
                              >
                                {/* <ImgWidthFit height="164px" src={v.data.files[0]} /> */}
                                <Box
                                  key={i}
                                  style={
                                    matches
                                      ? {
                                          width: "100%",
                                          position: "relative",

                                          maxHeight: "164px",
                                          aspectRatio: "1.896 / 1",
                                          backgroundImage: `url(${
                                            v.data.files[0]
                                              ? v.data.files[0]
                                              : newsLoading
                                          })`,
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "50% 50%",
                                          overflow: "hidden",
                                        }
                                      : {
                                          width: "100%",
                                          position: "relative",

                                          aspectRatio: "1.896 / 1",
                                          backgroundImage: `url(${
                                            v.data.files[0]
                                              ? v.data.files[0]
                                              : newsLoading
                                          })`,
                                          backgroundSize: "cover",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "50% 50%",
                                          overflow: "hidden",
                                        }
                                  }
                                />
                              </Link>
                            </Grid>
                            <Grid item xs={0} md={1}></Grid>
                            <Grid item xs={matches ? 6 : 8} md={8}>
                              <Link
                                underline="none"
                                button
                                href={`/news/${v.id}?page=${newsroomPage}`}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                  }}
                                >
                                  <EmptySpace
                                    height={matches ? "0px" : "5px"}
                                  ></EmptySpace>

                                  <Box
                                    sx={
                                      matches
                                        ? { ...styles.paddingBox.lgtheme }
                                        : { ...styles.paddingBox.smtheme }
                                    }
                                  >
                                    {" "}
                                    <EmptySpace
                                      height={matches ? "0px" : "5px"}
                                    ></EmptySpace>
                                    <Typography
                                      variant="h6"
                                      sx={
                                        matches
                                          ? styles.newsTitle.lgtheme
                                          : styles.newsTitle.smtheme
                                      }
                                    >
                                      {v.data.title}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      sx={
                                        matches
                                          ? styles.newsInfo.lgtheme
                                          : styles.newsInfo.smtheme
                                      }
                                    >
                                      {v.data.date} {"\u00a0"}| {"\u00a0"}
                                      {v.data.category}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Link>
                            </Grid>
                          </>
                        )}
                    </>
                  ))}
            </Grid>
          </Box>
          <EmptySpace height={matches ? "40px" : "30px"} />

          <Box>
            {newsroom?.length > 10 && (
              <nav>
                <List>
                  {items.map(
                    ({ page, type, selected, disabled, ...item }, index) => {
                      let children = null;

                      if (
                        type === "start-ellipsis" ||
                        type === "end-ellipsis"
                      ) {
                        children = "…";
                      } else if (type === "page") {
                        children = (
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <button
                              type="button"
                              style={{
                                border: "0px solid white",
                                background: "none",
                                fontWeight: selected ? "bold" : undefined,
                                textDecoration: selected
                                  ? "underline"
                                  : undefined,
                                textDecorationThickness: "1px",
                                textUnderlinePosition: "under",
                                fontSize: "15px",
                              }}
                              {...item}
                            >
                              {page}
                            </button>
                          </div>
                        );
                      } else {
                        if (type === "previous") {
                          children = (
                            <button
                              type="button"
                              style={{
                                display: disabled ? "none" : undefined,
                                border: "0px solid white",
                                background: "none",
                                fontWeight: selected ? "bold" : undefined,
                                textDecoration: selected
                                  ? "underline"
                                  : undefined,
                                textDecorationThickness: "1px",
                                textUnderlinePosition: "under",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                              {...item}
                            >
                              {"<"}
                            </button>
                          );
                        } else if (type === "next") {
                          children = (
                            <button
                              type="button"
                              style={{
                                display: disabled ? "none" : undefined,
                                border: "0px solid white",
                                background: "none",
                                fontWeight: selected ? "bold" : undefined,
                                textDecoration: selected
                                  ? "underline"
                                  : undefined,
                                textDecorationThickness: "1px",
                                textUnderlinePosition: "under",
                                fontWeight: "bold",
                                fontSize: "15px",
                              }}
                              {...item}
                            >
                              {">"}
                            </button>
                          );
                        } else {
                          children = (
                            <button type="button" {...item}>
                              {type}
                            </button>
                          );
                        }
                      }

                      return <li key={index}>{children}</li>;
                    }
                  )}
                </List>
              </nav>
            )}
          </Box>
        </PaddingBox>
      </CustomContainer>
      <EmptySpace height={matches ? "210px" : "80px"} />
    </React.Fragment>
  );
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;

import React from "react";

const styles = {
  fixedContainer: {
    lgtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",
      marginTop: "160px",
      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
      backgroundColor: "purple",
    },
    smtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",

      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
  },
  firstImageBox: {
    lgtheme: {
      position: "relative",
      height: "1109px",
      textAlign: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      display: "block",
      minWidth: "100%",
      lineHeight: "0",
    },
    smtheme: { lineHeight: "0" },
  },
  firstImage: {
    lgtheme: {
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      msTransform: " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },
  imgWidthFitBox: {
    lgtheme: {
      width: "100%",
    },
    smtheme: {
      width: "100%",
    },
  },
  imgWidthFit: {
    lgtheme: {
      width: "100%",
      margin: "0",
    },
    smtheme: { width: "100%" },
  },
  imgKeepLengthBox: {
    lgtheme: {
      position: "relative",
      height: "1109px",
      textAlign: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      display: "block",
      minWidth: "100%",
    },
    smtheme: {},
  },
  imgKeepLength: {
    lgtheme: {
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      msTransform: " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },

  firstImageBox: {
    lgtheme: {
      position: "relative",
      height: "1109px",
      textAlign: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      display: "block",
      minWidth: "100%",
      lineHeight: "0",
    },
    smtheme: { lineHeight: "0" },
  },
  firstImage: {
    lgtheme: {
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      msTransform: " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },

  fixedBox: {
    lgtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",
      marginTop: "25vh",
      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
    smtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",
      marginTop: "25vh",
      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
  },
  copyRight: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
  },
  copyRight: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
  },
  ceoQuote: {
    lgtheme: {
      fontWeight: "400",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      lineHeight: "1.1",
      fontSize: "16px",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      padding: "4px 0",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
  },
  imgScaleFitBox: {
    lgtheme: {
      position: "relative",
      boxSizing: "border-box",
      display: "block",
      height: "874px",
      minWidth: "100%",
    },
    smtheme: {},
  },
  imgScaleFit: {
    lgtheme: {
      maxHeight: "874px",
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      // objectFit: "cover",

      left: "50%",
      msTransform: " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },
  ceoImageBox: {
    lgtheme: {
      position: "relative",
      boxSizing: "border-box",
      display: "block",
      height: "874px",
      minWidth: "100%",
    },
    smtheme: {},
  },
  ceoImage: {
    lgtheme: {
      maxHeight: "874px",
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      objectFit: "cover",

      left: "50%",
      msTransform: " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },

  oneDes: {
    lgtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "600",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-0.3px",
      color: "#1a1e24",
      fontSize: "48px",
      lineHeight: "1.1",
      paddingTop: "13px",
    },
    smtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "600",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-0.03vw",
      color: "#1a1e24",
      fontSize: "3.5vw",
      lineHeight: "1.1",
      paddingTop: "14px",
    },
  },
  firstOne: {
    lgtheme: {
      fontFamily: "NanumBarunGothic",

      fontWeight: "bold",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "220px",
      lineHeight: "1.1",
      paddingLeft: "46px",
      paddingBottom: "-4px",
    },
    smtheme: {},
  },
  one: {
    lgtheme: {
      fontFamily: "NanumBarunGothic",

      fontWeight: "bold",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "220px",
      lineHeight: "1.1",
      paddingLeft: "0px",
      paddingBottom: "-4px",
    },
    smtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "84px",
      lineHeight: "1.1",
      paddingLeft: "10px",
    },
  },
  paddingBox: {
    lgtheme: { padding: "0 5px" },

    smtheme: { padding: "0 15px" },
  },
  paddingBoxCompany: {
    lgtheme: { padding: "0 0px" },

    smtheme: { padding: "0 15px" },
  },
  careerTitle: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(26, 30, 36)",
      fontSize: "72px",
      lineHeight: "1.1",
      paddingBottom: "37px",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "26px",
      lineHeight: "1.1",
      paddingBottom: "40px",
    },
  },
  newsDes: {
    lgtheme: {
      fontWeight: "normal",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      fontSize: "22px",
      fontWeight: "400",
      letterSpacing: "-1px",
      lineHeight: "1.2",
      color: "color: 'rgb(26, 30, 36)'",
    },
    smtheme: {
      fontWeight: "normal",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "13px",
      lineHeight: "1.65",
      paddingBottom: "42px",
      whiteSpace: "pre-line",
    },
  },
  moreLink: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(0, 51, 159)",
      fontSize: "20px",
      fontWeight: "400",
      letterSpacing: "-1px",
      lineHeight: "1.2",
      paddingBottom: "100px",
      wordWrap: "break-word",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "14px",
      lineHeight: "1.1",
      paddingTop: "20px",
      paddingBottom: "60px",
    },
  },
  newsImage: {
    lgtheme: {
      maxWidth: "100%",
      backgroundColor: "#1a1e24",
      textAlign: "end",
      color: "white",
    },
    smtheme: {
      width: "100%",
      textAlign: "end",
      color: "white",
      paddingBottom: "10px",
    },
  },
  newsTitle: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(29, 29, 29)",
      lineHeight: "1.7",
      padding: "2px 0px",
      fontSize: "18px",
      whiteSpace: "pre-line",
      maxWidth: "410px",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7 eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "13px",
      lineHeight: "1.6",
      padding: "0px 0px",
      paddingTop: "10px",
    },
  },
  newsInfo: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      lineHeight: "2",
      fontSize: "14px",
      letterSpacing: "0px",
      color: "#979797",

      padding: "0px 0px",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      lineHeight: "1.1",
      fontSize: "13px",
      letterSpacing: "-1px",
      color: "rgb(165, 165, 165)",
      whiteSpace: "pre-line",

      padding: "4px 0px",
      paddingBottom: "40px",
    },
  },
  lastBox: {
    lgtheme: {
      padding: "0 5px",
      paddingTop: "200px",
      paddingBottom: "200px",

      maxWidth: "100%",
      backgroundColor: "#1a1e24",
      textAlign: "end",
      color: "white",
    },
    smtheme: {
      paddingTop: "48px",
      paddingBottom: "48px",
      maxWidth: "100%",
      backgroundColor: "#1a1e24",
      textAlign: "end",
      color: "white",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },

  lastTitle: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "14px",
      lineHeight: "1.1",
    },
  },
  lastBody: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "60px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "20px",
      lineHeight: 1.4,
    },
  },
  copyRight: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "14px",
      lineHeight: "1.1",
    },
  },
};

export default styles;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ko from "resource/language/ko";
import en from "resource/language/en";

const resources = {
  ko,
  en,
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "ko",
});
export default i18n;

import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import Link from "@mui/material/Link";
import $ from "jquery";
import { TweenLite, Power4 } from "gsap";

import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import { app, db } from "firebaseConfig";

import i18n from "i18n";
import "./main.css";
import "./iamport.cm";

import { maxWidth } from "@mui/system";
import newsLoading from "asset/newsLoading.png";
import main1 from "asset/main/main1.jpeg";
import main2 from "asset/main/main2.jpeg";
import main3 from "asset/main/main3.jpeg";
import main4 from "asset/main/main4.jpeg";
import main5 from "asset/main/main5.png";

const styles = {
  fixedContainer: {
    lgtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",
      marginTop: "160px",
      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
    smtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",

      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
  },
  firstImageBox: {
    lgtheme: {
      position: "relative",
      height: "1000px",
      textAlign: "center",
      overflow: "hidden",
      boxSizing: "border-box",
      display: "block",
      minWidth: "100%",
    },
    smtheme: {},
  },
  firstImage: {
    lgtheme: {
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      "-ms-transform": " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },

  fixedBox: {
    lgtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",
      marginTop: "25vh",
      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
    smtheme: {
      margin: "0",
      padding: "0",
      display: "inline",
      display: "inline-block",
      marginTop: "25vh",
      paddingTop: "100px",
      lineHeight: "0.96rem",

      letterSpacing: "-0.0533rem",
      position: "relative",
    },
  },

  copyRight: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
  },
  ceoQuote: {
    lgtheme: {
      fontWeight: "400",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      lineHeight: "1.1",
      fontSize: "16px",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      padding: "4px 0",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
  },
  ceoImageBox: {
    lgtheme: {
      position: "relative",
      boxSizing: "border-box",
      display: "block",
      height: "874px",
      minWidth: "100%",
    },
    smtheme: {},
  },
  ceoImage: {
    lgtheme: {
      maxHeight: "874px",
      minWidth: "100%",
      margin: "0",
      position: "absolute",
      top: "50%",
      objectFit: "cover",

      left: "50%",
      "-ms-transform": " translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
    smtheme: { maxWidth: "100%" },
  },

  oneDes: {
    lgtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "600",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-0.3px",
      color: "#1a1e24",
      fontSize: "48px",
      lineHeight: "1.1",
      paddingTop: "13px",
    },
    smtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-0.03vw",
      color: "#1a1e24",
      fontSize: "3.7vw",
      lineHeight: "0",
      paddingTop: "14px",
    },
  },
  firstOne: {
    lgtheme: {
      fontFamily: "NanumBarunGothic",

      fontWeight: "bold",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "220px",
      lineHeight: "1.1",
      paddingLeft: "46px",
      paddingBottom: "-4px",
    },
    smtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "70px",
      lineHeight: "1.1",
      paddingLeft: "15px",
    },
  },
  one: {
    lgtheme: {
      fontFamily: "NanumBarunGothic",

      fontWeight: "bold",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "220px",
      lineHeight: "1.1",
      paddingLeft: "0px",
      paddingBottom: "-4px",
    },
    smtheme: {
      fontFamily: "NanumBarunGothic",
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",

      color: "#1a1e24",
      fontSize: "70px",
      lineHeight: "1.1",
      paddingLeft: "0px",
    },
  },
  paddingBox: {
    lgtheme: { padding: "0 5px" },

    smtheme: { padding: "0 15px" },
  },
  careerTitle: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(26, 30, 36)",
      fontSize: "72px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "26px",
      lineHeight: "1.1",
    },
  },
  newsDes: {
    lgtheme: {
      fontWeight: "normal",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      fontSize: "22px",
      fontWeight: "400",
      letterSpacing: "-1px",
      lineHeight: "1.2",
      color: "color: 'rgb(26, 30, 36)'",
    },
    smtheme: {
      fontWeight: "normal",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "13px",
      lineHeight: "1.65",

      whiteSpace: "pre-line",
    },
  },
  moreLink: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(0, 51, 159)",
      fontSize: "20px",
      fontWeight: "400",
      letterSpacing: "-1px",
      lineHeight: "1.2",

      wordWrap: "break-word",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "14px",
      lineHeight: "1.1",
    },
  },
  newsImage: {
    lgtheme: {
      maxWidth: "100%",
      backgroundColor: "#1a1e24",
      textAlign: "end",
      color: "white",
    },
    smtheme: {
      width: "100%",
      textAlign: "end",
      color: "white",
      paddingBottom: "10px",
    },
  },
  newsTitle: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(29, 29, 29)",
      lineHeight: "1.6",
      padding: "2px 0px",
      fontSize: "18px",
      whiteSpace: "pre-line",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7 eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "13px",
      lineHeight: "1.5",
      padding: "2px 0px",
    },
  },
  newsInfo: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      lineHeight: "2",
      fontSize: "14px",
      letterSpacing: "0px",
      color: "#979797",

      padding: "0px 0px",
    },
    smtheme: {
      fontWeight: "400",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      lineHeight: "1.1",
      fontSize: "13px",
      letterSpacing: "-1px",
      color: "rgb(165, 165, 165)",
      whiteSpace: "pre-line",

      padding: "4px 0px",
    },
  },
  lastBox: {
    lgtheme: {
      padding: "0 5px",
      paddingTop: "200px",
      paddingBottom: "200px",

      maxWidth: "100%",
      backgroundColor: "#1a1e24",
      textAlign: "end",
      color: "white",
    },
    smtheme: {
      paddingTop: "48px",
      paddingBottom: "48px",
      maxWidth: "100%",
      backgroundColor: "#1a1e24",
      textAlign: "end",
      color: "white",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },

  lastTitle: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "14px",
      lineHeight: "1.1",
    },
  },
  lastBody: {
    lgtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "60px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "20px",
      lineHeight: 1.45,
    },
  },
  copyRight: {
    lgtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "0px",
      color: "rgb(255, 255, 255)",
      fontSize: "30px",
      lineHeight: "1.1",
    },
    smtheme: {
      fontFamily: "Inter",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "rgb(255, 255, 255)",
      fontSize: "14px",
      lineHeight: "1.1",
    },
  },
};
function HoverScalingImg({ aspectRatio, img }) {
  return (
    <Box
      style={{
        overflow: "hidden",
        width: "100%",
        // position: "relative",
        aspectRatio: aspectRatio,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        // display: "inline-block",

        overflow: "hidden",
      }}
    >
      <Box
        draggable="false"
        style={{
          overflow: "hidden",
          width: "100%",
          // position: "relative",
          height: "100%",

          aspectRatio: aspectRatio,
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          // display: "block",
        }}
        className="zoom"
      ></Box>
    </Box>
  );
}

function Main(props) {
  const { t } = useTranslation();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "en"
  );

  const [newsroom, setNewsroom] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        await init();
        setRefresh(false);
      }
    })();
  }, [refresh]);

  async function init() {
    const car = await getFirebaseDoc("newsroom");
    console.log("carcar", car);
    setNewsroom(car.posts);
  }
  async function getFirebaseDoc(table) {
    try {
      const q = query(
        collection(db, table),
        orderBy("created", "desc"),
        limit(3)
      );
      const querySnapshot = await getDocs(q);

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }
  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }
  React.useEffect(() => {
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  if (window.addEventListener)
    window.addEventListener("DOMMouseScroll", wheel, { passive: false });
  window.onmousewheel = document.onmousewheel = wheel;

  function wheel(event) {
    const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

    // if (!fixedRange) {
    //   return;
    // }
    var delta = 0;
    // console.log("ee", event);
    // if (event.wheelDelta) delta = event.wheelDelta / 120;
    // else if (event.detail) delta = -event.detail / 3;
    if (event.wheelDelta) delta = event.wheelDelta / 120;
    else if (event.detail) delta = -event.detail / 3;

    handle(delta);
    if (event.preventDefault) event.preventDefault();
    event.returnValue = false;
  }
  function handle(delta) {
    var time = 800;
    var distance = 100;
    // gsap.to(fixedBoxRef && fixedBoxRef.current, 1.2, {
    //   scrolltop: this.scrollPosition,
    //   ease: "power4.easeOut",
    //   overwrite: 1,
    //   onUpdate: () => {},
    //   onComplete: () => {},
    // });
    // $("html, body")
    // $(".smooth");

    // console.log("ofddddddddddddddf" - window.pageYOffset);
    // gsap.to(window, 1, {
    //   y: -window.pageYOffset,
    //   ease: Power4.easeOut,
    // });
    // $("html, body")
    //   .stop()
    //   .animate(
    //     {
    //       scrollTop: $(window).scrollTop() - distance * delta,
    //     },
    //     time
    //   );
  }

  const FixedText = styled(Typography)(({ theme }) =>
    matches
      ? {
          // fontFamily: "NanumBarunGothic",
          fontSize: "3.3vw",
          // // lineHeight: "4.2vw",
          // fontSize: "5.0vw",
          lineHeight: "6.2vw",
          // whiteSpace: "nowrap",
          // wordBreak: "keep-all",
          // // lineHeight: "4.2vw",
          // fontWeight: "700",
          // letterSpacing: "-0.02vw",
          // fontSize: "60px",
          color: "#000",
          fontWeight: "600",
          letterSpacing: "0px",
          // lineHeight: "120px",
          // lineHeight: "1",
        }
      : {
          fontSize: "3.3vw",
          // // lineHeight: "4.2vw",
          // fontSize: "5.0vw",
          lineHeight: "8.2vw",
          // whiteSpace: "nowrap",
          // wordBreak: "keep-all",
          // // lineHeight: "4.2vw",
          // fontWeight: "700",
          // letterSpacing: "-0.02vw",
          // fontSize: "60px",
          color: "#000",
          fontWeight: "600",
          letterSpacing: "0px",
        }
  );

  // function HoverScalingImg({ aspectRatio, img }) {
  //   return (
  //     // <Box
  //     //   style={{
  //     //     overflow: "hidden",
  //     //     width: "100%",
  //     //     position: "relative",
  //     //     aspectRatio: aspectRatio,
  //     //     backgroundSize: "cover",
  //     //     backgroundRepeat: "no-repeat",
  //     //     backgroundPosition: "50% 50%",
  //     //     display: "inline-block",

  //     //     overflow: "hidden",
  //     //   }}
  //     // >
  //     <img src={} />

  //     // <image
  //     //   // src={`${img})`}
  //     //   src={"https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FbNZo14%2Fbtq17bsvo5i%2FOSV3gGWnwAKqNB0EOSQkrK%2Fimg.png"}
  //     //   style={{
  //     //     overflow: "hidden",
  //     //     width: "100%",
  //     //     // position: "relative",
  //     //     height: "100%",

  //     //     // aspectRatio: aspectRatio,
  //     //     // backgroundImage: `url(${img})`,
  //     //     // backgroundSize: "cover",
  //     //     // backgroundRepeat: "no-repeat",
  //     //     // backgroundPosition: "50% 50%",
  //     //     // display: "block",
  //     //   }}
  //     //   className="zoom"
  //     // ></image>
  //     // </Box>
  //   );
  // }
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);

  const digitalRef = React.useRef();
  const transformationRef = React.useRef();
  const textBoxRef = React.useRef();

  const [digitalState, setDigitalState] = React.useState(null);
  const [transformationState, setTransformationState] = React.useState(null);
  const [textBoxState, setTextBoxState] = React.useState(null);

  const fixedBoxRef = React.useRef();
  // const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();
  const movingBoxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);
  const [ovingBox, setMovingBox] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  const middle = vh / 2;
  const [middleLine, setMiddleLine] = React.useState(() => middle);
  const handleScroll = () => {
    const position = window.pageYOffset;
    console.log("scroll: ", position);

    setScrollPosition(position);
    setMiddleLine(position + middle);
  };

  // 절대 좌표 구하기
  // let boxPosition = $(".movingBox").offset().top;
  // console.log("boxPosition", boxPosition);

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  React.useEffect(() => {
    if (fixedBoxRef.current) {
      setRefState(fixedBoxRef);
    }
    // if (textBoxRef.current) {
    //   setTextRefState(textBoxRef);
    // }
    if (lastTextRef.current) {
      setLastTextRefState(lastTextRef);
    }
    if (boxRef.current) {
      setBoxRefState(boxRef);
    }

    if (textBoxRef.current) {
      setTextBoxState(textBoxRef);
      setTextRefState(textBoxRef);
    }
    if (digitalRef.current) {
      setDigitalState(digitalRef);
    }
    if (transformationRef.current) {
      setTransformationState(transformationRef);
    }
    if (movingBoxRef.current) {
      setMovingBox(movingBoxRef);
    }
  }, [fixedBoxRef]);

  const digitalTop = digitalState && digitalState.current.offsetTop;
  const digitalHeight = digitalState && digitalState.current.offsetHeight;

  const transformationBottom =
    transformationState &&
    transformationState.current.offsetTop +
      transformationState.current.offsetHeight;
  const transformationHeight =
    transformationState && transformationState.current.offsetHeight;
  const digitalTransformationHeight =
    boxRefState && boxRefState.current.offsetHeight;
  const textBoxHeight = textBoxState && textBoxState.current.offsetHeight;

  const movingAreaSize = textBoxHeight + digitalTransformationHeight;
  const movingBoxTop = movingBoxRef.current && movingBoxRef.current.offsetTop;
  const [movingArea, setMovingArea] = React.useState(null);
  React.useEffect(() => {
    setMovingArea(movingAreaSize);
  }, [textBoxState]);
  console.log("디지털 크기", digitalHeight);
  console.log("텍스트박스 크기", textBoxHeight);
  console.log("무빙 에리아 크기", movingAreaSize);
  console.log("무빙 탑", movingBoxTop);
  console.log("컨테이너 탑", containerTop);

  const atTop = scrollPosition + middle <= containerTop;
  const atBottom =
    scrollPosition + middle >= containerBottom - transformationHeight;
  console.log("엣 탑", atTop);
  console.log("엣 바텀", atBottom);
  const fixingPoint =
    middleLine >= containerTop &&
    middleLine <= containerBottom - transformationHeight;

  console.log(middle, middle);
  return (
    <React.Fragment>
      {/* 빈공간 */}
      {/* 스티키 헤더 */}
      <BasicAppBar />
      <EmptySpace height={matches ? "242px" : "83px"} />
      <div
        style={
          matches
            ? {
                ...styles.firstImageBox.lgtheme,
                lineHeight: "0",
                height: "1000px",
              }
            : { ...styles.firstImageBox.smtheme, lineHeight: "0" }
        }
      >
        <img
          style={
            matches ? styles.firstImage.lgtheme : styles.firstImage.smtheme
          }
          src={main1}
          alt=""
        ></img>
      </div>
      <div className={"startMoving"}>
        {/* 여기 시작 */}
        <EmptySpace height={matches ? "293px" : "45px"} />
        <Container
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
          }}
        >
          <Box
            sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
          >
            <Typography variant="h1mainfirst"> {t(`사람과 기계를`)}</Typography>

            <Typography variant="h1mainfirst">
              {t(`최고로 편리하게 연결하는`)}
            </Typography>

            <Typography variant="h1mainfirst">
              {t(`혁신적인 기술을 만들어갑니다.`)}
            </Typography>
          </Box>
          <EmptySpace height={matches ? "90px" : "47px"} />
          {matches ? (
            <Box
              sx={
                matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme
              }
            >
              <Typography variant="h6">
                {t(`하이딥의 터치 기술로 Digital Transformation이 시작됩니다.`)}
              </Typography>

              {lang === "ko" && (
                <Typography variant="h6">
                  {t(`Flexible OLED 디스플레이용 터치, 스타일러스 기술과 모든
            ${!matches ? "\n" : ""} 디스플레이에 사용 가능한 3D 터치, 그리고 그
            이상의 가능성.`)}
                </Typography>
              )}

              <Typography variant="h6">
                {t(
                  `지금 세상이 필요로 하는 유의미한 사용자 터치 경험을 제공합니다.`
                )}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={
                matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme
              }
            >
              <Typography variant="h6">
                {t(`main_mobile_1_하이딥의`)}
              </Typography>
            </Box>
          )}
        </Container>
        <EmptySpace height={matches ? "207px" : "115px"} />
        <Container
          sx={
            matches
              ? {
                  margin: "0",
                  padding: "0",
                  display: "block",
                  // backgroundColor: "purple",
                  position: "relative",
                }
              : {
                  margin: "0",
                  padding: "0",
                  display: "block",

                  // display: "inline",
                  // display: "inline-block",

                  // lineHeight: "0.96rem",
                  // scrollBehavior: "smooth",

                  // letterSpacing: "-0.0533rem",
                  position: "relative",
                }
          }
          ref={fixedBoxRef}
        >
          <Box
            // sx={{ paddingTop: matches ? "300px" : "0px", scrollingSpeed: "700" }}
            sx={{
              // backgroundColor: "silver",
              height: `${movingAreaSize}px`,
              display: "flex",
              justifyContent: "left",
              alignItems: "flex-end",
            }}
            ref={movingBoxRef}
            className="movingBox"
          >
            {
              <Box
                className="smooth"
                ref={boxRef}
                sx={
                  matches
                    ? {
                        zIndex: 99,
                        width: "100%",
                        overflow: "hidden",
                        position: fixingPoint ? "fixed" : "absolute",
                        top: fixingPoint
                          ? `${middle}px`
                          : atTop
                          ? "0%"
                          : atBottom
                          ? `calc(100% - ${transformationHeight}px)`
                          : "0px",
                        marginLeft: "3vw",
                      }
                    : {
                        zIndex: 99,
                        width: "100%",
                        overflow: "hidden",
                        position: fixingPoint ? "fixed" : "absolute",
                        top: fixingPoint
                          ? `${middle}px`
                          : atTop
                          ? "0%"
                          : atBottom
                          ? `calc(100% - ${transformationHeight}px)`
                          : "0px",
                        paddingLeft: "15px",
                      }
                }
              >
                {/* need to work */}
                <Typography
                  sx={
                    matches
                      ? {
                          // backgroundColor: "red",
                          // lineHeight: "0.73",
                          // fontSize: "200px",
                          color: "#000",
                          letterSpacing: "-1px",
                          fontWeight: "600",
                          // lineHeight: "230px",
                          // marginBottom: "20px",
                          lineHeight: "1",
                          fontSize: "11.5vw",
                        }
                      : {
                          color: "#000",
                          letterSpacing: "-1px",
                          fontWeight: "600",
                          // lineHeight: "230px",
                          // marginBottom: "20px",
                          lineHeight: "1.125",
                          width: "80%",
                          fontSize: "11.5vw",
                          marginRight: "0px",
                        }
                  }
                  ref={digitalRef}
                >
                  Digital
                </Typography>

                <Typography
                  sx={
                    matches
                      ? {
                          lineHeight: "1",
                          // backgroundColor: "yellow",
                          // fontSize: "200px",
                          color: "#000",
                          letterSpacing: "-1px",
                          fontWeight: "600",
                          // marginTop: "20px",
                          // lineHeight: "230px",
                          lineHeight: "1",
                          fontSize: "11.5vw",
                        }
                      : {
                          color: "#000",
                          letterSpacing: "-1px",
                          fontWeight: "600",
                          // lineHeight: "230px",
                          // marginBottom: "20px",
                          lineHeight: "1.125",
                          width: "87%",
                          marginRight: "0px",

                          fontSize: "11.5vw",
                        }
                  }
                  ref={transformationRef}
                >
                  Transformation
                </Typography>
              </Box>
            }
            {/* TextBox */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
                marginRight: "3vw",
              }}
            >
              <Box
                sx={{
                  textAlign: "left",
                  // backgroundColor: "green",
                }}
                ref={textBoxRef}
              >
                <FixedText>AI embedded touch engine</FixedText>
                <FixedText>All about User Experience</FixedText>
                <FixedText>Easier to use and More useful</FixedText>
                <FixedText>A fiercely independent approach</FixedText>
                <FixedText>incisive inquiry</FixedText>
                <FixedText>New potential and ideas</FixedText>
                <FixedText>Innovative and progressive thinkers</FixedText>
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
      {/* 여기 끝 */}
      <EmptySpace height={matches ? "400px" : "115px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography variant="h1moreLine">
            {t(`혁신은 생각만으로 완성할 수 없습니다.`)}
          </Typography>

          <Typography variant="h1moreLine">
            {" "}
            {t(`수많은 실패는 물론이고,`)}
          </Typography>

          <Typography variant="h1moreLine">
            {t(`과감한 행동과 새로운 관점이 필요합니다.`)}
          </Typography>

          <EmptySpace height={matches ? "58px" : "28px"}></EmptySpace>

          <Typography variant="h1moreLine">
            {t(`하이딥은 언제나 새로운 방법에 도전하기 위해`)}
          </Typography>

          <Typography variant="h1moreLine">
            {t(`'왜?' 와 '어떻게?'에 집중합니다.`)}
          </Typography>
        </Box>
      </Container>
      <EmptySpace height={matches ? "284px" : "115px"} />
      <Box
        sx={matches ? styles.ceoImageBox.lgtheme : styles.ceoImageBox.smtheme}
      >
        <img
          style={matches ? styles.ceoImage.lgtheme : styles.ceoImage.smtheme}
          id="img_w20230105b1eca6a32854b"
          src={main2}
          // class=" org_image"
          alt=""
        ></img>
        {matches && (
          <Box
            sx={{
              position: "absolute",
              top: "444px",
              color: "white",
              right: "0vw",
              width: "43%",
              marginLeft: "20%",
              overflow: "hidden",
            }}
          >
            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`우리는 세상에 없는 기술에 도전하여 그 기술이 사람들에게`)}
            </Typography>
            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`더 나은 삶을 사는 데 도움이 되기를 바랍니다.`)}
            </Typography>

            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`우리는 사람들이 불가능하다고 여기는 것들에 대해`)}
            </Typography>
            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`전례가 없는 생각과 도전을 통하여 새로운 방법을 찾아냅니다.`)}
            </Typography>
            <EmptySpace height={"25.59px"} />
            <Typography
              sx={{
                ...styles.ceoQuote.lgtheme,
                whiteSpace: "pre-line",
                lineHeight: lang === "en" ? 1.5 : 1.1,
              }}
            >
              {t(
                `우리는 그 과정을 통해 더 많은 것을 배우고, 더 많은 것을 보게 되고`
              )}
            </Typography>

            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`더욱더 성장하며 더 나은 삶을 살아갑니다.`)}
            </Typography>
            <Typography
              sx={{
                ...styles.ceoQuote.lgtheme,
                lineHeight: lang === "en" ? 1.5 : 1.1,
                whiteSpace: "pre-line",
              }}
            >
              {t(`그것이 하이딥이 존재하는 이유입니다.`)}
            </Typography>

            <EmptySpace height={"25.59px"} />
            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`"사람은 자신이 아는 만큼 볼 수 있다."`)}
            </Typography>
            <EmptySpace height={"25.59px"} />
            <EmptySpace height={"25.59px"} />

            <Typography sx={styles.ceoQuote.lgtheme}>
              {t(`CEO / 고범규`)}
            </Typography>
          </Box>
        )}
      </Box>
      {!matches && (
        <Container>
          <EmptySpace height={matches ? "0px" : "30.0px"} />

          <Box>
            <Typography variant="h6">
              {t(`우리는 세상에 없는 기술에 도전하여 그 기술이 사람들에게`)}
            </Typography>
            <Typography variant="h6" sx={{ color: "#1a1e24" }}>
              {t(`더 나은 삶을 사는 데 도움이 되기를 바랍니다.`)}
            </Typography>

            <Typography variant="h6" sx={{ color: "#1a1e24" }}>
              {t(`전례가 없는 생각과 도전을 통하여 새로운 방법을 찾아냅니다.`)}
            </Typography>
            <Typography variant="h6" sx={{ color: "#1a1e24" }}>
              {t(`우리는 세상에 없는 기술에 도전하여 그 기술이 사람들에게`)}
            </Typography>

            <EmptySpace height={matches ? "0px" : "24.0px"} />
            <Typography variant="h6" sx={{ color: "#1a1e24" }}>
              {t(
                `우리는 그 과정을 통해 더 많은 것을 배우고, 더 많은 것을 보게 되고`
              )}
            </Typography>
            <Typography variant="h6" sx={{ color: "#1a1e24" }}>
              {t(`더욱더 성장하며 더 나은 삶을 살아갑니다.`)}
            </Typography>
            <Typography variant="h6" sx={{ color: "#1a1e24" }}>
              {t(`그것이 하이딥이 존재하는 이유입니다.`)}
            </Typography>
            <EmptySpace height={matches ? "0px" : "24.0px"} />

            <Typography
              variant="h6"
              sx={{
                color: "#1a1e24",
                fontWeight: "700",
                lineHeight: !matches && "1.5",
              }}
            >
              {t(`"사람은 자신이 아는 만큼 볼 수 있다."`)}
            </Typography>

            <Typography variant="h6">{t(`CEO / 고범규`)}</Typography>
          </Box>
        </Container>
      )}
      <EmptySpace height={matches ? "300px" : "115px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography variant="h1">{t(`화면 확대에 꼭`)}</Typography>
          <Typography variant="h1">{t(`두 손가락이 필요할까?`)}</Typography>
          <EmptySpace height={matches ? "50px" : "25px"} />

          <Typography variant="h6">
            {t(`모두들 또다른 제스처의 스마트폰 터치를 고민할 때,`)}
          </Typography>
          <Typography variant="h6">
            {t(`우리는 근본적인 질문을 던졌습니다.`)}
          </Typography>
        </Box>

        <EmptySpace height={matches ? "50px" : "40px"} />
        <Box>
          <img
            style={{ maxWidth: "100%" }}
            id="img_w20230105b1eca6a32854b"
            src={main3}
            // class=" org_image"
            alt=""
          ></img>{" "}
        </Box>
      </Container>
      <EmptySpace height={matches ? "50px" : "30px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography variant="h1mainOneHand">{t(`한 손으로`)}</Typography>

          <Typography variant="h1mainOneHand">
            {t(`하나의 손가락만을 이용하여`)}
          </Typography>

          <Typography variant="h1mainOneHand">
            {t(`한 번만 터치해서`)}
          </Typography>

          <Typography variant="h1mainOneHand">
            {t(`스마트폰을 사용할 수 있다면?`)}
          </Typography>
        </Box>
      </Container>
      <EmptySpace height={matches ? "50px" : "25px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography variant="h6">
            {t(`지금까지의 터치 - 손가락을 이용한 모든 제스처 기술은 모두`)}
          </Typography>
          <Typography variant="h6">
            {t(`X좌표와 Y 좌표를 기반으로 한 2차원의 기술이었습니다.`)}
          </Typography>
          {lang === "ko" && (
            <Typography variant="h6">
              {t(`우리는 새로운 Z 좌표를 추가하여 한 단계 진보된
            ${!matches ? "\n" : ""}입체 터치 기술의 가능성을 생각했습니다.`)}
            </Typography>
          )}
          <EmptySpace height={matches ? "0px" : "20px"}></EmptySpace>
        </Box>
      </Container>
      <EmptySpace height={matches ? "105px" : "0px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: "0 5px",
        }}
      >
        <Box
          sx={{
            margin: "30px 5vw",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            padding: !matches && "0 15px",
          }}
        >
          <Box>
            <Typography>
              <AnimatedNumbers
                animateToNumber={1}
                fontStyle={
                  matches ? styles.firstOne.lgtheme : styles.firstOne.smtheme
                }
                locale="en-US"
                configs={[
                  { mass: 1, tension: 220, friction: 100 },
                  { mass: 1, tension: 180, friction: 130 },
                  { mass: 1, tension: 280, friction: 90 },
                  { mass: 1, tension: 180, friction: 135 },
                  { mass: 1, tension: 260, friction: 100 },
                  { mass: 1, tension: 210, friction: 11 },
                ]}
              ></AnimatedNumbers>
            </Typography>
            <Typography
              variant="h3"
              sx={matches ? styles.oneDes.lgtheme : styles.oneDes.smtheme}
            >
              One Hand
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography>
              <AnimatedNumbers
                animateToNumber={1}
                fontStyle={matches ? styles.one.lgtheme : styles.one.smtheme}
                locale="en-US"
                configs={[
                  { mass: 1, tension: 220, friction: 100 },
                  { mass: 1, tension: 180, friction: 130 },
                  { mass: 1, tension: 280, friction: 90 },
                  { mass: 1, tension: 180, friction: 135 },
                  { mass: 1, tension: 260, friction: 100 },
                  { mass: 1, tension: 210, friction: 11 },
                ]}
              ></AnimatedNumbers>
            </Typography>
            <Typography
              variant="h3"
              sx={matches ? styles.oneDes.lgtheme : styles.oneDes.smtheme}
            >
              One Finger
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography>
              <AnimatedNumbers
                animateToNumber={1}
                locale="en-US"
                fontStyle={matches ? styles.one.lgtheme : styles.one.smtheme}
                configs={[
                  { mass: 1, tension: 220, friction: 100 },
                  { mass: 1, tension: 180, friction: 130 },
                  { mass: 1, tension: 280, friction: 90 },
                  { mass: 1, tension: 180, friction: 135 },
                  { mass: 1, tension: 260, friction: 100 },
                  { mass: 1, tension: 210, friction: 11 },
                ]}
              ></AnimatedNumbers>
            </Typography>
            <Typography
              variant="h3"
              sx={matches ? styles.oneDes.lgtheme : styles.oneDes.smtheme}
            >
              One Touch
            </Typography>
          </Box>
        </Box>
      </Container>
      <EmptySpace height={matches ? "120px" : "30px"} />
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography variant="h1moreLine">{t(`3D Touch`)}</Typography>

          <Typography variant="h1moreLine">
            {t(`보이지 않는 공간에서`)}
          </Typography>

          <Typography variant="h1moreLine">
            {t(`새로운 좌표를 찾아내다.`)}
          </Typography>
          <EmptySpace height={matches ? "45px" : "20px"} />
        </Box>
      </Container>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography variant="h6">
            {t(
              `수많은 방법으로 Z 축을 감지할 방법을 고민하고 시도하고 실패했지만`
            )}
          </Typography>
          <Typography variant="h6">
            {t(`결국 스마트폰 디스플레이 뒤의 미세한 공간을 발견하였고`)}
          </Typography>
          <Typography variant="h6">
            {t(`스마트폰 화면을 누르면, 커버 글라스로부터 디스플레이까지의`)}
          </Typography>
          <Typography variant="h6">
            {t(`전체 부품이 휠 수 있다는 것을 알아냈습니다.`)}
          </Typography>
          <EmptySpace height={matches ? "19px" : "0px"} />
          <EmptySpace height={matches ? "19px" : "17.39px"} />
          <Typography variant="h6">
            {t(`최초의 시도는 허름한 전도성 테이프로 만든 3D센서였습니다.`)}
          </Typography>
          {lang === "ko" && (
            <Typography variant="h6">
              {t(`정말 스마트폰 화면을 눌렀을 때 전기 신호를 감지할 수 있을지
            실험을 하였고 가능하다는 것을 확인하게 됩니다.`)}
            </Typography>
          )}

          <EmptySpace height={matches ? "19px" : "0px"} />
          <EmptySpace height={matches ? "19px" : "17.93px"} />
          {lang === "ko" && (
            <Typography variant="h6">
              이를 시작으로 우리는 3D Touch 기술을 {!matches ? "\n" : ""}
              스마트폰 모델들에 적용하여 양산에 성공하며
            </Typography>
          )}
          <Typography variant="h6">
            {t(
              `다양한 Single Touch UX / UI 를 독보적으로 제공하기 시작했습니다.`
            )}
          </Typography>
        </Box>
        <EmptySpace height={matches ? "110px" : "40px"}></EmptySpace>
        <Box>
          <img
            style={{ maxWidth: "100%" }}
            id="img_w20230105b1eca6a32854b"
            src={main4}
            // class=" org_image"
            alt=""
          ></img>{" "}
        </Box>
      </Container>
      <EmptySpace height={matches ? "300px" : "100px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography
            variant="h1"
            sx={
              matches ? styles.careerTitle.lgtheme : styles.careerTitle.smtheme
            }
          >
            Careers
          </Typography>
          <EmptySpace height={matches ? "50px" : "22px"} />

          {!matches && (
            <Typography
              variant="h6"
              sx={matches ? styles.newsDes.lgtheme : styles.newsDes.smtheme}
            >
              {t(
                `새로운 '왜?' 와 '어떻게?'를 찾아가는 사람들이\n하이딥의 진정한 가치입니다.`
              )}
            </Typography>
          )}
          {matches && (
            <Typography
              variant="h6"
              sx={matches ? styles.newsDes.lgtheme : styles.newsDes.smtheme}
            >
              {t(
                `새로운 '왜?' 와 '어떻게?'를 찾아가는 사람들이 하이딥의 진정한 가치입니다.`
              )}
            </Typography>
          )}
          <EmptySpace height={matches ? "15px" : "25px"} />

          <Link href="/careers" underline="none">
            <Typography
              variant="h6"
              sx={matches ? styles.moreLink.lgtheme : styles.moreLink.smtheme}
            >
              Learn more {"\u00a0"} ⟩
            </Typography>
          </Link>
          <EmptySpace height={matches ? "100px" : "43px"} />
        </Box>

        <Box>
          <img
            style={
              matches ? styles.newsImage.lgtheme : styles.newsImage.smtheme
            }
            id="img_w20230105b1eca6a32854b"
            src={main5}
            // class=" org_image"
            alt=""
          ></img>
        </Box>
      </Container>
      <EmptySpace height={matches ? "270px" : "90px"}></EmptySpace>
      <Container
        sx={{
          maxWidth: "1294px",
          padding: !matches && "0 0px",
        }}
      >
        <Box
          sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}
        >
          <Typography
            variant="h1"
            sx={
              matches ? styles.careerTitle.lgtheme : styles.careerTitle.smtheme
            }
          >
            Newsroom
          </Typography>
          <EmptySpace height={matches ? "50px" : "22px"} />
          {/* <Typography
            variant="h6"
            sx={matches ? styles.newsDes.lgtheme : styles.newsDes.smtheme}
          >
            {t(`미디어를 통해 조명된 HIDEEP의 최신 소식들을{!matches ? "\n" : ""}{" "}
            확인하실 수 있습니다.`)}
          </Typography> */}

          {matches && (
            <Typography
              variant="h6"
              sx={matches ? styles.newsDes.lgtheme : styles.newsDes.smtheme}
            >
              {t(
                `미디어를 통해 조명된 HIDEEP의 최신 소식들을 확인하실 수 있습니다.`
              )}
            </Typography>
          )}
          {!matches && (
            <Typography
              variant="h6"
              sx={matches ? styles.newsDes.lgtheme : styles.newsDes.smtheme}
            >
              {t(
                `미디어를 통해 조명된 HIDEEP의 최신 소식들을\n확인하실 수 있습니다.`
              )}
            </Typography>
          )}

          <EmptySpace height={matches ? "15px" : "25px"} />

          <Link href="/newsroom" underline="none">
            <Typography
              variant="h6"
              sx={matches ? styles.moreLink.lgtheme : styles.moreLink.smtheme}
            >
              Learn more {"\u00a0"}⟩
            </Typography>
          </Link>
          <EmptySpace height={matches ? "100px" : "50px"} />
        </Box>

        <Box sx={{}}>
          <Grid
            container
            spacing={matches ? 2 : 0}
            columnSpacing={matches ? 3 : 0}
          >
            {newsroom &&
              newsroom.map((v) => (
                <>
                  {" "}
                  <Grid item xs={12} md={4}>
                    <Link underline="none" button href={`/news/${v.id}?page=1`}>
                      <HoverScalingImg
                        aspectRatio={"1.896"}
                        img={v.data.files[0] ? v.data.files[0] : newsLoading}
                      ></HoverScalingImg>
                      <Box
                        sx={
                          matches
                            ? styles.paddingBox.lgtheme
                            : styles.paddingBox.smtheme
                        }
                      >
                        <EmptySpace height={matches ? "0px" : "10px"} />

                        <Typography
                          variant="h6"
                          sx={
                            matches
                              ? styles.newsTitle.lgtheme
                              : styles.newsTitle.smtheme
                          }
                        >
                          {v.data.title}
                        </Typography>

                        <Typography
                          variant="h6"
                          sx={
                            matches
                              ? styles.newsInfo.lgtheme
                              : styles.newsInfo.smtheme
                          }
                        >
                          {v.data.date}
                          {"\u00a0"}| {v.data.category}
                        </Typography>
                        <EmptySpace height={matches ? "0px" : "40px"} />
                      </Box>
                    </Link>
                  </Grid>
                </>
              ))}
          </Grid>
        </Box>
      </Container>
      {/* 완료 1 */}
      <EmptySpace height={matches ? "335px" : "70px"} />
      <Box sx={matches ? styles.lastBox.lgtheme : styles.lastBox.smtheme}>
        <EmptySpace height={matches ? "0px" : "5px"} />
        <Container
          sx={{
            padding: "0px",
            maxWidth: "1294px",
          }}
        >
          <Typography
            sx={matches ? styles.lastTitle.lgtheme : styles.lastTitle.smtheme}
          >
            {t(`Fly Higher, Think Deeper`)}
          </Typography>
          <EmptySpace height="26px" />

          <Typography
            sx={
              matches
                ? {
                    ...styles.lastBody.lgtheme,
                    fontSize: lang === "en" ? "55px" : "60px",
                  }
                : styles.lastBody.smtheme
            }
          >
            {t(`높은 곳에서, 넓게 보고`)}
          </Typography>
          {matches && <p></p>}
          <Typography
            sx={
              matches
                ? {
                    ...styles.lastBody.lgtheme,
                    fontSize: lang === "en" ? "55px" : "60px",
                  }
                : styles.lastBody.smtheme
            }
          >
            {t(`더 깊게 생각합니다`)}
          </Typography>
          <EmptySpace height="54px" />
          <Typography
            sx={matches ? styles.copyRight.lgtheme : styles.copyRight.smtheme}
          >
            ⓒ HIDEEP. All rights reserved.
          </Typography>
          <EmptySpace height={matches ? "100px" : "83px"} />

          <Box sx={{ position: "relative" }}>
            <select
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onChange={(e) => {
                setLang(e.target.value);
                localStorage.setItem("language", e.target.value);
              }}
              className="w150"
              value={
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "ko"
              }
              style={{
                borderRadius: "4%",
                borderColor: "white",
                border: "1px solid rgb(255, 255, 255, 0.25)",
                borderColor: "rgba(233, 232, 232, 0.25)",
                // padding: "8px 25px",
                width: "96px",
                height: "39px",
                color: hover ? "black" : "#ffffff",
                backgroundColor: hover ? "white" : "rgba(0, 0, 0, 0)",
                fontSize: "12px",
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                textIndent: matches ? "23px" : "20px",
                // textAlign: "center",
                "&input[type=submit]:hover": {
                  backgroundColor: "#fff",
                  color: "black",
                },

                "&:hover": {
                  backgroundColor: "#fff",
                  color: "black",

                  borderColor: "#fff",
                },
              }}
            >
              <option value="ko">한국어 </option>
              <option value="en">English</option>
            </select>
            <Typography
              sx={{
                position: "absolute",
                right: "13px",
                top: "7px",
                color: hover ? "black" : "#ffffff",
                fontSize: "12px",
              }}
            >
              ∨
            </Typography>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Main;

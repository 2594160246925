import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import hi from "asset/company/hi.jpeg";
import ceo from "asset/company/ceo.jpeg";
import challenge from "asset/company/challenge.png";
import chips from "asset/company/chips.jpeg";
import connect from "asset/company/connect.jpeg";
import investors from "asset/company/investors.jpeg";
import ipo1 from "asset/company/new1.jpeg";
import ipo2 from "asset/company/new2.jpeg";
import office from "asset/company/office.jpeg";
import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
// import { withScriptjs, withGoogleMap, Marker } from "react-google-maps";
// import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";
import { app, db } from "firebaseConfig";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";
import { UsePagination } from "component/components";
import usePagination from "@mui/material/usePagination";
import { compose, withProps } from "recompose";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {
  getStorage,
  ref,
  deleteObject,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import "./iamport.cm";
import queryString from "query-string";

import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import fileDownload from "js-file-download";

import { Viewer } from "@toast-ui/react-editor";
import "prismjs/themes/prism.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import Prism from "prismjs";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";

import AttachFileIcon from "@mui/icons-material/AttachFile";
function InvestorRelations(props) {
  let page = queryString.parseUrl(window.location.search).query?.page ?? "1";
  const { t } = useTranslation();
  let history = useHistory();

  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "ko"
  );

  React.useEffect(() => {
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const [companyPage, setCompanyPage] = React.useState(page);
  const companyChange = (e, number) => {
    setCompanyPage(number);
  };
  React.useEffect(() => {
    (async () => {
      console.log("바뀜", companyPage);
    })();
  }, [companyPage]);
  const itemsPerPage = 10;

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   if (fixedBoxRef.current) {
  //     setRefState(fixedBoxRef);
  //   }
  //   if (textBoxRef.current) {
  //     setTextRefState(textBoxRef);
  //   }
  //   if (lastTextRef.current) {
  //     setLastTextRefState(lastTextRef);
  //   }
  //   if (boxRef.current) {
  //     setBoxRefState(boxRef);
  //   }
  // }, [fixedBoxRef]);
  const List = styled("ul")({
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  });

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgWidthFitBox.lgtheme, height: height ?? "" }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <Container
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        {props.children}
      </Container>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box
        sx={
          matches ? styles.paddingBoxCompany.lgtheme : styles.paddingBox.smtheme
        }
      >
        {children}
      </Box>
    );
  }

  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          // console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }

  // const MyMapComponent = compose(
  //   withProps({
  //     googleMapURL:
  //       "https://maps.googleapis.com/maps/api/js?key=AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU&v=3.exp&libraries=geometry,drawing,places",
  //     loadingElement: <div style={{ height: `100%` }} />,
  //     containerElement: <div style={{ height: `1000px`, display: "none" }} />,
  //     mapElement: <div style={{ height: `100%` }} />,
  //   }),
  //   withScriptjs,
  //   withGoogleMap
  // )((props) => (
  //   <GoogleMap
  //     yesIWantToUseGoogleMapApiInternals
  //     defaultZoom={9}
  //     defaultCenter={{
  //       lat: 37.4000962,
  //       lng: 127.1123346,
  //     }}
  //   ></GoogleMap>
  // ));

  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.4000962, lng: 127.1123346 },
        // position: { lat: 37.400909, lng: 127.112158 },

        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
            language: lang,
            region: "US",
          }}
          defaultCenter={{
            lat: 37.4000962,
            lng: 127.1123346,
          }}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.4000962,
            lng: 127.1123346,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.4000962} lng={127.1123346}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }
  const [stock, setStock] = React.useState({
    output: [
      {
        stck_bsop_date: String(new Date()),
        stck_clpr: "0",
        acml_vol: "0",
        prdy_vrss: "0",
        prdy_vrss_sign: "3",
        prdy_ctrt: "0",
      },
    ],
  });
  const [company, setCompany] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        await init();
        setRefresh(false);
      }
    })();
  }, [refresh]);
  function isWithin10Minutes(timestamp) {
    console.log("stockData time", timestamp);

    const now = moment();
    console.log("now", now.toString());
    //지금으로 부터 10분
    const thirtyMinutesAgo = now.subtract(10, "minutes");

    timestamp = moment(timestamp);
    console.log("stockData time moment", timestamp);

    // timestamp가 현재 시간보다 과거인지 확인
    if (timestamp.isBefore(now)) {
      // timestamp가 30분 이전인지 확인
      console.log("체크중", timestamp.toString());
      console.log("체크중 10", thirtyMinutesAgo.toString());

      console.log(
        "주식 데이터의 시간이 현재로부터 10분 전인지",
        timestamp.isBefore(thirtyMinutesAgo)
      );

      return timestamp.isBefore(thirtyMinutesAgo);
    }

    return false;
  }

  async function init() {
    const car = await getFirebaseDoc("company");
    setCompany(car.posts);
    (async () => {
      try {
        let stock = null;

        const stockRef = collection(db, "stock");
        const q = query(stockRef, orderBy("createdAt", "desc"), limit(1));
        const querySnapshot = await getDocs(q);
        const stocks = querySnapshot.docs.map((v) => {
          console.log("문서", v.id);
          return { id: v.id, data: v.data() };
        });
        // const createdIn10Min = isWithin10Minutes(stocks[0].data.createdAt);
        // console.log("", stocks);
        // if (!createdIn10Min) {
        //   console.log("Data within 10min", createdIn10Min);

        //   console.log("헬로 30분", createdIn10Min);
        //   console.log("saved stock Data", stocks);
        setStock(stocks[0].data);

        //   return;
        // }
        // console.log("Data need to be replaced", createdIn10Min);
        const stockd = await axios.get(
          "https://hideep-express.vercel.app/stock"
        );
        console.log("daata", stockd.data);
        if (!stockd.data?.msg1) {
          console.log("no msg1");
          return;
        }
        setStock(stockd.data);
        console.log("save stock", stockd);
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        stockd.data.createdAt = String(now);
        console.log("saved", stockd.data.createdAt);
        const postId = createRefId();
        console.log("save new stock Data", stockd);
        const Ref = doc(db, "stock", postId);

        await setDoc(Ref, stockd.data, { merge: true });
      } catch (e) {
        console.log("헬로error", e);
      }
    })();
  }
  function shuffle(count) {
    //v1.0
    var chars = "acdefhiklmnoqrstuvwxyz0123456789".split("");
    var result = "";
    for (var i = 0; i < count; i++) {
      var x = Math.floor(Math.random() * chars.length);
      result += chars[x];
    }
    return result;
  }
  function createRefId() {
    return moment().format("YYYY-MM-DD HH:mm:ss").toString() + shuffle(5);
  }
  async function getFirebaseDoc(table) {
    try {
      const querySnapshot = await getDocs(collection(db, table));

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }

  async function download(path) {
    const storage = getStorage(app);
    try {
      console.log("시작");
      const httpsReference = ref(storage, path);
      console.log(ref);
      const metadata = await getMetadata(httpsReference);
      const fileName = metadata.name;
      console.log("ff", fileName);
      const url = await getDownloadURL(httpsReference);

      console.log(url);
      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      handleDownload(url, fileName);
      return;
    } catch (e) {
      alert(e.message);
      return;
    }
  }
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        fileDownload(res.data, filename);
      });
  };
  const { items } = usePagination({
    page: Number(companyPage),

    count: Math.ceil(company?.length / itemsPerPage),
    onChange: (e, page) => {
      setCompanyPage(page);
    },
  });
  function getFileName(path) {
    const storage = getStorage(app);
    const httpsReference = ref(storage, path);
    console.log(httpsReference);
    console.log("파일", httpsReference.name);
    return httpsReference.name;
    // getMetadata(httpsReference).then((meta) => meta.name);
  }

  function MainLikeImg({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }

  function MainLikeImg2({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
          backgroundColor: "#000",
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
            backgroundColor: "#000",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }

  function LgPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        <BasicAppBar />
        <EmptySpace height={matches ? "204px" : "83px"} />
        {/* <MainLikeImg height="1000px" img={hi} />
        <EmptySpace height={matches ? "160px" : "28px"} /> */}

        {/* img */}

        <CustomContainer>
          <PaddingBox>
            {/* img */}
            {/* <ImgWidthFit src={investors} /> */}
            <EmptySpace height={matches ? "40px" : "110px"} />
            <Typography variant="h1Alias">{t("Stock Price")}</Typography>
            <EmptySpace height={matches ? "60px" : "40px"} />

            {/* img */}
            {/* apple stock*/}

            <Box
              sx={
                matches
                  ? {
                      fontFamily:
                        "Noto Sans Korean, -apple-system, SFProDisplay, sans-serif",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }
                  : {}
              }
            >
              <Box
                sx={
                  matches
                    ? {
                        width: "50%",
                      }
                    : {}
                }
              >
                <Typography
                  variant="body3Alias"
                  sx={
                    matches
                      ? {
                          fontSize: "34px",
                          letterSpacing: "-0.011em",
                          fontWeight: "700",
                          color: "#000",
                          lineHeight: "1.2",

                          padding: "0",
                        }
                      : {}
                  }
                >
                  {/* {t("January 26, 2023 4:00 p.m. ET")} */}
                  {stock?.output?.[0] &&
                    moment(stock?.output[0]?.stck_bsop_date)
                      // .format("dddd, MMMM Do YYYY, h:mm:ss a")
                      .format("dddd, MMMM Do YYYY")
                      .toString()}
                </Typography>

                <EmptySpace height={matches ? "40px" : "40px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          fontSize: "16px",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1.55",
                          color: "#111111",
                          letterSpacing: "-1px",
                          display: "block",
                          whiteSpace: "pre-line",
                        }
                      : {}
                  }
                >
                  {t(`company_lg_본 주식정보`)}
                </Typography>
                <Typography
                  sx={
                    matches
                      ? {
                          fontSize: "13px",
                          marginTop: "30px",
                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1.55",
                          color: "#5a5a5a",
                          letterSpacing: "-1px",
                          display: "block",
                          whiteSpace: "pre-line",
                        }
                      : {}
                  }
                >
                  {t(`company_lg_현재주가는`)}
                </Typography>
              </Box>
              <Box
                sx={
                  matches
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }
                    : {}
                }
              >
                <Typography
                  sx={
                    matches
                      ? {
                          textAlign: "right",

                          fontSize: "16px",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                      : {}
                  }
                >
                  {t("현재주가")}
                </Typography>
                <Typography
                  sx={
                    matches
                      ? {
                          textAlign: "right",

                          fontSize: "44px",
                          letterSpacing: "-0.011em",

                          fontWeight: "700",
                          color: "#1a1e24",
                          lineHeight: "1.3",
                          color: "#111111",
                          letterSpacing: "-0.011em",
                        }
                      : {}
                  }
                >
                  {stock?.output?.[0] &&
                    numberWithCommas(stock.output[0].stck_clpr)}
                </Typography>
                <EmptySpace height={matches ? "15px" : "40px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          textAlign: "right",

                          fontSize: "16px",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                      : {}
                  }
                >
                  {t("전일대비")}
                </Typography>
                <EmptySpace height={matches ? "10px" : "40px"} />

                <div>
                  {stock?.output?.[0] && stock.output[0].prdy_vrss_sign < "3" && (
                    <Typography
                      sx={
                        matches
                          ? {
                              fontSize: "44px",
                              letterSpacing: "-0.011em",

                              fontWeight: "500",
                              color: "#1a1e24",
                              lineHeight: "1",
                              color: "#111111",
                              backgroundColor: "#d21010",
                              color: "#fff",
                              padding: "10px 16px",
                              borderRadius: "5px",
                              display: "inline-block",
                              fontSize: "44px",

                              letterSpacing: "-0.002em",
                            }
                          : {}
                      }
                    >
                      {stock?.output?.[0] && stock.output[0].prdy_ctrt + "%"}
                    </Typography>
                  )}
                  {stock?.output?.[0] &&
                    stock.output[0].prdy_vrss_sign === "3" && (
                      <Typography
                        sx={
                          matches
                            ? {
                                fontSize: "44px",
                                letterSpacing: "-0.011em",

                                fontWeight: "500",
                                color: "#1a1e24",
                                lineHeight: "1",
                                color: "#111111",
                                // backgroundColor: "#1d882e",
                                color: "#000",
                                padding: "10px 16px",
                                borderRadius: "5px",
                                display: "inline-block",
                                fontSize: "44px",

                                letterSpacing: "-0.002em",
                              }
                            : {}
                        }
                      >
                        {stock?.output?.[0] && stock.output[0].prdy_ctrt + "%"}
                      </Typography>
                    )}
                  {stock?.output?.[0] && stock.output[0].prdy_vrss_sign > "3" && (
                    <Typography
                      sx={
                        matches
                          ? {
                              fontSize: "44px",
                              letterSpacing: "-0.011em",

                              fontWeight: "500",
                              color: "#1a1e24",
                              lineHeight: "1",
                              backgroundColor: "#1a82f9",
                              color: "#fff",
                              padding: "10px 16px",
                              borderRadius: "5px",
                              display: "inline-block",
                              fontSize: "44px",

                              letterSpacing: "-0.002em",
                            }
                          : {}
                      }
                    >
                      {stock?.output?.[0] && stock.output[0].prdy_ctrt + "%"}
                    </Typography>
                  )}
                </div>
                <EmptySpace height={matches ? "30px" : "40px"} />

                <Typography
                  sx={{
                    textAlign: "right",

                    fontSize: "16px",

                    fontWeight: "400",
                    color: "#1a1e24",
                    lineHeight: "1",
                    color: "#111111",
                  }}
                >
                  {t("거래량")}
                </Typography>
                <EmptySpace height={matches ? "8px" : "40px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          fontSize: "44px",
                          letterSpacing: "-0.011em",

                          fontWeight: "500",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                      : {}
                  }
                >
                  {/* {t("98.9")} */}
                  {stock?.output?.[0] &&
                    numberWithCommas(stock.output[0].acml_vol)}
                </Typography>
              </Box>
            </Box>
            <EmptySpace height={matches ? "100px" : "40px"} />
            {/* img before 20240708 */}

            <CustomContainer>
              <PaddingBox>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection="row"
                  width={"100%"}
                  alignItems={"flex-end"}
                >
                  {" "}
                  <Typography variant="h1Alias">
                    {t("Financial Data")}
                  </Typography>
                  <Box
                    display={"flex"}
                    justifyContent={""}
                    flexDirection="row"
                    alignItems={"flex-end"}
                  >
                    <Typography
                      variant={"body4"}
                      paragraph={true}
                      sx={matches ? {} : { fontSize: "12px" }}
                    >
                      {t(`상세 재무자료는 `)}

                      <Link
                        sx={{
                          cursor: "pointer",
                          textDecorationColor: "black",
                        }}
                        onClick={() => {
                          window.open(
                            "https://dart.fss.or.kr",
                            "_blank",
                            "noopener, noreferrer"
                          );
                        }}
                      >
                        {t("금감원(DART)")}
                      </Link>

                      {t("공시자료를 참고해 주세요.")}
                    </Typography>
                  </Box>
                </Box>
                <EmptySpace height={matches ? "90px" : "40px"} />

                {/* img */}
              </PaddingBox>
              {/* was 200px */}
              <EmptySpace height={matches ? "110px" : "40px"} />

              <Box sx={{}}>
                <Typography variant="h1Alias">{t("Public Notice")}</Typography>
                <EmptySpace height={matches ? "60px" : "0px"} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={3}>
                    <FadeInSection>
                      <EmptySpace height={matches ? "0px" : "60px"} />

                      <Typography variant="h1Alias">{t("")}</Typography>
                    </FadeInSection>
                    <EmptySpace height={matches ? "0px" : "24px"} />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Box>
                      {company &&
                        company
                          .slice(0)
                          .reverse()
                          .map((v, i) => (
                            <React.Fragment key={i}>
                              {itemsPerPage * companyPage >= i + 1 &&
                                itemsPerPage * companyPage - itemsPerPage <
                                  i + 1 && (
                                  <Accordion
                                    key={i}
                                    disableGutters={true}
                                    elevation={0}
                                    square={true}
                                    sx={{
                                      "&.MuiAccordion-root": {
                                        borderTop: "1px solid black",
                                        borderBottom:
                                          i === company.length - 1 ||
                                          (i + 1) % 10 === 0
                                            ? "1px solid #000"
                                            : "0px solid #000",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      disableGutters={true}
                                      elevation={0}
                                      square={true}
                                      onClick={() => {
                                        console.log(v);
                                        history.push(
                                          `InvestorRelations/${v.id}?page=${companyPage}`
                                        );
                                      }}
                                      sx={
                                        matches
                                          ? {
                                              padding: "30px 0px ",
                                              margin: "0",
                                              "&.MuiAccordion-root": {
                                                borderTop: "1px solid black",
                                              },
                                              "& .MuiAccordionSummary-content":
                                                {
                                                  margin: "0",
                                                },
                                            }
                                          : {}
                                      }
                                      expandIcon={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontWeight: "bold",
                                            margin: "0",
                                            color: "black",
                                          }}
                                        ></Typography>
                                      }
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <Box
                                        sx={{
                                          width: "97%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={
                                            matches
                                              ? {
                                                  fontWeight: "bold",
                                                  margin: "0",
                                                  color: "black",
                                                  fontSize: "18px",
                                                  lineHeight: "1",
                                                }
                                              : {}
                                          }
                                        >
                                          {v.data.title}
                                        </Typography>
                                        <Typography
                                          sx={
                                            matches
                                              ? {
                                                  fontWeight: "bold",
                                                  margin: "0",
                                                  color: "#979797",
                                                  fontSize: "14px",
                                                  lineHeight: "1",
                                                }
                                              : {}
                                          }
                                        >
                                          {v.data.published}
                                        </Typography>
                                      </Box>
                                    </AccordionSummary>
                                  </Accordion>
                                )}
                            </React.Fragment>
                          ))}
                    </Box>
                    <Box>
                      <EmptySpace height={"30px"} />
                      {company?.length > 10 && (
                        <nav style={{ marginLeft: "-6px" }}>
                          <List>
                            {items.map(
                              (
                                { page, type, selected, disabled, ...item },
                                index
                              ) => {
                                let children = null;

                                if (
                                  type === "start-ellipsis" ||
                                  type === "end-ellipsis"
                                ) {
                                  children = "…";
                                } else if (type === "page") {
                                  children = (
                                    <div
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          border: "0px solid white",
                                          background: "none",
                                          fontWeight: selected
                                            ? "bold"
                                            : undefined,
                                          textDecoration: selected
                                            ? "underline"
                                            : undefined,
                                          textDecorationThickness: "1px",
                                          textUnderlinePosition: "under",
                                          fontSize: "15px",
                                        }}
                                        {...item}
                                      >
                                        {page}
                                      </button>
                                    </div>
                                  );
                                } else {
                                  if (type === "previous") {
                                    children = (
                                      <button
                                        type="button"
                                        style={{
                                          display: disabled
                                            ? "none"
                                            : undefined,
                                          border: "0px solid white",
                                          background: "none",
                                          fontWeight: selected
                                            ? "bold"
                                            : undefined,
                                          textDecoration: selected
                                            ? "underline"
                                            : undefined,
                                          textDecorationThickness: "1px",
                                          textUnderlinePosition: "under",
                                          fontWeight: "bold",
                                          fontSize: "15px",
                                        }}
                                        {...item}
                                      >
                                        {"<"}
                                      </button>
                                    );
                                  } else if (type === "next") {
                                    children = (
                                      <button
                                        type="button"
                                        style={{
                                          display: disabled
                                            ? "none"
                                            : undefined,
                                          border: "0px solid white",
                                          background: "none",
                                          fontWeight: selected
                                            ? "bold"
                                            : undefined,
                                          textDecoration: selected
                                            ? "underline"
                                            : undefined,
                                          textDecorationThickness: "1px",
                                          textUnderlinePosition: "under",
                                          fontWeight: "bold",
                                          fontSize: "15px",
                                        }}
                                        {...item}
                                      >
                                        {">"}
                                      </button>
                                    );
                                  } else {
                                    children = (
                                      <button type="button" {...item}>
                                        {type}
                                      </button>
                                    );
                                  }
                                }

                                return <li key={index}>{children}</li>;
                              }
                            )}
                          </List>
                        </nav>
                      )}
                    </Box>
                  </Grid>
                </Grid>

                {/* 라인 */}
                {/* 아코디언 */}
              </Box>
            </CustomContainer>
            {/* <EmptySpace height={matches ? "125px" : "40px"} /> */}
            {/* img */}
          </PaddingBox>
        </CustomContainer>
        <EmptySpace height={matches ? "200px" : "40px"} />

        {onlyLg(
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100vw",
            }}
          >
            <Box
              style={{
                overflow: "hidden",
                width: "100%",
                position: "relative",
                aspectRatio: "0.85 / 1",
                height: "600px",
                backgroundImage: `url(${ipo1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            ></Box>
            <Box
              style={{
                overflow: "hidden",
                width: "100%",
                height: "600px",

                position: "relative",
                aspectRatio: "0.85 / 1",
                backgroundImage: `url(${ipo2})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            ></Box>
          </Box>
        )}
        <EmptySpace height={matches ? "210px" : "40px"} />
        {/* 공고 */}

        {/* <MainLikeImg height="572px" img={challenge} />
        <EmptySpace height={matches ? "200px" : "40px"} /> */}

        {/* <EmptySpace height={matches ? "300px" : "100px"} /> */}
      </React.Fragment>
    );
  }
  function SmPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        <BasicAppBar />
        <EmptySpace height={matches ? "300px" : "90px"} />
        {/* <ImgWidthFit src={hi} /> */}
        {/* <ImgScaleFit src={connect} /> */}

        <EmptySpace height={matches ? "200px" : "40px"} />
        {/* 20240602 investors */}
        {/* <CustomContainer>
          <PaddingBox>
            <Typography variant="h1Alias">{t("Investor Relations")}</Typography>

            <EmptySpace height={matches ? "30px" : "40px"} />
          </PaddingBox>
        </CustomContainer> */}

        <CustomContainer>
          <PaddingBox>
            <Typography variant="h1Alias">{t("Stock Price")}</Typography>

            {/* img */}
            {/* apple stock*/}
            <EmptySpace height={matches ? "30px" : "40px"} />

            <Box>
              <Typography
                variant="body3Alias"
                sx={
                  matches
                    ? {
                        fontSize: "21px",
                        letterSpacing: "-0.011em",
                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.2",

                        padding: "0",
                      }
                    : {
                        fontSize: "22px",
                        letterSpacing: "-1px",
                        fontWeight: "700",
                        color: "#1a1e24",
                        lineHeight: "1",

                        padding: "0",
                      }
                }
              >
                {stock?.output?.[0] &&
                  moment(stock?.output[0]?.stck_bsop_date)
                    .format("dddd, MMMM Do YYYY")
                    .toString()}
              </Typography>
              <EmptySpace height={matches ? "30px" : "10px"} />

              <Typography
                sx={
                  matches
                    ? {
                        fontSize: "17px",

                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.5",
                        color: "#111111",

                        letterSpacing: "-0.011em",
                      }
                    : {
                        fontSize: "13px",

                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.5",
                        color: "#111111",
                        letterSpacing: "-1px",
                        whiteSpace: "pre-line",
                      }
                }
              >
                {t(
                  `본 주식 정보는 정보 제공의 목적으로만 \n제공되며 거래를 위한 목적이 아닙니다.\n본 주식 정보는 코스닥의 정보를 그대로 공유하며\n하이딥은 이 서비스를 제공하지 않습니다.`
                )}
              </Typography>
              <Typography
                sx={
                  matches
                    ? {
                        fontSize: "17px",

                        fontWeight: "400",
                        color: "#1a1e24",
                        lineHeight: "1.5",
                        color: "#111111",

                        letterSpacing: "-0.011em",
                      }
                    : {
                        fontSize: "10px",
                        marginTop: "20px",
                        fontWeight: "400",
                        color: "#5a5a5a",
                        lineHeight: "1.5",
                        color: "#111111",
                        letterSpacing: "-1px",
                        whiteSpace: "pre-line",
                      }
                }
              >
                {t(
                  `*현재주가는 자동으로 갱신되지 않으며 페이지를\n새로고침 하면 가장 최근 업데이트된 주가를 표시합니다.`
                )}
              </Typography>

              <EmptySpace height={matches ? "30px" : "40px"} />

              <Box
                sx={
                  matches
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                      }
                    : {}
                }
              >
                <Typography
                  sx={
                    matches
                      ? {
                          textAlign: "right",

                          fontSize: "21px",
                          letterSpacing: "-0.011em",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                      : {
                          fontSize: "13px",
                          letterSpacing: "-1px",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                  }
                >
                  {t("현재주가")}
                </Typography>
                <Typography
                  sx={
                    matches
                      ? {
                          textAlign: "right",

                          fontSize: "44px",
                          letterSpacing: "-0.011em",

                          fontWeight: "600",
                          color: "#1a1e24",
                          lineHeight: "1.3",
                          color: "#111111",
                          letterSpacing: "-0.011em",
                        }
                      : {
                          fontSize: "40px",
                          letterSpacing: "-1px",

                          fontWeight: "700",
                          color: "#1a1e24",
                          lineHeight: "1.3",
                          color: "#111111",
                        }
                  }
                >
                  {stock?.output?.[0] &&
                    numberWithCommas(stock.output[0].stck_clpr)}
                </Typography>
                <EmptySpace height={matches ? "8px" : "30px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          textAlign: "right",

                          fontSize: "17px",
                          letterSpacing: "-0.011em",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1.5",
                          color: "#111111",
                        }
                      : {
                          fontSize: "13px",
                          letterSpacing: "-1px",

                          fontWeight: "400",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                  }
                >
                  {t("전일대비")}
                </Typography>
                <EmptySpace height={matches ? "8px" : "16px"} />

                <div>
                  {stock?.output?.[0] &&
                    stock.output[0].prdy_vrss_sign === "3" && (
                      <Typography
                        sx={{
                          fontSize: "40px",
                          letterSpacing: "-0.011em",
                          fontWeight: "500",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                          color: "#000",
                          padding: "10px 0",
                          borderRadius: "5px",
                          display: "inline-block",
                          fontSize: "44px",
                          letterSpacing: "-0.002em",
                        }}

                        // (stock.output[0].prdy_vrss_sign < "3" && {
                        //   backgroundColor: "#1d882e",
                        //   color: "#111111",
                        //   padding: "10px 16px",
                        // },
                        // stock.output[0].prdy_vrss_sign > "3" && {
                        //   backgroundColor: "#D11111",
                        //   color: "#fff",
                        //   padding: "10px 16px",
                        // },
                        // stock.output[0].prdy_vrss_sign === "3" && {})
                      >
                        {stock?.output?.[0] && stock.output[0].prdy_ctrt + "%"}{" "}
                      </Typography>
                    )}
                  {stock?.output?.[0] && stock.output[0].prdy_vrss_sign < "3" && (
                    <Typography
                      sx={{
                        fontSize: "40px",
                        letterSpacing: "-0.011em",
                        fontWeight: "500",
                        color: "#1a1e24",
                        lineHeight: "1",
                        color: "#111111",
                        color: "#000",
                        padding: "10px 0",
                        borderRadius: "5px",
                        display: "inline-block",
                        fontSize: "44px",
                        letterSpacing: "-0.002em",
                        backgroundColor: "#d21010",
                        color: "#fff",
                        padding: "10px 16px",
                      }}
                    >
                      {stock?.output?.[0] && stock.output[0].prdy_ctrt + "%"}{" "}
                    </Typography>
                  )}
                  {stock?.output?.[0] && stock.output[0].prdy_vrss_sign > "3" && (
                    <Typography
                      sx={{
                        fontSize: "44px",
                        letterSpacing: "-0.011em",
                        fontWeight: "500",
                        color: "#1a1e24",
                        lineHeight: "1",
                        color: "#111111",
                        color: "#000",
                        padding: "10px 0",
                        borderRadius: "5px",
                        display: "inline-block",
                        fontSize: "44px",
                        letterSpacing: "-0.002em",
                        backgroundColor: "#1a82f9",
                        color: "#fff",
                        padding: "10px 16px",
                      }}
                    >
                      {stock?.output?.[0] && stock.output[0].prdy_ctrt + "%"}{" "}
                    </Typography>
                  )}
                </div>
                <EmptySpace height={matches ? "8px" : "30px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          fontSize: "17px",
                          letterSpacing: "-0.011em",

                          fontWeight: "400",
                          color: "#1a1e24",
                          color: "#111111",
                        }
                      : {
                          fontSize: "13px",
                          letterSpacing: "-1px",

                          fontWeight: "400",
                          color: "#1a1e24",
                          color: "#111111",
                          lineHeight: "1",
                        }
                  }
                >
                  {t("거래량")}
                </Typography>
                <EmptySpace height={matches ? "8px" : "9px"} />

                <Typography
                  sx={
                    matches
                      ? {
                          fontSize: "44px",
                          letterSpacing: "-0.011em",

                          fontWeight: "500",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                      : {
                          fontSize: "40px",
                          letterSpacing: "-0.011em",

                          fontWeight: "500",
                          color: "#1a1e24",
                          lineHeight: "1",
                          color: "#111111",
                        }
                  }
                >
                  {stock?.output?.[0] &&
                    numberWithCommas(stock?.output[0].acml_vol)}
                  {}
                </Typography>
              </Box>
            </Box>
            <EmptySpace height={matches ? "70px" : "110px"} />

            {/* img */}
          </PaddingBox>
        </CustomContainer>
        {/* img  moving 20240708*/}

        <CustomContainer>
          <PaddingBox>
            <Typography variant="h1Alias">{t("Financial Data")}</Typography>
            <EmptySpace height={matches ? "25px" : "40px"} />

            <Box
              display={"flex"}
              justifyContent={""}
              flexDirection="row"
              alignItems={"flex-end"}
            >
              <Typography
                variant={"body4"}
                paragraph={true}
                sx={matches ? {} : { fontSize: "12px" }}
              >
                {t(`상세 재무자료는 `)}

                <Link
                  sx={{
                    cursor: "pointer",
                    textDecorationColor: "black",
                  }}
                  onClick={() => {
                    window.open(
                      "https://dart.fss.or.kr",
                      "_blank",
                      "noopener, noreferrer"
                    );
                  }}
                >
                  {t("금감원(DART)")}
                </Link>

                {t("공시자료를 참고해 주세요.")}
              </Typography>
            </Box>

            {/* img */}
          </PaddingBox>
          <EmptySpace height={matches ? "200px" : "110px"} />

          <Box sx={{}}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography
                  sx={
                    matches
                      ? {
                          color: "rgb(0, 0, 0)",
                          fontWeight: "700",
                          fontSize: "48px",
                          lineHeight: "1.2",
                          letterSpacing: "0px",
                        }
                      : {
                          padding: "0 15px",
                        }
                  }
                  variant="h1Alias"
                >
                  {t("Public Notice")}
                </Typography>
                <EmptySpace height={matches ? "0px" : "24px"} />
              </Grid>
              <Grid item xs={12} md={9}>
                <Box sx={{ margin: "0 5px" }}>
                  {company &&
                    company
                      .slice(0)
                      .reverse()
                      .map((v, i) => (
                        <>
                          {itemsPerPage * companyPage >= i + 1 &&
                            itemsPerPage * companyPage - itemsPerPage <
                              i + 1 && (
                              <Accordion
                                disableGutters={true}
                                elevation={0}
                                square={true}
                                sx={{
                                  "&.MuiAccordion-root": {
                                    borderTop: "1px solid black",
                                    borderBottom:
                                      i === company.length - 1 ||
                                      (i + 1) % 10 === 0
                                        ? "1px solid #000"
                                        : "0px solid #000",
                                  },
                                }}
                              >
                                <AccordionSummary
                                  disableGutters={true}
                                  elevation={0}
                                  square={true}
                                  onClick={() => {
                                    console.log(v);
                                    history.push(
                                      `InvestorRelations/${v.id}?page=${companyPage}`
                                    );
                                  }}
                                  sx={
                                    matches
                                      ? {
                                          padding: "30px 0px ",
                                          margin: "0",
                                          "&.MuiAccordion-root": {
                                            borderTop: "1px solid black",
                                          },
                                          "& .MuiAccordionSummary-content": {
                                            margin: "0",
                                          },
                                        }
                                      : {}
                                  }
                                  expandIcon={
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "13px",
                                        margin: "0",
                                        marginRight: "-5px",
                                        color: "black",
                                      }}
                                    ></Typography>
                                  }
                                  aria-controls="panel2a-content"
                                  id="panel2a-header"
                                >
                                  <Box
                                    sx={{
                                      width: "97%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={
                                        matches
                                          ? {
                                              fontWeight: "bold",
                                              margin: "0",
                                              color: "black",
                                              fontSize: "18px",
                                            }
                                          : {
                                              fontWeight: "bold",
                                              margin: "5px 0",
                                              color: "black",
                                              fontSize: "13px",
                                            }
                                      }
                                    >
                                      {v.data.title}
                                    </Typography>
                                    <Typography
                                      sx={
                                        matches
                                          ? {
                                              fontWeight: "bold",
                                              margin: "0",
                                              color: "black",
                                              fontSize: "18px",
                                            }
                                          : {
                                              fontWeight: "bold",
                                              margin: "5px 0",
                                              color: "#979797",
                                              fontSize: "11px",
                                            }
                                      }
                                    >
                                      {v.data.published}
                                    </Typography>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                  sx={
                                    matches
                                      ? {
                                          padding: "30px 0px",
                                          fontWeight: "normal",
                                        }
                                      : { fontWeight: "normal" }
                                  }
                                >
                                  <Viewer
                                    initialValue={v.data.contents || ""}
                                    plugins={[
                                      [
                                        codeSyntaxHighlight,
                                        { highlighter: Prism },
                                      ],
                                    ]}
                                    customHTMLRenderer={{
                                      htmlBlock: {
                                        iframe(node) {
                                          return [
                                            {
                                              type: "openTag",
                                              tagName: "iframe",
                                              outerNewLine: true,
                                              attributes: node.attrs,
                                            },
                                            {
                                              type: "html",
                                              content: node.childrenHTML,
                                            },
                                            {
                                              type: "closeTag",
                                              tagName: "iframe",
                                              outerNewLine: false,
                                            },
                                          ];
                                        },
                                      },
                                    }}
                                  />
                                  <EmptySpace height={"20px"}> </EmptySpace>
                                  {v.data.files &&
                                    v.data.files.map((f) => (
                                      <>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Link
                                            color={"#fff"}
                                            underline="none"
                                            download
                                            component="button"
                                            onClick={() => {
                                              download(f);
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              <AttachFileIcon
                                                sx={{ mr: 3, color: "#000" }}
                                              />{" "}
                                              <Typography
                                                sx={{
                                                  fontWeight: "700",
                                                  fontSize: "10px",
                                                  marginRight: "10px",
                                                  color: "#000",
                                                  minWidth: "34px",
                                                }}
                                              >
                                                다운로드
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "10px",
                                                  marginRight: "10px",
                                                  color: "#000",
                                                }}
                                              >
                                                |{" "}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontSize: "11px",
                                                  color: "#000",
                                                  fontWeight: "400",
                                                }}
                                              >
                                                {" "}
                                                {getFileName(f)}
                                              </Typography>
                                            </Box>
                                          </Link>
                                        </Box>
                                      </>
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            )}
                        </>
                      ))}
                </Box>
                <Box>
                  <EmptySpace height={"20px"} />
                  {company?.length >= 10 && (
                    <nav style={{ marginLeft: "-1px" }}>
                      <List>
                        {items.map(
                          (
                            { page, type, selected, disabled, ...item },
                            index
                          ) => {
                            let children = null;

                            if (
                              type === "start-ellipsis" ||
                              type === "end-ellipsis"
                            ) {
                              children = "…";
                            } else if (type === "page") {
                              children = (
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <button
                                    type="button"
                                    style={{
                                      border: "0px solid white",
                                      background: "none",
                                      fontWeight: selected ? "bold" : undefined,
                                      textDecoration: selected
                                        ? "underline"
                                        : undefined,
                                      textDecorationThickness: "1px",
                                      textUnderlinePosition: "under",
                                      fontSize: "15px",
                                    }}
                                    {...item}
                                  >
                                    {page}
                                  </button>
                                </div>
                              );
                            } else {
                              if (type === "previous") {
                                children = (
                                  <button
                                    type="button"
                                    style={{
                                      display: disabled ? "none" : undefined,
                                      border: "0px solid white",
                                      background: "none",
                                      fontWeight: selected ? "bold" : undefined,
                                      textDecoration: selected
                                        ? "underline"
                                        : undefined,
                                      textDecorationThickness: "1px",
                                      textUnderlinePosition: "under",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                    {...item}
                                  >
                                    {"<"}
                                  </button>
                                );
                              } else if (type === "next") {
                                children = (
                                  <button
                                    type="button"
                                    style={{
                                      display: disabled ? "none" : undefined,
                                      border: "0px solid white",
                                      background: "none",
                                      fontWeight: selected ? "bold" : undefined,
                                      textDecoration: selected
                                        ? "underline"
                                        : undefined,
                                      textDecorationThickness: "1px",
                                      textUnderlinePosition: "under",
                                      fontWeight: "bold",
                                      fontSize: "15px",
                                    }}
                                    {...item}
                                  >
                                    {">"}
                                  </button>
                                );
                              } else {
                                children = (
                                  <button type="button" {...item}>
                                    {type}
                                  </button>
                                );
                              }
                            }

                            return <li key={index}>{children}</li>;
                          }
                        )}
                      </List>
                    </nav>
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* 라인 */}
            {/* 아코디언 */}
          </Box>
        </CustomContainer>
        <EmptySpace height={matches ? "100px" : "110px"} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <Box
            style={{
              overflow: "hidden",
              width: "100%",
              position: "relative",
              aspectRatio: "1.5 / 1",
              backgroundImage: `url(${ipo1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></Box>
          <Box
            style={{
              overflow: "hidden",
              width: "100%",
              position: "relative",
              aspectRatio: "1.5 / 1",
              backgroundImage: `url(${ipo2})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></Box>
        </Box>

        <EmptySpace height={matches ? "100px" : "100px"} />
        {/* 
        <Box
          style={{
            overflow: "hidden",
            width: "100%",
            position: "relative",
            aspectRatio: "2.786",
            backgroundImage: `url(${challenge})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        ></Box> */}

        {/* 공고 */}

        {/* {matches ? <ImgWidthFit src={challenge} /> : ""} */}
        {/* <EmptySpace height={matches ? "100px" : "115px"} /> */}

        {/* <EmptySpace height={matches ? "300px" : "100px"} /> */}
      </React.Fragment>
    );
  }
  return matches ? <LgPage /> : <SmPage />;
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default InvestorRelations;

import React from "react";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import { useAuth } from "authContext";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";

//careers

import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import { app, db } from "firebaseConfig";

import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

function Login(props) {
  const auth = useAuth();
  let history = useHistory();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setLoding] = React.useState(false);
  return (
    <>
      <Container>
        <Box sx={{ p: 2 }}>
          <Link href={"/login"} underline="none">
            <Typography>관리자 페이지</Typography>
          </Link>
        </Box>
        <EmptySpace height="200px" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Box>
              <Typography sx={{ fontSize: "16px" }}>ID</Typography>
              <TextField
                size="small"
                variant="outlined"
                value={email}
                type="email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#f1f1f1",
                    borderRadius: "0",
                    border: "1px",
                    minWidth: "300px",
                    borderColor: "#000",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0",

                    borderColor: "#000",
                  },
                }}
              />
            </Box>
            <Box>
              <Typography sx={{ fontSize: "16px" }}>PW</Typography>
              <TextField
                type="password"
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "#f1f1f1",
                    borderRadius: "0",
                    border: "0",
                    minWidth: "300px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "0",
                    borderColor: "#000",
                  },
                }}
                size="small"
                variant="outlined"
              />
            </Box>
            <EmptySpace height="20px" />
            <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
              <Button
                size="small"
                onClick={async () => {
                  setLoding(true);
                  try {
                    const result = await auth.singInWithEmail(email, password);
                    console.log("result", result);
                    if (result.code !== 200) {
                      setLoding(false);
                      auth.setIsLogin(true);
                      alert("다시 시도해주세요 " + result.msg);
                      return;
                    }

                    console.log("흠");
                    setLoding(false);
                    history.push("/adminmain");
                  } catch (error) {
                    console.log(error);
                    window.alert("Login: 다시시도해 주세요", error);
                    setLoding(false);
                    history.push("/login");

                    return;
                  }
                }}
                sx={{
                  backgroundColor: "#212121",
                  color: "#fff",
                  "&.MuiButtonBase-root": {
                    borderRadius: "0",
                  },
                  "&:hover": {
                    backgroundColor: "#212121",
                  },
                }}
                fullWidth={true}
              >
                Login
              </Button>
              <Button
                href="/reset"
                size="small"
                sx={{
                  height: "36px",
                  backgroundColor: "#e5e5e5",
                  color: "#000",
                  "&.MuiButtonBase-root": {
                    borderRadius: "0",
                  },
                  "&:hover": {
                    backgroundColor: "#e5e5e5",
                  },
                }}
                fullWidth={true}
              >
                비밀번호 변경
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Container>
    </>
  );
}

export default Login;

import "./App.css";
import React from "react";
import Portal from "@mui/base/Portal";
import "i18n";

import Routes from "Routes";
import { BrowserRouter as Router } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { green, purple } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import GlobalContext from "context/globalContext";
import { ScrollToTop } from "component/components";
import { BasicDrawer } from "component/components";
import acceptedlanguages from "acceptedlanguages";
import i18n from "i18n";

const lgtheme = createTheme({
  spacing: 5,
  components: {
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
        maxWidth: false,
      },
      styleOverrides: {
        maxWidthSm: {},
        maxWidthMd: {},
        maxWidthLg: {},
        maxWidthXl: {},
      },
    },

    overrides: {
      MuiButton: {
        // Name of the rule
        backgroundColor: "green",

        root: {
          "& :hover": {
            textDecoration: "none",
            backgroundColor: "#35C37D",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: "#35C37D",
            },
          },
        },
      },
    },
  },

  typography: {
    letterSpacing: "-1px",
    fontFamily: [
      "Inter",
      "Noto Sans Korean",
      "Apple SD Gothic Neo",
      "Malgun Gothic",
      "Nanum Gothic",
      "Noto Sans",
      "sans-serif",
    ].join(","),
    fontFamily: "Inter, Noto Sans Korean",
    h1: {
      fontSize: "60px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.49,
      display: "block",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h1mainfirst: {
      fontSize: "60px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.49,
      display: "block",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h1mainOneHand: {
      fontSize: "60px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.6,
      display: "block",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h1moreLine: {
      fontSize: "60px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.65,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      whiteSpace: "pre-line",
      display: "block",
    },

    h1Alias: {
      fontSize: "60px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.1,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      padding: "16px 0",
    },
    h2: {
      fontSize: "48px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      whiteSpace: "pre-line",
    },
    h2Alias: {
      fontSize: "48px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.18,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h3: {
      fontSize: "22px",
      fontWeight: "normal",
      letterSpacing: "-1px",

      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h4: {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
    },
    h4Alias: {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      whiteSpace: "pre-line",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#000",
    },
    h5: {
      fontSize: "18px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
    },
    h6: {
      fontSize: "22px",
      fontWeight: "400",
      letterSpacing: "-1px",
      color: "rgb(26, 30, 36)",
      lineHeight: 2.0,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
    },
    subtitle1: {},
    body1: {
      fontSize: "16px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: 2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "rgb(69, 69, 69)",
    },
    body3: {
      display: "block",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: "1.6",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "16px",
      color: "rgb(29, 29, 29)",
      wordBreak: "normal",
      wordWrap: "break-word",
      padding: "0px 0px",
      lineHeight: "2.03",
    },

    body3Alias: {
      display: "block",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: "1.6",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "16px",
      color: "rgb(29, 29, 29)",
      wordBreak: "normal",
      wordWrap: "break-word",
      padding: "0px 0px",
      lineHeight: "2.02",
      whiteSpace: "pre-line",
    },
    body4: { color: "#1d1d1d", display: "block" },
    careerh1: {
      fontSize: "48px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.47,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      whiteSpace: "pre-line",
      display: "block",
    },
    careerQuote: {
      display: "block",
      whiteSpace: "pre-line",
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "26px",
      lineHeight: "1.8",
    },
    careerWhiteName1: {
      color: "white",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "1.2",
      letterSpacing: "-1px",

      display: "block",
      whiteSpace: "pre-line",
    },
    carrerGray1: {
      display: "block",
      fontWeight: "700",

      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "16px",
      color: "#c6c6c6",
      wordBreak: "normal",
      wordWrap: "break-word",
      padding: "0px 0px",
      lineHeight: "1.8",
      letterSpacing: "-1px",

      whiteSpace: "pre-line",
    },
    careerWhiteTitle: {
      color: "#FBFBFB",
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "1.2",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    careerWhiteName: {
      color: "#f000",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "1.2",
      letterSpacing: "0px",
      display: "block",
      whiteSpace: "pre-line",
    },
    carrerGray: {
      display: "block",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: "1.6",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "15px",
      color: "#B0B1B3",
      wordBreak: "normal",
      wordWrap: "break-word",
      padding: "0px 0px",
      lineHeight: "1.8",
      whiteSpace: "pre-line",
    },
    careerCeo: {
      fontSize: "18px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      whiteSpace: "pre-line",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#000",
    },
    bodyGray: {
      display: "block",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: "1.6",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "16px",
      color: "rgb(151, 151, 151)",
      wordBreak: "normal",
      wordWrap: "break-word",
      padding: "0px 0px",
      lineHeight: "2.03",
    },

    // tech
    tech1: {
      color: "white",
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "1.46",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techh2: {
      color: "rgb(244, 244, 244)",
      color: "#fff",
      fontWeight: "700",
      fontSize: "60px",
      lineHeight: "1.40",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techBody1: {
      color: "#fff",
      fontWeight: "700",
      fontSize: "30px",
      lineHeight: "1.7",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },

    techBody2: {
      color: "white",
      fontWeight: "700",
      fontSize: "30px",
      lineHeight: "1.7",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techBody3: {
      color: "white",
      fontWeight: "normal",
      fontSize: "22px",
      lineHeight: "1.9",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techLink: {
      color: "#1560ff",
      fontWeight: "normal",
      fontSize: "22px",
      lineHeight: "1.7",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },

    componetsh1: {
      color: "white",
      fontWeight: "700",
      fontSize: "72px",
      lineHeight: "1.5",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsh2: {
      color: "white",
      fontWeight: "normal",
      fontSize: "36px",
      lineHeight: "1.5",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsh3: {
      color: "white",
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "1.5",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsh4: {
      color: "white",
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "1.45",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsh5: {
      color: "white",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "1",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsh6: {
      color: "white",
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: "1",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsbody1: {
      color: "white",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "1.65",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsbodyApplication: {
      color: "white",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "1.65",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    newsh1: {
      fontFamily: "Inter, Noto Sans Korean, sans-serif",

      fontSize: "72px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      whiteSpace: "pre-line",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "black",
      fontWeight: "700",
      fontSize: "72px",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    newsh2: {
      fontFamily: "Inter, Noto Sans Korean, sans-serif",

      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      whiteSpace: "pre-line",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1a1E24",
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },
    white: {
      main: "#fff",
    },
  },
});

const smtheme = createTheme({
  typography: {
    fontFamily: [
      "Inter",
      "Noto Sans Korean",
      "Apple SD Gothic Neo",
      "Malgun Gothic",
      "Nanum Gothic",
      "Noto Sans",
      "sans-serif",
    ].join(","),
    fontFamily: "Inter, Noto Sans Korean",
    fontFamily: "Inter, Noto Sans Korean",
    letterSpacing: "-1px",

    h1: {
      fontSize: "18px",
      // fontSize: "26px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.7,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      display: "block",
    },
    h1mainfirst: {
      fontSize: "18px",
      // fontSize: "26px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.67,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      display: "block",
    },
    h1mainOneHand: {
      fontSize: "18px",
      // fontSize: "26px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.67,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      display: "block",
    },
    h1moreLine: {
      fontSize: "18px",
      // fontSize: "26px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.7,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
      display: "block",
      color: "#000",
    },

    h1Alias: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "26px",
      lineHeight: "1.1",
    },
    h2: {
      fontSize: "48px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h2Alias: {
      fontSize: "18px",
      fontWeight: "700",
      letterSpacing: "-1px",
      color: "#1a1e24",
      lineHeight: "1",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
    },
    h3: {
      fontSize: "22px",
      fontWeight: "normal",
      letterSpacing: "-1px",

      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1A1E24",
    },
    h4: {
      fontSize: "20px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
    },
    h4Alias: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "13px",
      lineHeight: "1.0",
      whiteSpace: "pre-line",
    },
    careerCeo: {},
    h5: {
      fontSize: "18px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: 1.2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
    },
    h6: {
      fontSize: "16px",
      fontWeight: "normal",
      letterSpacing: "-1px",
      lineHeight: 2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      lineHeight: "1.71",
      color: "#1a1e24",
      fontSize: "13px",
      whiteSpace: "pre-line",
    },
    h6alias: {
      fontSize: "16px",
      fontWeight: "normal",
      letterSpacing: "-1px",
      lineHeight: 2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      lineHeight: "1.71",
      color: "#1a1e24",
      fontSize: "13px",
      whiteSpace: "pre-line",
    },
    subtitle1: {},
    body1: {
      fontSize: "16px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: 2,
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "rgb(69, 69, 69)",
    },
    body3: {
      fontSize: "99px",
      fontWeight: "700",
      letterSpacing: "-1px",

      lineHeight: "2",

      fontSize: "15px",
      lineHeight: "1.6",
      fontWeight: "normal",
      fontStyle: "normal",
      backgroundColor: "rgba(255,255,255,1)",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "16px",
      color: "rgb(29, 29, 29)",
    },
    body3Alias: {
      whiteSpace: "pre-line",
      fontWeight: "400",
      letterSpacing: "-1px",
      display: "block",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "13px",
      color: "#1a1e24",
      lineHeight: "1.735",
    },
    body4: {
      fontWeight: "700",
      letterSpacing: "-1px",
      display: "block",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "13px",
      color: "#1d1d1d",
      display: "block",

      lineHeight: "1.735",
      fontWeight: "700",
      whiteSpace: "pre-line",
    },
    bodyGray: {
      display: "block",
      letterSpacing: "-1px",

      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "13px",
      color: "#a5a5a5",
      wordBreak: "normal",
      wordWrap: "break-word",
      padding: "0px 0px",
      fontWeight: "700",

      lineHeight: "1.7",
    },

    body6: {
      whiteSpace: "pre-line",
      fontWeight: "700",
      letterSpacing: "-1px",
      display: "block",
      fontWeight: "normal",
      fontStyle: "normal",
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      fontSize: "12px",
      color: "#1a1e24",
      lineHeight: "1.75",
      fontWeight: "700",
    },
    careerQuote: {
      display: "block",
      whiteSpace: "pre-line",

      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "#e5e7eb",
      letterSpacing: "-1px",
      color: "#1a1e24",
      fontSize: "16px",
      lineHeight: "1.52",
    },
    careerWhiteTitle: {
      color: "white",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "1.2",
      letterSpacing: "-1px",
    },
    careerWhiteTitle2: {
      color: "white",
      fontWeight: "600",
      fontSize: "26px",
      lineHeight: "1.2",
      letterSpacing: "0px",
    },
    careerWhiteName: {
      color: "white",
      fontWeight: "700",
      fontSize: "13px",
      lineHeight: "1.4",
      letterSpacing: "-1px",
    },

    carrerGrayApply: {
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "1.69",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
      color: "#fff",
    },

    carrerGray: {
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "1.58",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
      color: "#fff",
    },

    techTitle: {
      color: "white",
      fontWeight: "600",
      fontSize: "26px",
      lineHeight: "1.2",
      letterSpacing: "0px",
      padding: "0 15px",
    },

    // tech
    tech1: {
      color: "white",
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "1.67",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techh2: {
      color: "#fff",
      fontWeight: "700",
      fontSize: "26px",
      lineHeight: "1.45",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techBody1: {
      color: "white",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "1.75",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techRef: {
      color: "#fff",
      fontWeight: "normal",
      fontSize: "13px",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
      lineHeight: "1.51",
      fontSize: "11px",
      color: "#a5a5a5",
    },
    techBody3: {
      color: "white",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "1.7",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    techLink: {
      color: "#1560ff",
      fontWeight: "700",
      fontSize: "14px",
      lineHeight: "1.6",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componetsh1: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      whiteSpace: "pre-line",
      fontSize: "26px",

      letterSpacing: "-1px",
      color: "#fff",
      borderColor: "#e5e7eb",
      margin: "0",
      padding: "0",
      whiteSpace: "pre-line",
      display: "block",
      lineHeight: 1,
    },
    componentsh2: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      whiteSpace: "pre-line",
      fontSize: "18px",

      letterSpacing: "-1px",
      color: "#fff",
      borderColor: "#e5e7eb",
      margin: "0",
      padding: "0",
      whiteSpace: "pre-line",
      display: "block",
      lineHeight: 1,
    },
    componentsh3: {},
    componentsh4: {},
    componentsh5: {
      color: "white",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "1",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsh6: {
      color: "white",
      fontWeight: "700",
      fontSize: "26px",
      lineHeight: "1.35",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    componentsbody1: {
      fontWeight: "400",
      borderWidth: "0",
      borderStyle: "solid",
      whiteSpace: "pre-line",
      fontSize: "13px",

      letterSpacing: "-1px",
      color: "#fff",
      borderColor: "#e5e7eb",
      margin: "0",
      padding: "0",
      display: "block",
      whiteSpace: "pre-line",
      lineHeight: 1.7,
    },
    componentsref: {
      fontWeight: "400",
      borderWidth: "0",
      borderStyle: "solid",
      whiteSpace: "pre-line",
      fontSize: "13px",

      letterSpacing: "-1px",
      color: "#e5e7eb",
      borderColor: "#e5e7eb",
      margin: "0",
      padding: "0",
      display: "block",
      whiteSpace: "pre-line",
      lineHeight: 1.7,
    },
    componentsbodyApplication: {
      fontWeight: "400",
      borderWidth: "0",
      borderStyle: "solid",
      whiteSpace: "pre-line",
      fontSize: "14px",

      letterSpacing: "-1px",
      color: "#fff",
      borderColor: "#e5e7eb",
      margin: "0",
      padding: "0",
      display: "block",
      whiteSpace: "pre-line",
      lineHeight: 1.6,
    },
    // Career
    careerh1: {
      fontWeight: "700",
      borderWidth: "0",
      borderStyle: "solid",
      whiteSpace: "pre-line",
      fontSize: "16px",
      letterSpacing: "-1px",
      color: "#1a1e24",
      borderColor: "#e5e7eb",
      margin: "0",
      padding: "0",
      whiteSpace: "pre-line",
      lineHeight: 1,
    },

    careerh2: {},
    careerWhiteh1: {},
    careerWhiteh2: {},
    careerWhiteBody1: {},
    careerDarkGreyBody1: {},

    newsh1: {
      fontFamily: "Inter",
      paddingLeft: "10px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      whiteSpace: "pre-line",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "black",
      fontWeight: "700",
      fontSize: "24px",
      letterSpacing: "-1px",
      display: "block",
      whiteSpace: "pre-line",
    },
    newsh2: {
      paddingLeft: "10px",

      fontFamily: "Inter",
      fontSize: "13px",
      fontWeight: "700",
      letterSpacing: "-1px",
      lineHeight: 1.2,
      whiteSpace: "pre-line",
      wordBreak: "normal",
      wordWrap: "break-word",
      boxSizing: "border-box",
      color: "#1a1E24",
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },
    white: {
      main: "#fff",
    },
  },
  components: {
    MuiToolbar: {
      overrides: {
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
  },
});

function App() {
  const matches = useMediaQuery("(min-width:900px)");
  const [isLogin, setIsLogin] = React.useState(
    sessionStorage.getItem("token") ? true : false
  );
  const [initialize, setInitialize] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false);
  const [language, setLanguage] = React.useState("ko");
  const [adminTables, setAdminTables] = React.useState(null);
  const [adminFocused, setAdminFocused] = React.useState(null);
  // const [setDialogMessage, dialogProps] = useDialog();

  const [network, setNetwork] = React.useState(null);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [lang, setLang] = React.useState("en");
  const contextValue = {
    user,
    setUser,
    isLogin,
    setIsLogin,
    drawerIsOpen,
    setDrawerIsOpen,
    initialize,
    setInitialize,
    adminTables,
    setAdminTables,
    language,
    setLanguage,
    // setDialogMessage,
    // dialogProps,
    network,
    setNetwork,
    selectedAddress,
    setSelectedAddress,
    adminFocused,
    setAdminFocused,
  };

  function getLanguageOnBrowser() {
    return acceptedlanguages?.accepted;
  }
  function isBrowserLangKo() {
    const value = getLanguageOnBrowser();

    if (!value || value.length === 0) {
      return false;
    }
    let ko = false;
    value.some((element) => {
      const lowerCaseElement = element.toLowerCase();
      if (lowerCaseElement.includes("ko") || lowerCaseElement.includes("kr")) {
        ko = true;
        return true;
      }
    });
    return ko;
  }

  React.useEffect(() => {
    const localLang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : isBrowserLangKo()
      ? "ko"
      : "en";
    localStorage.setItem("language", localLang);
    i18n.changeLanguage(localLang);
  }, []);

  return (
    <React.Fragment>
      <GlobalContext.Provider value={contextValue}>
        <ThemeProvider theme={matches ? lgtheme : smtheme}>
          <CssBaseline />
          {/* <Portal> */}
          <BasicDrawer />
          <Router>
            <ScrollToTop />
            <Routes />
          </Router>
          {/* </Portal> */}
        </ThemeProvider>
      </GlobalContext.Provider>
    </React.Fragment>
  );
}

export default App;

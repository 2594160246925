import React from "react";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import Link from "@mui/material/Link";
import { UsePagination } from "component/components";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import { EmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import { useAuth } from "authContext";
import { useHistory } from "react-router-dom";

//careers

import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { app, db } from "firebaseConfig";

import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

function AdminMain(props) {
  const auth = useAuth();
  let history = useHistory();

  // const [locationKeys, setLocationKeys] = React.useState([]);

  // React.useEffect(() => {
  //   // 뒷정리 함수 이용
  //   return history.listen((location) => {
  //     if (history.action === "PUSH") {
  //       setLocationKeys([location.key]);
  //       const allowGoBack = window.confirm(
  //         "작성중인 내용이 사라집니다. 뒤로가시겠습니까?"
  //       );
  //     }

  //     if (history.action === "POP") {
  //       if (locationKeys[1] === location.key) {
  //         setLocationKeys(([_, ...keys]) => keys);
  //         const allowGoBack = window.confirm(
  //           "작성중인 내용이 사라집니다. 뒤로가시겠습니까?"
  //         );

  //         // 앞으로 가기
  //       } else {
  //         setLocationKeys((keys) => [location.key, ...keys]);

  //         const allowGoBack = window.confirm(
  //           "작성중인 내용이 사라집니다. 뒤로가시겠습니까?"
  //         );
  //         // 뒤로 가기
  //         // history.push("/detail");
  //       }
  //     }
  //   });
  // }, [locationKeys, useHistory()]);

  // window.addEventListener("popstate", function (event) {
  //   //Your code here
  //   console.log("뒤로가기");
  //   const allowGoBack = window.confirm(
  //     "작성중인 내용이 사라집니다. 뒤로가시겠습니까?"
  //   );
  //   if (!allowGoBack) {
  //     return;
  //   }
  // });

  // React.useEffect(() => {
  //   const unListen = window.addEventListener("popstate", () => {
  //     console.log("User clicked back button");
  //     window.confirm("작성중인 내용이 사라집니다. 뒤로가시겠습니까11?");
  //   });
  //   return () => {
  //     unListen();
  //   };
  // }, []);

  // window.onpageshow = function (event) {
  //   if (
  //     event.persisted ||
  //     (window.performance && window.performance.navigation.type == 2)
  //   ) {
  //     // Back Forward Cache로 브라우저가 로딩될 경우 혹은 브라우저 뒤로가기 했을 경우
  //     // 이벤트 추가하는 곳
  //     console.log("back button event");
  //     window.confirm("작성중인 내용이 사라집니다. 뒤로가시겠습니까?");
  //   }
  // };

  // React.useEffect(() => {
  //   history.listen((loc, action) => {
  //     if (action === "POP") {
  //       // Do your stuff
  //       window.confirm("작성중인 내용이 사라집니다. 뒤로가시겠습니까?");
  //     }
  //   });

  //   const backListener = history.listen((loc, action) => {
  //     if (action === "POP") {
  //       // Do your stuff
  //       window.confirm("작성중인 내용이 사라집니다. 뒤로가시겠습니까?");
  //     }
  //     return () => {
  //       backListener();
  //     };
  //   })();
  // }, []);

  const matches = useMediaQuery("(min-width:900px)");

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setLoding] = React.useState(false);
  const [career, setCareer] = React.useState(null);
  const [newsroom, setNewsroom] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        await init();
        setRefresh(false);
      }
    })();
  }, [refresh]);

  async function init() {
    const car = await getFirebaseDoc("career");
    setCareer(car.posts);
    const news = await getFirebaseDoc("newsroom");
    setNewsroom(news.posts);
    const com = await getFirebaseDoc("company");
    setCompany(com.posts);
  }

  async function download(path) {
    const storage = getStorage();
    try {
      const httpsReference = ref(storage, path);

      const url = await getDownloadURL(httpsReference);

      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        const blob = xhr.response;
      };
      xhr.open("GET", url);
      xhr.send();
      return;
    } catch (e) {
      alert(e.message);
      return;
    }

    // Or inserted into an <img> element
    // const img = document.getElementById("myimg");
    // img.setAttribute("src", url);
  }
  const [companyPage, setCompanyPage] = React.useState(1);
  const companyChange = (e, number) => {
    setCompanyPage(number);
  };
  const [careerPage, setCareerPage] = React.useState(1);
  const careerChange = (e, number) => {
    setCareerPage(number);
  };
  const [newsroomPage, setNewRoomPage] = React.useState(1);
  const newsroomChange = (e, number) => {
    setNewRoomPage(number);
  };
  const itemsPerPage = 10;

  async function deleteFiles(refs) {
    const storage = getStorage();

    // Create a reference to the file to delete
    const httpsReference = ref(storage, refs);

    // const desertRef = ref(storage, "images/desert.jpg");

    // Delete the file
    try {
      await deleteObject(httpsReference);
    } catch (e) {
      alert(e.message);
    }
  }
  async function delete_doc(collection, path) {
    await deleteDoc(doc(db, collection, path));
  }

  async function getFirebaseDoc(table) {
    try {
      const querySnapshot = await getDocs(collection(db, table));

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }

  function Companys() {
    <Grid item xs={12} md={4}>
      <Stack>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography sx={{ fontSize: "26px" }}>
            <Typography sx={{ fontSize: "26px", color: "#6d6e6e" }}>
              Careers
            </Typography>{" "}
            채용 공고
          </Typography>

          <Button
            size="small"
            onClick={() => {
              history.push("/career/post");
            }}
            sx={{
              minWidth: matches ? "128px" : "128.5px",
              height: "33px",
              marginRight: matches ? "20px" : "24px",
              backgroundColor: "#e5e5e5",
              color: "#fff",
              "&.MuiButtonBase-root": {
                borderRadius: "0",
              },
              "&:hover": {
                backgroundColor: "#e5e5e5",
              },
            }}
            fullWidth={false}
          >
            게시글 작성하기
          </Button>
        </Stack>
        <EmptySpace height="20px" />

        <Box sx={{ backgroundColor: "#f3f3f3" }}>
          {career &&
            career.map((v, i) => (
              <>
                {itemsPerPage * careerPage >= i + 1 &&
                  itemsPerPage * careerPage - itemsPerPage < i + 1 && (
                    <Container sx={{ p: 4 }} key={v.id}>
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Typography
                          sx={{ fontWeight: "400", marginRight: "8px" }}
                        >
                          {`한글${v.data?.category} `}
                          {`영문${v.data?.category_en} `}
                          {v.data.category + " "}
                          {v.data?.title ? v.data.title : v.data.title_en}
                        </Typography>
                        <Button
                          size="small"
                          onClick={() => {
                            console.log("히스토리");
                            history.push(`/career/post/${v.id}`);
                          }}
                          sx={{
                            backgroundColor: "#fff",
                            color: "#000",
                            "&.MuiButtonBase-root": {
                              borderRadius: "0",
                            },
                            "&:hover": {
                              backgroundColor: "#fff",
                            },
                          }}
                        >
                          수정
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "c4c3c4",
                            color: "#000",
                            "&.MuiButtonBase-root": {
                              borderRadius: "0",
                            },
                            "&:hover": {
                              backgroundColor: "#c4c3c4",
                            },
                          }}
                          onClick={async () => {
                            const conf = window.confirm("삭제하시겠습니까");
                            if (!conf) {
                              return;
                            }

                            if (v.data.files.length) {
                              for await (const file of v.data.files)
                                await deleteFiles(file);
                            }

                            await delete_doc("career", v.id);
                            alert("삭제 완료");
                            setRefresh(true);
                          }}
                        >
                          삭제
                        </Button>
                      </Stack>
                    </Container>
                  )}
              </>
            ))}
        </Box>
      </Stack>
      <Box></Box>
    </Grid>;
  }

  return (
    <>
      <Container sx={{ p: 4 }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Link underline={"none"} href={"/adminmain"}>
            <Typography>관리자 페이지</Typography>
          </Link>
          <Box>
            <Button
              size="small"
              sx={{
                height: "36px",
                backgroundColor: "#e5e5e5",
                color: "#000",
                "&.MuiButtonBase-root": {
                  borderRadius: "0",
                },
                "&:hover": {
                  backgroundColor: "#e5e5e5",
                },
              }}
              onClick={async () => {
                const result = await auth.logOut();
                console.log("signoutresult", result);
                if (result.code !== 200) {
                  alert(result.msg);
                  return;
                }
                history.push("/login");
              }}
            >
              로그아웃
            </Button>
          </Box>
        </Stack>
        <EmptySpace height="100px" />

        <Grid container rowSpacing={6} columnSpacing={{ xs: 0, sm: 0, md: 6 }}>
          {/* Career */}
          <Grid item xs={12} md={4}>
            <Stack>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ fontSize: "26px" }}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "26px",
                      color: "#6d6e6e",
                    }}
                  >
                    Careers
                  </Typography>{" "}
                  채용 공고
                </Typography>

                <Button
                  onClick={() => {
                    console.log("히스토리");
                    history.push(`/career/post`);
                  }}
                  size="small"
                  sx={{
                    minWidth: matches ? "128px" : "128.5px",
                    height: "33px",
                    marginRight: matches ? "20px" : "24px",
                    backgroundColor: "#212121",
                    color: "#fff",
                    "&.MuiButtonBase-root": {
                      borderRadius: "0",
                    },
                    "&:hover": {
                      backgroundColor: "#212121",
                    },
                  }}
                  fullWidth={false}
                  // href={`/career/post`}
                >
                  게시글 작성하기
                </Button>
              </Stack>
              <EmptySpace height="20px" />

              <Box sx={{ backgroundColor: "#f3f3f3" }}>
                {career &&
                  career
                    .slice(0)
                    .reverse()
                    .map((v, i) => (
                      <>
                        {itemsPerPage * careerPage >= i + 1 &&
                          itemsPerPage * careerPage - itemsPerPage < i + 1 && (
                            <Container
                              sx={{
                                p: 4,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // border: "1px solid #d8d8d8",
                                fontSize: "14px",
                              }}
                              key={v.id}
                            >
                              <Box
                                sx={{
                                  // border: "1px solid red",
                                  flex: "2",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    flexDirection: "row",
                                    display: "flex",
                                    justifyContent: "start",

                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <span
                                    style={{
                                      flex: "0.5",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    한글
                                  </span>
                                  <span
                                    style={{
                                      flex: "1",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {` ${v.data?.category ?? ""}`}
                                  </span>
                                  <span
                                    style={{
                                      flex: "1",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontWeight: "400",
                                    }}
                                    f
                                  >
                                    {` ${v.data?.title ?? ""}`}
                                  </span>
                                </Box>
                                <Box
                                  sx={{
                                    flexDirection: "row",
                                    display: "flex",
                                    justifyContent: "start",
                                  }}
                                >
                                  <span
                                    style={{
                                      flex: "0.5",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    영문
                                  </span>
                                  <span
                                    style={{
                                      flex: "1",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",

                                      fontWeight: "400",
                                    }}
                                  >
                                    {` ${v.data?.category_en ?? ""}`}
                                  </span>
                                  <span
                                    style={{
                                      flex: "1",
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {`${v.data?.title_en ?? ""}`}
                                  </span>
                                </Box>

                                {/* {v.data &&
                                    v.data.title &&
                                    v.data.category + " " + v.data.title}
                                  {v.data &&
                                    !v.data.title &&
                                    v.data.title_en &&
                                    v.data.category_en + " " + v.data.title_en} */}
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  // border: "1px solid red",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  height: "30.7px",
                                  justifyContent: "end",
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    console.log("히스토리");
                                    history.push(`/career/post/${v.id}`);
                                  }}
                                  size="small"
                                  sx={{
                                    backgroundColor: "#fff",
                                    color: "#000",
                                    "&.MuiButtonBase-root": {
                                      borderRadius: "0",
                                    },
                                    "&:hover": {
                                      backgroundColor: "#fff",
                                    },
                                  }}
                                  // href={`/career/post/${v.id}`}
                                >
                                  수정
                                </Button>
                                <Button
                                  size="small"
                                  sx={{
                                    backgroundColor: "#c4c3c4",
                                    color: "#000",
                                    "&.MuiButtonBase-root": {
                                      borderRadius: "0",
                                    },
                                    "&:hover": {
                                      backgroundColor: "#c4c3c4",
                                    },
                                  }}
                                  onClick={async () => {
                                    const conf =
                                      window.confirm("삭제하시겠습니까");
                                    if (!conf) {
                                      return;
                                    }
                                    if (v.data.files.length) {
                                      for await (const file of v.data.files)
                                        await deleteFiles(file);
                                    }

                                    await delete_doc("career", v.id);
                                    alert("삭제 완료");
                                    setRefresh(true);
                                  }}
                                >
                                  삭제
                                </Button>
                              </Box>
                            </Container>
                          )}
                      </>
                    ))}
              </Box>
              {career?.length > 10 && (
                <UsePagination total={career.length} onChange={careerChange} />
              )}
            </Stack>
            <Box></Box>
          </Grid>
          {/* Company */}
          <Grid item xs={12} md={4}>
            <Stack>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ fontSize: "26px" }}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "26px",
                      color: "#6d6e6e",
                    }}
                  >
                    Investor Relations
                  </Typography>{" "}
                  공고
                </Typography>

                <Button
                  size="small"
                  onClick={() => {
                    console.log("히스토리");
                    history.push(`/company/post`);
                  }}
                  sx={{
                    minWidth: matches ? "128px" : "128.5px",
                    height: "33px",
                    marginRight: matches ? "20px" : "24px",
                    height: "33px",
                    backgroundColor: "#212121",
                    color: "#fff",
                    "&.MuiButtonBase-root": {
                      borderRadius: "0",
                    },
                    "&:hover": {
                      backgroundColor: "#212121",
                    },
                  }}
                  fullWidth={false}
                  // href={`/company/post`}
                >
                  게시글 작성하기
                </Button>
              </Stack>
              <EmptySpace height="20px" />

              <Box sx={{ backgroundColor: "#f3f3f3" }}>
                {company &&
                  company
                    .slice(0)
                    .reverse()
                    .map((v, i) => (
                      <>
                        {itemsPerPage * companyPage >= i + 1 &&
                          itemsPerPage * companyPage - itemsPerPage < i + 1 && (
                            <Container sx={{ p: 4 }} key={v.id}>
                              <Stack
                                direction="row"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography
                                  sx={{ fontWeight: "400", marginRight: "8px" }}
                                >
                                  {v.data.title}
                                </Typography>
                                <Box sx={{ minWidth: "129px" }}>
                                  <Button
                                    onClick={() => {
                                      console.log("히스토리");
                                      history.push(`/company/post/${v.id}`);
                                    }}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      "&.MuiButtonBase-root": {
                                        borderRadius: "0",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#fff",
                                      },
                                    }}
                                    // href={`/company/post/${v.id}`}
                                  >
                                    수정
                                  </Button>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: "#c4c3c4",
                                      color: "#000",
                                      "&.MuiButtonBase-root": {
                                        borderRadius: "0",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#c4c3c4",
                                      },
                                    }}
                                    onClick={async () => {
                                      const conf =
                                        window.confirm("삭제하시겠습니까");
                                      if (!conf) {
                                        return;
                                      }
                                      if (v.data.files.length) {
                                        for await (const file of v.data.files)
                                          await deleteFiles(file);
                                      }

                                      await delete_doc("company", v.id);
                                      alert("삭제 완료");
                                      setRefresh(true);
                                    }}
                                  >
                                    삭제
                                  </Button>
                                </Box>
                              </Stack>
                            </Container>
                          )}
                      </>
                    ))}
              </Box>
              {company?.length > 10 && (
                <UsePagination
                  total={company.length}
                  onChange={companyChange}
                />
              )}
            </Stack>
          </Grid>
          {/* Newsroom */}
          <Grid item xs={12} md={4}>
            <Stack>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography sx={{ fontSize: "26px" }}>
                  <Typography
                    sx={{
                      display: "inline",
                      fontSize: "26px",
                      color: "#6d6e6e",
                    }}
                  >
                    Newsroom
                  </Typography>{" "}
                  뉴스
                </Typography>

                <Button
                  size="small"
                  onClick={() => {
                    console.log("히스토리");
                    history.push(`/newsroom/post`);
                  }}
                  sx={{
                    minWidth: matches ? "128px" : "128.5px",
                    height: "33px",
                    marginRight: matches ? "20px" : "24px",
                    height: "33px",
                    marginRight: "20px",
                    height: "33px",
                    backgroundColor: "#212121",
                    color: "#fff",
                    "&.MuiButtonBase-root": {
                      borderRadius: "0",
                    },
                    "&:hover": {
                      backgroundColor: "#212121",
                    },
                  }}
                  fullWidth={false}
                  // href={`/newsroom/post`}
                >
                  게시글 작성하기
                </Button>
              </Stack>
              <EmptySpace height="20px" />

              <Box sx={{ backgroundColor: "#f3f3f3" }}>
                {newsroom &&
                  newsroom
                    .slice(0)
                    .reverse()
                    .map((v, i) => (
                      <>
                        {itemsPerPage * newsroomPage >= i + 1 &&
                          itemsPerPage * newsroomPage - itemsPerPage <
                            i + 1 && (
                            <Container sx={{ p: 4 }} key={v.id}>
                              <Stack
                                direction="row"
                                justifyContent={"space-between"}
                                alignItems={"center"}
                              >
                                <Typography
                                  sx={{ fontWeight: "400", marginRight: "8px" }}
                                >
                                  {v.data.title}
                                </Typography>
                                <Box sx={{ minWidth: "129px" }}>
                                  <Button
                                    onClick={() => {
                                      console.log("히스토리");
                                      history.push(`/newsroom/post/${v.id}`);
                                    }}
                                    size="small"
                                    sx={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      "&.MuiButtonBase-root": {
                                        borderRadius: "0",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#fff",
                                      },
                                    }}
                                    // href={`/newsroom/post/${v.id}`}
                                  >
                                    수정
                                  </Button>
                                  <Button
                                    size="small"
                                    sx={{
                                      backgroundColor: "#c4c3c4",
                                      color: "#000",
                                      "&.MuiButtonBase-root": {
                                        borderRadius: "0",
                                      },
                                      "&:hover": {
                                        backgroundColor: "#c4c3c4",
                                      },
                                    }}
                                    onClick={async () => {
                                      const conf =
                                        window.confirm("삭제하시겠습니까");
                                      if (!conf) {
                                        return;
                                      }
                                      if (v.data.files.length) {
                                        for await (const file of v.data.files)
                                          await deleteFiles(file);
                                      }

                                      await delete_doc("newsroom", v.id);
                                      alert("삭제 완료");
                                      setRefresh(true);
                                    }}
                                  >
                                    삭제
                                  </Button>
                                </Box>
                              </Stack>
                            </Container>
                          )}
                      </>
                    ))}
              </Box>
              {newsroom?.length > 10 && (
                <UsePagination
                  total={newsroom.length}
                  onChange={newsroomChange}
                />
              )}
            </Stack>
            <Box></Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AdminMain;

import React from "react";
import GlobalContext from "context/globalContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import Link from "@mui/material/Link";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo_black from "asset/2023-HIDEEP-logo-black.png";
import logo_white from "asset/2023-HIDEEP-logo-gray.png";
import logo_sm from "asset/logo_sm.png";
import logo_sm_white from "asset/logo_sm_white.png";

import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import TreeItem, { useTreeItem } from "@mui/lab/TreeItem";
import clsx from "clsx";
import { maxHeight } from "@mui/system";

import usePagination from "@mui/material/usePagination";
import { styled } from "@mui/material/styles";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});
export function UsePagination({ total, currentPage, onChange }) {
  const itemsPerPage = 10;
  const { items } = usePagination({
    count: Math.ceil(total / itemsPerPage),
    currentPage,
    onChange,
  });

  return (
    <nav>
      <List>
        {items.map(({ page, type, selected, disabled, ...item }, index) => {
          let children = null;

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (type === "page") {
            children = (
              <div
                style={{
                  position: "relative",
                }}
              >
                <button
                  type="button"
                  style={{
                    border: "0px solid white",
                    background: "none",
                    fontWeight: selected ? "bold" : undefined,
                    textDecoration: selected ? "underline" : undefined,
                    textDecorationThickness: "1px",
                    textUnderlinePosition: "under",
                    fontSize: "15px",
                  }}
                  {...item}
                >
                  {page}
                </button>
              </div>
            );
          } else {
            if (type === "previous") {
              children = (
                <button
                  type="button"
                  style={{
                    display: disabled ? "none" : undefined,
                    border: "0px solid white",
                    background: "none",
                    fontWeight: selected ? "bold" : undefined,
                    textDecoration: selected ? "underline" : undefined,
                    textDecorationThickness: "1px",
                    textUnderlinePosition: "under",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  {...item}
                >
                  {"<"}
                </button>
              );
            } else if (type === "next") {
              children = (
                <button
                  type="button"
                  style={{
                    display: disabled ? "none" : undefined,
                    border: "0px solid white",
                    background: "none",
                    fontWeight: selected ? "bold" : undefined,
                    textDecoration: selected ? "underline" : undefined,
                    textDecorationThickness: "1px",
                    textUnderlinePosition: "under",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                  {...item}
                >
                  {">"}
                </button>
              );
            } else {
              children = (
                <button type="button" {...item}>
                  {type}
                </button>
              );
            }
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}

export function BasicDrawer({ open, close }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { drawerIsOpen, setDrawerIsOpen, setIsLogin, isLogin, setUser } =
    React.useContext(GlobalContext);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  // const list = (anchor) => (
  //   <Box
  //     sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
  //     role="presentation"
  //     onClick={toggleDrawer(anchor, false)}
  //     onKeyDown={toggleDrawer(anchor, false)}
  //   >
  //     <List>
  //       {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //     <Divider />
  //     <List>
  //       {["All mail", "Trash", "Spam"].map((text, index) => (
  //         <ListItem key={text} disablePadding>
  //           <ListItemButton>
  //             <ListItemIcon>
  //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //             </ListItemIcon>
  //             <ListItemText primary={text} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );
  return (
    <div style={{ position: "relative" }}>
      {drawerIsOpen && (
        <div
          style={{
            position: "absolute",
            top: "13px",
            left: "310px",
            width: "25px",
            height: "25px",
            margin: "5px",
            zIndex: "999999",
          }}
        >
          <CloseIcon
            onClick={() => {
              setDrawerIsOpen(!drawerIsOpen);
            }}
            sx={{ color: "#fff", width: "25px", height: "25px" }}
          />
        </div>
      )}
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            sx={{ position: "relative" }}
            anchor={"left"}
            open={drawerIsOpen}
            onClose={() => setDrawerIsOpen(!drawerIsOpen)}
          >
            <Box sx={{ width: "300px" }}>
              <TreeView
                aria-label="icon expansion"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ExpandLessIcon />}
                sx={{
                  height: 500,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: "auto",
                }}
              >
                <Box sx={{ width: "10px", height: "10px" }}></Box>
                <CustomTreeItem nodeId="1" label="Technology">
                  <CustomTreeItem nodeId="2" label="Touch" />
                  <CustomTreeItem nodeId="3" label="3D Touch" />
                  <CustomTreeItem nodeId="4" label="Stylus" />
                </CustomTreeItem>
                <CustomTreeItem nodeId="5" label="Company"></CustomTreeItem>

                <CustomTreeItem nodeId="6" label="Careers"></CustomTreeItem>
                <CustomTreeItem
                  nodeId="8"
                  label="Investor Relations"
                ></CustomTreeItem>
                <CustomTreeItem nodeId="7" label="Newsroom"></CustomTreeItem>
              </TreeView>
              {/* </Container> */}
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
export function EmptySpace({ height = "300px" }) {
  return <Box sx={{ height: `${height}`, maxWidth: "100%" }}></Box>;
}
export function BlackEmptySpace({ height = "300px" }) {
  return (
    <Box
      sx={{ height: `${height}`, maxWidth: "100%", backgroundColor: "black" }}
    ></Box>
  );
}

export function BasicAppBar({ white }) {
  const matches = useMediaQuery("(min-width:900px)");
  const { drawerIsOpen, setDrawerIsOpen } = React.useContext(GlobalContext);
  const openDrawer = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };
  function AppBarLG({ white }) {
    console.log("화이트", white);

    return (
      <Box
        className={white ? `black backtransition` : `white backtransition`}
        style={{
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "90px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          zIndex: "9999999999",
          backgroundColor: white ? "#000" : "#fff",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
          transition: "all .5s ease",
          WebkitTransition: "all .5s ease",
          MozTransition: "all .5s ease",
        }}
      >
        <Link
          sx={{ padding: "0", marginTop: "8px" }}
          underline="none"
          href="/main"
        >
          <img
            src={white ? logo_white : logo_black}
            style={{
              height: "auto",
              maxWidth: "139.27px",
            }}
          ></img>
        </Link>
        <Box
          sx={{
            flexGrow: 1,
            position: "fixed",
            top: "65px",
            left: "0px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: white ? "#000" : "#fff",
          }}
        >
          <Link
            href="/technology"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "500",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Technology
          </Link>

          <Link
            href="/company"
            variant="h4"
            sx={{
              fontFamily: "Inter",
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Company
          </Link>
          <Link
            href="/careers"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Careers
          </Link>
          <Link
            href="/InvestorRelations"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Investor Relations
          </Link>
          <Link
            href="/newsroom"
            variant="h4"
            sx={{
              padding: "0 20px",
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",

              textDecoration: "none",
              fontWeight: "bold",
              color: "#1a1e24",
              letterSpacing: "0px",
              lineHeight: "2",
              fontStyle: "normal",
              fontFamily: "Inter",
              color: white ? "#fff" : "#212121",
            }}
          >
            Newsroom
          </Link>
        </Box>
      </Box>
    );
  }
  function AppBarSM() {
    return (
      <Box
        sx={{
          flexGrow: 1,
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "48px",
          zIndex: "999",
        }}
      >
        <AppBar
          position="static"
          sx={{
            backgroundColor: white ? "black" : "#fff",
            boxShadow: "none",
            height: "48px",
            minHeight: "48px",
            maxHeight: "48px",
          }}
        >
          <Toolbar
            sx={{
              backgroundColor: white ? "black" : "#fff",
              transition: "background-color 1000ms linear",
              boxShadow: "none",
              justifyContent: "space-between",
              display: "flex",
              alignContent: "center",
              height: "48px",
              minHeight: "48px",
            }}
          >
            <IconButton
              size="samll"
              edge="start"
              color={white ? "#000" : "#fff"}
              aria-label="menu"
              sx={{ mr: 2, color: "black", maxHeight: "48px" }}
              onClick={openDrawer}
            >
              <MenuIcon color={white ? "white" : "black"} />
            </IconButton>
            <Link
              underline="none"
              href="/main"
              sx={{ padding: "0", marginTop: "8px" }}
            >
              <img
                src={white ? logo_sm_white : logo_sm}
                style={{
                  marginRight: "45px",
                  height: "auto",
                  maxWidth: "56.2px",
                }}
              ></img>
            </Link>

            <Typography sx={{ height: "48px" }} color="inherit">
              {" "}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
    );
  }

  return (
    <>{matches ? <AppBarLG white={white} /> : <AppBarSM white={white} />}</>
  );
}

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event, link) => {
    handleSelection(event);
  };
  function nested(label) {
    return label === "Touch" || label === "3D Touch" || label === "Stylus";
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        // [classes.selected]: selected,
        // [classes.focused]: focused,
        // [classes.disabled]: disabled,
      })}
      // style={{ display: "block" }}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <Typography
        onClick={(event) => handleSelectionClick(event)}
        component="div"
        className={classes.label}
        style={{
          fontSize: nested(label) ? "14px" : "14px",
          fontStyle: "normal",
          fontWeight: "bold",
          color: "#212121",
          letterSpacing: "0px",
          padding: nested(label) ? " 2px 15px" : "4px 15px",
        }}
      >
        <Link
          href={`/${label.replace(/\s/g, "")}`}
          underline="none"
          style={{
            fontSize: nested(label) ? "14px" : "14px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontWeight: "bold",

            color: "#212121",
            letterSpacing: "0px",
          }}
        >
          {label}
        </Link>
      </Typography>

      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
    </div>
  );
});
function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
        setVisible(entry.isIntersecting);
      });
    });

    const { current } = domRef;
    observer.observe(current);

    return () => observer.unobserve(current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}
export function HoverScalingImg({ aspectRatio, img }) {
  return (
    <FadeInSection>
      <Box
        style={{
          overflow: "hidden",
          width: "100%",
          position: "relative",
          aspectRatio: aspectRatio,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% 50%",
          display: "inline-block",

          overflow: "hidden",
        }}
      >
        <Box
          style={{
            overflow: "hidden",
            width: "100%",
            position: "relative",
            height: "100%",

            aspectRatio: aspectRatio,
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            display: "block",
          }}
          className="zoom"
        ></Box>
      </Box>
    </FadeInSection>
  );
}

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired,
};

function CustomTreeItem(props) {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
}
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

import React from "react";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import { EmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import { useAuth } from "authContext";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

//careers
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  decrement,
  getCountFromServer,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { app, db } from "firebaseConfig";

import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import moment from "moment";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";
import { Editor } from "@toast-ui/react-editor";
import { Parser, createRenderHTML } from "@toast-ui/toastmark";

import "tui-color-picker/dist/tui-color-picker.css";
import "@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import Prism from "prismjs"; // prism 테마 추가
function AdminMain(props) {
  const { id } = useParams();

  const editorRef = React.useRef(null);
  const fileInput = React.useRef(null);
  const editorRef_en = React.useRef(null);

  const [categ, setCateg] = React.useState(null);
  const [created, setCreated] = React.useState(null);

  const [title, setTitle] = React.useState(null);

  const [categ_en, setCateg_en] = React.useState(null);
  const [title_en, setTitle_en] = React.useState(null);

  const [loading, setLoading] = React.useState(false);
  const [selectedCat, setSelectedCat] = React.useState(null);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setLoding] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [content, setContent] = React.useState(null);
  const [content_en, setContent_en] = React.useState(null);

  const [isBlocking, setIsBlocking] = React.useState(false);

  const toolbarItems = [
    ["heading", "bold", "italic", "strike"],
    ["hr"],
    ["ul", "ol", "task"],
    ["table", "link"],
    ["image"],
    ["code"],
    ["scrollSync"],
  ];

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        console.log("뭘까");

        init();
        setRefresh(false);
      }
    })();
  }, [refresh]);
  React.useEffect(() => {
    (async () => {
      init();
    })();
  }, []);

  async function init() {
    console.log("뭘까");

    let rule = ".toastui-editor-md-preview {background-color: #1A1E24}";
    // Load the rules and execute after the DOM loads

    addCss(rule);

    const docRef = doc(db, `/career/${id}`);
    const docSnap = await getDoc(docRef);
    setLoading(true);
    setContent(docSnap.data().contents);
    setTitle(docSnap.data().title);
    setCateg(docSnap.data().category);
    // if (!docSnap.data()?.title_en) {
    //   setLoading(false);
    //   return;
    // }
    console.log("뭘까", docSnap.data());
    console.log("뭘까 content_en", docSnap.data().contents_en);

    setContent_en(docSnap.data().contents_en);
    setTitle_en(docSnap.data().title_en);
    setCateg_en(docSnap.data().category_en);
    setCreated(docSnap.data().created);
  }
  React.useEffect(() => {
    if (title || title_en) {
      setLoading(false);
      console.log("로딩", content_en);
    }
  }, [content]);
  function createRefId() {
    return moment().format("YYYY-MM-DD HH:mm:ss").toString() + shuffle(5);
  }
  function shuffle(count) {
    //v1.0
    var chars = "acdefhiklmnoqrstuvwxyz0123456789".split("");
    var result = "";
    for (var i = 0; i < count; i++) {
      var x = Math.floor(Math.random() * chars.length);
      result += chars[x];
    }
    return result;
  }
  const matches = useMediaQuery("(min-width:900px)");

  async function onSave(
    title,
    contents,
    category,
    title_en,
    category_en,
    contents_en,
    files,
    created
  ) {
    const postId = id;
    const now = new Date();
    const post = {
      title,
      contents,
      category,
      title_en,
      category_en,
      contents_en,
      files,
      created,
      updated: now,
    };
    const Ref = doc(db, "career", postId);
    await setDoc(Ref, post, { merge: false });
  }

  const onUploadImage = async (blob, callback) => {
    const url = await uploadImage(blob);
    callback(url, "alt text");
    return false;
  };
  async function uploadImage(blob) {
    const spaceRef = ref(storage, `images/${createRefId()}`);
    const uploaded = await uploadBytes(spaceRef, blob);
    const downloadURL = await getDownloadURL(uploaded.ref);
    console.log(downloadURL);
    return downloadURL;
  }

  async function uploadFiles(files) {
    const id = createRefId();

    const urls = [];
    if (!files.length) {
      return urls;
    }
    for await (const file of files) {
      const spaceRef = ref(storage, `career/files/${id}/${file.name}`);
      const uploaded = await uploadBytes(spaceRef, file);
      const downloadURL = await getDownloadURL(uploaded.ref);
      console.log(downloadURL);
      urls.push(downloadURL);
    }
    return urls;
  }

  function addCss(rule) {
    let css = document.createElement("style");
    css.type = "text/css";
    if (css.styleSheet) css.styleSheet.cssText = rule; // Support for IE
    else css.appendChild(document.createTextNode(rule)); // Support for the rest
    document.getElementsByTagName("head")[0].appendChild(css);
  }

  // CSS rules
  let rule = ".toastui-editor-md-preview {background-color: #1A1E24}";
  let rule2 = ".toastui-editor-main-container {background-color: #1A1E24}";
  // Load the rules and execute after the DOM loads
  window.onload = function () {
    addCss(rule);
    addCss(rule2);
  };

  const auth = useAuth();
  let history = useHistory();
  const storage = getStorage(app);
  const storageRef = ref(storage);
  if (loading) {
    <Container sx={{ p: 4 }}>
      <Typography>Loading</Typography>
    </Container>;
  }

  React.useEffect(() => {
    let unlisten = history.block((loc, action) => {
      if (action === "POP") {
        return window.confirm("작성중인 내용이 사라집니다. 뒤로가시겠습니까?");
      }
      // if (action === "PUSH") {
      //   return window.confirm("작성중인 내용이 사라집니다. 이동하시겠습니까?");
      // }
      return true;
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <>
      <Container sx={{ p: 4 }}>
        <Box>
          <Link
            underline={"none"}
            onClick={() => {
              history.push("/adminmain");
            }}
            // href={"/adminmain"}
          >
            <Typography>관리자 페이지</Typography>
          </Link>
        </Box>
        <EmptySpace height="50px" />

        <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 0, md: 2 }}>
          <Grid item xs={12} md={12}>
            <Stack direction={"row"} spacing={1}>
              <Typography>Careers</Typography>
              <Typography>채용 공고</Typography>
            </Stack>
            <EmptySpace height="50px" />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction={"column"} spacing={1}>
              <Typography>채용 종류</Typography>
              <TextField
                value={categ}
                onChange={(ev) => setCateg(ev.target.value)}
                size={"small"}
                id="outlined-basic"
                // label="Outlined"
                variant="outlined"
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography>제목</Typography>
              <TextField
                value={title}
                onChange={(ev) => setTitle(ev.target.value)}
                size={"small"}
                id="outlined-basic"
                // label="Outlined"
                variant="outlined"
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <EmptySpace height="10px" />

            <Stack>
              <Stack
                direction="column"
                spacing={1}
                sx={{ fontWeight: "normal" }}
              >
                <Typography>내용</Typography>
                {content && (
                  <Editor
                    ref={editorRef}
                    initialValue={content || ""} // 글 수정 시 사용
                    initialEditType="markdown" // wysiwyg & markdown
                    previewStyle={
                      window.innerWidth > 1000 ? "vertical" : "vertical"
                    } // tab, vertical
                    hideModeSwitch={true}
                    height="calc(80vh - 10rem)"
                    theme={""} // '' & 'dark'
                    usageStatistics={false}
                    toolbarItems={toolbarItems}
                    useCommandShortcut={true}
                    hooks={{
                      addImageBlobHook: onUploadImage,
                    }}
                    plugins={[
                      colorSyntax,
                      [codeSyntaxHighlight, { highlighter: Prism }],
                    ]} // 추가!
                    customHTMLRenderer={{
                      htmlBlock: {
                        iframe(node, context) {
                          return [
                            {
                              type: "openTag",
                              tagName: "iframe",
                              outerNewLine: true,
                              attributes: node.attrs,
                            },
                            { type: "html", content: node.childrenHTML },
                            {
                              type: "closeTag",
                              tagName: "iframe",
                              outerNewLine: true,
                            },
                          ];
                        },
                      },
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Box></Box>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* <Button
              size="small"
              sx={{
                maxWidth: "90px",
                backgroundColor: "#f1f1f1",
                color: "#000",
                "&.MuiButtonBase-root": {
                  borderRadius: "0",
                },
              }}
              onClick={onClickInput}
            >
              파일 첨부
            </Button> */}
            {console.log(fileInput)}
            <input
              type="file"
              onChange={(e) => {
                setFiles(e.target.files);
                console.log(e.target.files);
              }}
              name="file"
              multiple
            />
          </Grid>
        </Grid>
        <EmptySpace height={"200px"} />
        <EmptySpace height={matches ? "200px" : "40px"} />
        <Divider orientation="horizontal" flexItem>
          영문 작성
        </Divider>

        <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 0, md: 2 }}>
          <Grid item xs={12} md={12}>
            {/* <Stack direction={"row"} spacing={1}>
              <Typography>Careers</Typography>
              <Typography>채용 공고</Typography>
            </Stack> */}
            <EmptySpace height="50px" />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack direction={"column"} spacing={1}>
              <Typography>Type of Recruitment</Typography>
              <TextField
                value={categ_en}
                onChange={(ev) => setCateg_en(ev.target.value)}
                size={"small"}
                id="outlined-basic"
                // label="Outlined"
                variant="outlined"
              />
            </Stack>
            <Stack direction={"column"} spacing={1}>
              <Typography>Title</Typography>
              <TextField
                value={title_en}
                onChange={(ev) => setTitle_en(ev.target.value)}
                size={"small"}
                id="outlined-basic"
                // label="Outlined"
                variant="outlined"
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={12}>
            <EmptySpace height="10px" />

            <Stack>
              <Stack
                direction="column"
                spacing={1}
                sx={{ fontWeight: "normal" }}
              >
                {console.log("흠 content_en", content_en)}
                <Typography>Content</Typography>
                {!loading && (
                  <Editor
                    ref={editorRef_en}
                    initialValue={content_en || " "} // 글 수정 시 사용
                    initialEditType="markdown" // wysiwyg & markdown
                    previewStyle={
                      window.innerWidth > 1000 ? "vertical" : "vertical"
                    } // tab, vertical
                    hideModeSwitch={true}
                    height="calc(80vh - 10rem)"
                    theme={""} // '' & 'dark'
                    usageStatistics={false}
                    toolbarItems={toolbarItems}
                    useCommandShortcut={true}
                    hooks={{
                      addImageBlobHook: onUploadImage,
                    }}
                    plugins={[
                      colorSyntax,
                      [
                        codeSyntaxHighlight,
                        { highlighter: Prism },
                        { color: "#ffffff" },
                      ],
                    ]} // 추가!
                    customHTMLRenderer={{
                      htmlBlock: {
                        iframe(node, context) {
                          return [
                            {
                              type: "openTag",
                              tagName: "iframe",
                              outerNewLine: true,
                              attributes: node.attrs,
                            },
                            { type: "html", content: node.childrenHTML },
                            {
                              type: "closeTag",
                              tagName: "iframe",
                              outerNewLine: true,
                            },
                          ];
                        },
                      },
                    }}
                  />
                )}
              </Stack>
            </Stack>
            <Box></Box>
          </Grid>
          <Grid item xs={12} md={12}>
            {/* <Button
              size="small"
              sx={{
                maxWidth: "90px",
                backgroundColor: "#f1f1f1",
                color: "#000",
                "&.MuiButtonBase-root": {
                  borderRadius: "0",
                },
              }}
              onClick={onClickInput}
            >
              파일 첨부
            </Button> */}
            {console.log(fileInput)}
          </Grid>
          <Grid item xs={12} md={12}>
            <EmptySpace height="30px" />
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Button
                size="small"
                sx={{
                  backgroundColor: "#212121",
                  color: "#fff",
                  "&.MuiButtonBase-root": {
                    borderRadius: "0",
                  },
                  "&:hover": {
                    backgroundColor: "#212121",
                  },
                }}
                fullWidth={false}
                onClick={async () => {
                  const t = title;
                  const contents = editorRef.current
                    .getInstance()
                    .getMarkdown();

                  const contents_en = editorRef_en?.current
                    ?.getInstance?.()
                    .getMarkdown();

                  const category = categ;

                  if (!!!t && !!!title_en) {
                    alert("한/영중 한가지를 입력해 주세요");
                    return;
                  }
                  if (!!!contents && !!!contents_en) {
                    alert("한/영중 한가지를 입력해 주세요");
                    return;
                  }
                  if (!!!category && !!!categ_en) {
                    alert("한/영중 한가지를 입력해 주세요");
                    return;
                  }
                  setLoading(true);
                  const file = await uploadFiles(files);
                  console.log("files", files);
                  setLoading(false);

                  await onSave(
                    t,
                    contents,
                    category,
                    title_en,
                    categ_en,
                    contents_en ?? null,
                    file,
                    created
                  );
                  setLoading(false);
                  alert("등록이 완료 되었습니다");
                  history.push("/adminmain");
                }}
              >
                등록하기
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default AdminMain;

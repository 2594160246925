import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CountUp from "react-countup";
import AnimatedNumbers from "react-animated-numbers";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EmptySpace, BasicAppBar } from "component/components";
import Divider from "@mui/material/Divider";
import { app, db } from "firebaseConfig";
import { Viewer } from "@toast-ui/react-editor";
import "prismjs/themes/prism.css";
import codeSyntaxHighlight from "@toast-ui/editor-plugin-code-syntax-highlight";
import "@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css";
import Prism from "prismjs";
import Link from "@mui/material/Link";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import usePagination from "@mui/material/usePagination";

import {
  getStorage,
  ref,
  deleteObject,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  deleteDoc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  where,
  getDocs,
  startAt,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import axios from "axios";
import fileDownload from "js-file-download";

//careers
import meeting1 from "asset/careers/meeting1.jpeg";
import meeting2 from "asset/careers/meeting2.jpeg";
import office9 from "asset/careers/office9.jpeg";
import office8 from "asset/careers/office8.jpeg";
import office10 from "asset/careers/office10.jpeg";
import test3 from "asset/careers/test3.jpeg";
import test4 from "asset/careers/test4.jpeg";
import test5 from "asset/careers/test5.jpeg";
import test6 from "asset/careers/test6.jpeg";
import test7 from "asset/careers/test7.jpeg";
import work8 from "asset/careers/work8.jpeg";
import work9 from "asset/careers/work9.jpeg";
import work10 from "asset/careers/work10.jpeg";
import nameCard from "asset/careers/new.jpeg";

import new1 from "asset/careers/new1.jpeg";
import new2 from "asset/careers/new2.jpeg";
import new3 from "asset/careers/new3.jpeg";

import new4 from "asset/careers/new4.jpeg";
import new5 from "asset/careers/new5.jpeg";
import new6 from "asset/careers/new6.jpeg";

import pdf from "asset/company/pdf.png";
import GoogleMapReact from "google-map-react";
import { GoogleMap, Marker as Marker1 } from "react-google-maps";
import { borders } from "@mui/system";

import styles from "styles/style";
import i18n from "i18n";
import "./main.css";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

import "./iamport.cm";
import { maxWidth } from "@mui/system";
import { BorderTop } from "@mui/icons-material";
function Company(props) {
  const { t } = useTranslation();
  const [careerPage, setCareerPage] = React.useState(1);
  const careerChange = (e, number) => {
    setCareerPage(number);
  };
  const itemsPerPage = 10;
  const [lang, setLang] = React.useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "en"
  );

  React.useEffect(() => {
    console.log("렌더를 어떻게 유발 시키지", lang);
    if (lang === "ko") {
      i18n.changeLanguage("ko");
    } else {
      i18n.changeLanguage("en");
    }
  }, [lang]);
  const matches = useMediaQuery("(min-width:900px)");
  const [hover, setHover] = React.useState(false);
  const fixedBoxRef = React.useRef();
  const textBoxRef = React.useRef();
  const lastTextRef = React.useRef();

  const boxRef = React.useRef();

  const [refState, setRefState] = React.useState(null);
  const [textRefState, setTextRefState] = React.useState(null);
  const [lastTextRefState, setLastTextRefState] = React.useState(null);

  const [boxRefState, setBoxRefState] = React.useState(null);

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const containerBottom =
    refState && refState.current.offsetTop + refState.current.offsetHeight;
  const containerTop = refState && refState.current.offsetTop;

  const containerHeight = refState && refState.current.offsetHeight;
  const fixedTop = refState && refState.current.offsetTop;
  const lastTextHeight =
    lastTextRefState && lastTextRefState.current.offsetHeight;
  const lastTextBottom =
    lastTextRefState &&
    lastTextRefState.current.offsetTop +
      lastTextRefState.current.offsetHeight +
      containerTop +
      lastTextHeight;

  const boxSize = boxRefState && boxRef.current.offsetHeight;
  const bottom = containerBottom - boxSize;
  const fixedBottom = refState && bottom;
  const fixedRange = bottom > scrollPosition && scrollPosition > fixedTop;

  const textHeight = textRefState && textRefState.current.offsetHeight;
  async function getFirebaseDoc(table) {
    try {
      const querySnapshot = await getDocs(collection(db, table));

      const posts = querySnapshot.docs.map((v) => {
        console.log(v.id);
        return { id: v.id, data: v.data() };
      });
      return { success: true, posts };
    } catch (e) {
      console.log(e);
      return { success: false, msg: e };
    }
  }
  function MainLikeImg({ height, img }) {
    return (
      <div
        style={{
          position: "relative",
          height: height,
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          display: "block",
          minWidth: "100%",
          lineHeight: "0",
          height: height,
          backgroundColor: "#fff",
        }}
      >
        <img
          style={{
            minWidth: "100%",
            margin: "0",
            position: "absolute",
            top: "50%",
            left: "50%",
            "-ms-transform": " translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            lineHeight: "0",
            backgroundColor: "#fff",
          }}
          src={img}
          alt=""
        ></img>
      </div>
    );
  }

  // React.useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   if (fixedBoxRef.current) {
  //     setRefState(fixedBoxRef);
  //   }
  //   if (textBoxRef.current) {
  //     setTextRefState(textBoxRef);
  //   }
  //   if (lastTextRef.current) {
  //     setLastTextRefState(lastTextRef);
  //   }
  //   if (boxRef.current) {
  //     setBoxRefState(boxRef);
  //   }
  // }, [fixedBoxRef]);

  function HoverScalingImg({ aspectRatio, img }) {
    return (
      <FadeInSection>
        <Box
          style={{
            overflow: "hidden",
            width: "100%",
            position: "relative",
            aspectRatio: aspectRatio,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            display: "inline-block",

            overflow: "hidden",
          }}
        >
          <Box
            style={{
              overflow: "hidden",
              width: "100%",
              position: "relative",
              height: "100%",

              aspectRatio: aspectRatio,
              backgroundImage: `url(${img})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
              display: "block",
            }}
            className="zoom"
          ></Box>
        </Box>
      </FadeInSection>
    );
  }

  function ImgKeepHeight({ src }) {
    return (
      <div
        style={
          matches
            ? styles.imgKeepLengthBox.lgtheme
            : styles.imgKeepLengthBox.smtheme
        }
      >
        <img
          style={
            matches
              ? styles.imgKeepLength.lgtheme
              : styles.imgKeepLength.smtheme
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgWidthFit({ src, height, minHeight }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgWidthFitBox.lgtheme, height: height ?? "" }
            : {
                ...styles.imgWidthFitBox.smtheme,
                height: height ?? "",
                minHeight,
                lineHeight: "0",
              }
        }
      >
        <img
          style={
            matches
              ? { ...styles.imgWidthFit.lgtheme, height: height ?? "" }
              : {
                  ...styles.imgWidthFit.smtheme,
                  height: height ?? "",
                  minHeight,
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function ImgScaleFit({ src, height }) {
    return (
      <div
        style={
          matches
            ? { ...styles.imgScaleFitBox.lgtheme, height, overflow: "hidden" }
            : {
                ...styles.imgScaleFitBox.smtheme,
                maxHeight: height,
                overflow: "hidden",
              }
        }
      >
        <img
          style={
            matches
              ? {
                  ...styles.imgScaleFit.lgtheme,
                  minHeight: height,
                  overflow: "hidden",
                }
              : {
                  ...styles.imgScaleFit.smtheme,
                  maxHeight: height,
                  overflow: "hidden",
                }
          }
          src={src}
          alt=""
        ></img>
      </div>
    );
  }
  function CustomContainer(props) {
    return (
      <Container
        maxWidth={false}
        disableGutters={true}
        sx={{
          maxWidth: "1294px",
          padding: matches ? "0 5px" : "0 0px",
        }}
      >
        {props.children}
      </Container>
    );
  }
  function PaddingBox({ children }) {
    return (
      <Box sx={matches ? styles.paddingBox.lgtheme : styles.paddingBox.smtheme}>
        {children}
      </Box>
    );
  }
  function CustomColorContainer(props) {
    return (
      <div style={{ backgroundColor: "#1A1E24" }}>
        <Container
          disableGutters={true}
          maxWidth={false}
          sx={{
            maxWidth: "1294px",
            padding: matches ? "0 5px" : "0 0px",
            backgroundColor: "#1A1E24",
          }}
        >
          {props.children}
        </Container>
      </div>
    );
  }
  function PaddingColorBox({ children }) {
    return (
      <Box
        sx={
          matches
            ? { ...styles.paddingBox.lgtheme, backgroundColor: "#1a1e24" }
            : { ...styles.paddingBox.smtheme, backgroundColor: "#1a1e24" }
        }
      >
        {children}
      </Box>
    );
  }
  const List = styled("ul")({
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  });
  const [isVisible, setVisible] = React.useState(false);
  function handleClick() {
    setVisible((v) => !v);
  }

  function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();

    React.useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
          setVisible(entry.isIntersecting);
        });
      });

      const { current } = domRef;
      observer.observe(current);

      return () => observer.unobserve(current);
    }, []);
    return (
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        {props.children}
      </div>
    );
  }

  function DaumMap() {
    return (
      <Map
        center={{ lat: 33.5563, lng: 126.79581 }}
        style={{ width: "100%", height: "360px" }}
      >
        <MapMarker position={{ lat: 33.55635, lng: 126.795841 }}>
          <div style={{ color: "#000" }}>Hello World!</div>
        </MapMarker>
      </Map>
    );
  }
  const Marker = ({ children }) => children;

  function GoogleMap({ height = "435px" }) {
    const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: 37.400909, lng: 127.112158 },
        map,
        title: "Hideep",
      });
      return marker;
    };

    return (
      <Box sx={{ maxHeight: height, width: "100%", aspectRatio: "1.9 / 1" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyA1a7XuP92YSphmUR0yBifVHQcuMnp9dmU",
          }}
          defaultCenter={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          styles={""}
          defaultZoom={14}
          distanceToMouse={() => {}}
          // options={defaultMapOptions}
          center={{
            lat: 37.400909,
            lng: 127.112158,
          }}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <Marker lat={37.400909} lng={127.112158}>
            <div
              className="cluster-marker"
              style={{
                // width: `${10 + (pointCount / points.length) * 20}px`,
                width: `${40}px`,
                // height: `${10 + (pointCount / points.length) * 20}px`
                height: `${40}px`,
                color: "black",
              }}
            >
              Hideep
            </div>
          </Marker>
        </GoogleMapReact>
      </Box>
    );
  }
  function getFileName(path) {
    const storage = getStorage(app);
    const httpsReference = ref(storage, path);
    console.log(httpsReference);
    console.log("파일", httpsReference.name);
    return httpsReference.name;
    // getMetadata(httpsReference).then((meta) => meta.name);
  }
  async function download(path) {
    const storage = getStorage(app);
    try {
      console.log("시작");
      const httpsReference = ref(storage, path);
      console.log(ref);
      const metadata = await getMetadata(httpsReference);
      const fileName = metadata.name;
      console.log("ff", fileName);
      const url = await getDownloadURL(httpsReference);

      console.log(url);
      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      handleDownload(url, fileName);
      return;
    } catch (e) {
      alert(e.message);
      return;
    }
  }
  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        console.log(res);
        fileDownload(res.data, filename);
      });
  };

  const CustomizedAccordion = styled(Accordion)(() => ({
    "& .MuiPaper-root": {
      color: "darkslategray",
      backgroundColor: "#E4FAF4",
      borderRadius: "0.6rem",
      boxShadow: "none",
    },
    paddingBottom: "1.6rem",
    boxShadow: "none",
  }));

  function match(lg, sm) {
    return matches ? lg : sm;
  }
  function snl() {
    return match("", "\n");
  }
  function onlySm(view) {
    return matches ? "" : <> {view}</>;
  }
  function onlyLg(view) {
    return matches ? <> {view} </> : "";
  }

  const welfare = [
    {
      name: t(`PS (Profit Sharing) / PI (Productivity Incentive)`),
      content: `일정 기준에 따라 회사의 이익을\n직원들과 공유합니다.`,
    },
    {
      name: t(`전 임직원에게 스톡 혹은 옵션 지급`),
      content: `전 임직원에게 스톡 혹은 옵션을 지급하여\n모두가 하이딥의 주인으로 일을 합니다.`,
    },
    {
      name: t(`내부 보상제도 운영`),
      content: `인재 추천, 직무 발명 등 \n각종 내부 보상제도를 운영합니다.`,
    },
    {
      name: t(`공로상 & 열정상`),
      content: `공로상 & 열정상 등 업무에 열정적인 분들을\n매년 선정하여 보상합니다.`,
    },

    {
      name: t(`자율 출퇴근제`),
      content: `자율 출퇴근제로 각자의 사정에 따라\n업무 시작 시간 조정이 가능합니다.`,
    },
    {
      name: t(`장기 근속 임직원 포상제도 실시`),
      content: `장기 근속 임직원에게 포상을\n제공하여 함께 성장합니다. `,
    },
    {
      name: t(`컴퓨터 환경 제공`),
      content: `일하기 편리하도록 원하는 사양의\n컴퓨터 환경을 제공합니다. (Mac 포함)`,
    },
    {
      name: t(`매년 종합검진 지원`),
      content: `100만원 상당의 종합검진을 매년 실시하여\n건강하게 일을 할 수 있도록 지원합니다.`,
    },
    {
      name: t(`넓고 쾌적한 사무환경`),
      content: `판교에 환하고 넓고 쾌적한 사무 환경에서\n일을 합니다. (초록 나무들과 다량의 공기청정기 설치)`,
    },
    {
      name: t(`도보 10분 거리 기숙사 제공`),
      content: `판교 내 도보 10분 거리의 신규 아파트를\n기숙사로 제공합니다.`,
    },

    {
      name: t(`휘트니스 센터 비용 지원`),
      content: `임직원들의 건강을 위하여\n회사 건물 내 휘트니스 센터 이용 비용의 50%를 지원합니다.`,
    },
    {
      name: t(`주차장 비용 지원`),
      content: `회사 인근 주차장 이용 시 한도 제한 없이 \n총비용의 50% 지원합니다.`,
    },

    {
      name: t(`시간 외 실비 제공`),
      content: `시간 외 실비 (식사 & 교통비) 별도 지급합니다.`,
    },
    {
      name: t(`경조사 휴가 제공`),
      content: `결혼 (5일), 회갑 등 경조사 휴가 제공 및\n유연한 연차 제도를 운영합니다.`,
    },
    {
      name: t(`콘도 회원가 이용`),
      content: `쏠비치, 소노펠리체, 휘닉스 아일랜드 등 (대명 및 휘닉스 계열 콘도)\n회원가로 이용할 수 있습니다.`,
    },

    {
      name: t(`축구 동호회 활동 지원`),
      content: `건강하고 즐거운 회사 생활을 위해\n축구 동호회 활동을 지원합니다.`,
    },
    {
      name: t(`하이딥 IC 탑재 제품 구매 지원`),
      content: `때때로 하이딥 IC가 탑재되는 \n제품의 구매를 지원합니다.`,
    },
    {
      name: t(`건강하고 맛있는 간식`),
      content: `건강하고 맛있는 간식과 음료가\n상시 제공됩니다.`,
    },
    {
      name: t(`청년내일채움공제 가입 및 기업부담금 지원`),
      content: `하이딥 임직원의 미래 설계에 도움을 드리기 위해\n청년내일채움공제 가입 및 기업부담금을 지원합니다.`,
    },
  ];
  const welfaresm = [
    {
      name: t(`PS (Profit Sharing) / PI (Productivity Incentive)`),
      content: `일정 기준에 따라 회사의 이익을\n직원들과 공유합니다.`,
    },
    {
      name: t(`전 임직원에게 스톡 혹은 옵션 지급`),
      content: `전 임직원에게 스톡 혹은 옵션을 지급하여\n모두가 하이딥의 주인으로 일을 합니다.`,
    },
    {
      name: t(`내부 보상제도 운영`),
      content: `인재 추천, 직무 발명 등 각종 내부 보상제도를 운영합니다.`,
    },
    {
      name: t(`공로상 & 열정상`),
      content: `공로상 & 열정상 등 업무에 열정적인 분들을\n매년 선정하여 보상합니다.`,
    },

    {
      name: t(`자율 출퇴근제`),
      content: `자율 출퇴근제로 각자의 사정에 따라 업무 시작 시간 조정이 가능합니다.`,
    },
    {
      name: t(`장기 근속 임직원 포상제도 실시`),
      content: `장기 근속 임직원에게 포상을 제공하여 함께 성장합니다.`,
    },
    {
      name: t(`컴퓨터 환경 제공`),
      content: `일하기 편리하도록 원하는 사양의 컴퓨터 환경을 제공합니다. (Mac 포함)`,
    },
    {
      name: t(`매년 종합검진 지원`),
      content: `100만원 상당의 종합검진을 매년 실시하여\n건강하게 일을 할 수 있도록 지원합니다.`,
    },
    {
      name: t(`넓고 쾌적한 사무환경`),
      content: `판교에 환하고 넓고 쾌적한 사무 환경에서\n일을 합니다. (초록 나무들과 다량의 공기청정기 설치)`,
    },
    {
      name: t(`도보 10분 거리 기숙사 제공`),
      content: `판교 내 도보 10분 거리의 신규 아파트를 기숙사로 제공합니다.`,
    },

    {
      name: t(`휘트니스 센터 비용 지원`),
      content: `임직원들의 건강을 위하여 회사 건물 내\n휘트니스 센터 이용 비용의 50%를 지원합니다.`,
    },
    {
      name: t(`주차장 비용 지원`),
      content: `회사 인근 주차장 이용 시 한도 제한 없이 총비용의 50% 지원합니다.`,
    },

    {
      name: t(`시간 외 실비 제공`),
      content: `시간 외 실비 (식사 & 교통비) 별도 지급합니다.`,
    },
    {
      name: t(`경조사 휴가 제공`),
      content: `결혼 (5일), 회갑 등 경조사 휴가 제공 및 유연한 연차 제도를 운영합니다.`,
    },
    {
      name: t(`콘도 회원가 이용`),
      content: `쏠비치, 소노펠리체, 휘닉스 아일랜드 등 (대명 및 휘닉스 계열 콘도)\n회원가로 이용할 수 있습니다.`,
    },

    {
      name: t(`축구 동호회 활동 지원`),
      content: `건강하고 즐거운 회사 생활을 위해 축구 동호회 활동을 지원합니다.`,
    },
    {
      name: t(`하이딥 IC 탑재 제품 구매 지원`),
      content: `때때로 하이딥 IC가 탑재되는 제품의 구매를 지원합니다.`,
    },
    {
      name: t(`건강하고 맛있는 간식`),
      content: `건강하고 맛있는 간식과 음료가 상시 제공됩니다.`,
    },
    {
      name: t(`청년내일채움공제 가입 및 기업부담금 지원`),
      content: `하이딥 임직원의 미래 설계에 도움을 드리기 위해\n청년내일채움공제 가입 및 기업부담금을 지원합니다.`,
    },
  ];
  const [career, setCareer] = React.useState(null);

  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    (async () => {
      await init();
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (refresh) {
        await init();
        setRefresh(false);
      }
    })();
  }, [refresh]);

  async function init() {
    const car = await getFirebaseDoc("career");
    setCareer(car.posts);
    console.log([car.posts]);
  }

  const { items } = usePagination({
    count: Math.ceil(career?.length / itemsPerPage),
    onChange: careerChange,
  });

  function LargePage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        <BasicAppBar />
        <EmptySpace height={matches ? "248px" : "83px"} />
        {/* <ImgWidthFit src={hi} /> */}
        {/* <ImgScaleFit src={connect} /> */}
        <CustomContainer>
          <PaddingBox>
            <Typography variant="careerh1">
              {t(`하이딥의 불꽃같은 여정에\n당신을 초대합니다.`)}
            </Typography>
            <EmptySpace height={matches ? "36px" : "83px"} />

            <Typography variant="careerh1">
              {t(`언제나 근본적인 '어떻게?' 를\n찾아가는 하이딥은`)}
            </Typography>
            <EmptySpace height={matches ? "36px" : "83px"} />

            <Typography variant="careerh1">
              {t(`함께하는 사람들의 가치를\n가장 중요하게 생각합니다.`)}
            </Typography>
            <EmptySpace height={matches ? "100px" : "36px"} />

            {/* <Typography variant="h4Alias">
          {t(`"
여러 사람들의 다양한 관점과 인사이트를 통해
새로운 방법을 찾는 짜릿한 여정에 당신을 초대합니다.
`)}
        </Typography> */}
          </PaddingBox>
        </CustomContainer>
        <Box sx={{}}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <ImgWidthFit src={meeting1} />
            </Grid>
            <Grid item xs={6} md={6}>
              <ImgWidthFit src={meeting2} />
            </Grid>
          </Grid>
        </Box>
        <EmptySpace height={matches ? "210px" : "36px"} />
        <Box sx={{}}>
          <CustomContainer>
            <Grid
              container
              rowSpacing={0.325}
              columnSpacing={{ xs: 0, sm: 0, md: 2 }}
            >
              <Grid item xs={6} md={6}>
                <HoverScalingImg aspectRatio="0.97277" img={test3} />
              </Grid>
              <Grid item xs={6} md={6}>
                <HoverScalingImg aspectRatio="0.97277" img={test4} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="careerQuote">
                    <Typography
                      variant="careerQuote"
                      sx={{ lineHeight: "0.5" }}
                    >
                      "
                    </Typography>
                    {t(
                      `누구나 이미 알고 있다고\n모두가 방법을 찾을 수는 없습니다.\n길이 보이지 않는다면 \n내딛는 첫 걸음으로 길이 열립니다.`
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.3" img={test7} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.805" img={test6} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.805" img={test5} />
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>
        <EmptySpace height={matches ? "210px" : "110px"} />
        <Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
          <Grid sm={12}>
            <Box
              style={{
                overflow: "hidden",
                width: "100%",
                position: "relative",

                aspectRatio: "4.25534",
                backgroundImage: `url(${office8})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            ></Box>
          </Grid>
        </Grid>
        {/* 하이딥 주요 복지  */}
        <CustomColorContainer>
          <PaddingColorBox>
            <EmptySpace height={matches ? "200px" : "110px"} />
            <FadeInSection>
              <Typography variant="careerWhiteTitle">
                {t("하이딥 주요 복지")}
              </Typography>
            </FadeInSection>
            <EmptySpace height={matches ? "110px" : "110px"} />

            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 0, sm: 0, md: 2 }}
            >
              {welfare.map((v) => {
                return (
                  <Grid md={4}>
                    <Box>
                      {" "}
                      <Divider
                        sx={{
                          margin: "12px 12px",
                          backgroundColor: "#fff",
                          opacity: "1",
                        }}
                      />
                      <EmptySpace height={matches ? "45px" : "110px"} />
                      <FadeInSection>
                        <Typography
                          sx={{ padding: "0 12px" }}
                          variant="careerWhiteName1"
                        >
                          {t(v.name)}
                        </Typography>
                      </FadeInSection>
                      <EmptySpace height={matches ? "25px" : "110px"} />
                      <Typography
                        variant="carrerGray1"
                        sx={{ padding: "0 12px" }}
                      >
                        {t(v.content)}
                      </Typography>
                      <EmptySpace height={matches ? "40px" : "0px"} />
                    </Box>
                  </Grid>
                );
              })}
              <Grid md={4}>
                <Box>
                  {" "}
                  <Divider
                    sx={{
                      margin: "12px 12px",
                      backgroundColor: "#fff",
                      opacity: "1",
                    }}
                  />
                </Box>
              </Grid>
              <Grid md={4}>
                <Box>
                  <Divider
                    sx={{
                      margin: "12px 12px",
                      backgroundColor: "#fff",
                      opacity: "1",
                    }}
                  />
                </Box>
              </Grid>
              <Grid md={4}>
                <Box>
                  {" "}
                  <Divider
                    sx={{
                      margin: "12px 12px",
                      backgroundColor: "#fff",
                      opacity: "1",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <EmptySpace height={matches ? "110px" : "38px"} />
          </PaddingColorBox>
        </CustomColorContainer>
        {/* 워크  */}
        <EmptySpace height={matches ? "210px" : "110px"} />
        <Box sx={{}}>
          <CustomContainer>
            <Grid container>
              <Grid xs={7} md={7} direction={"column"}>
                {/* <ImgWidthFit src={test3} /> */}
                <Grid item xs={12} md={12} sx={{ mr: 2 }}>
                  <HoverScalingImg aspectRatio="2.3809" img={work8} />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "start",
                      flexDirection: "column",
                      aspectRatio: "1.747",
                    }}
                  >
                    <Typography
                      variant="careerQuote"
                      sx={{ textAlign: "left" }}
                    >
                      <Typography
                        variant="careerQuote"
                        sx={{ lineHeight: "0.5", textAlign: "left" }}
                      >
                        "
                      </Typography>
                      {t(
                        `여러 사람들의 다양한 관점과 인사이트를 통해\n새로운 방법을 찾는 짜릿한 여정에 당신을 초대합니다.`
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} sx={{ mr: 2 }}>
                  <HoverScalingImg aspectRatio="1.722" img={work10} />
                </Grid>
              </Grid>
              <Grid xs={5} md={5} direction="column">
                <Grid item xs={3} md={4}>
                  <HoverScalingImg aspectRatio="1.2529" img={nameCard} />
                </Grid>
                <Grid item xs={3} md={8}>
                  {/* 의자 */}
                  <HoverScalingImg aspectRatio="0.722" img={work9} />
                </Grid>
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>
        <EmptySpace height={matches ? "220px" : "110px"} />
        <Box sx={{}}>
          <CustomContainer>
            <Grid
              container
              rowSpacing={0}
              columnSpacing={{ xs: 0, sm: 0, md: 2 }}
            >
              <Grid item xs={12} md={6}>
                <FadeInSection>
                  <HoverScalingImg aspectRatio="1.634" img={new1} />
                </FadeInSection>
              </Grid>
              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.634" img={new2} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    height: "100%",
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: "column",
                  }}
                >
                  <Typography variant="careerQuote" sx={{ textAlign: "left" }}>
                    <Typography
                      variant="careerQuote"
                      sx={{ lineHeight: "0.5", textAlign: "left" }}
                    >
                      "
                    </Typography>
                    {t(
                      `하루의 에너지를 충전할 수 있고, 편리하고 쾌적한\n공간은 집중력 있는 업무에 도움이 됩니다.\n하이딥에서는 누구나 자유롭게 아이디어를 나누고\n빠르게 실행하는 문화를 만들어 갑니다.`
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.50" img={new3} />
              </Grid>
              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.634" img={new4} />
              </Grid>

              <Grid item xs={12} md={6}>
                <HoverScalingImg aspectRatio="1.634" img={new5} />
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>

        <EmptySpace height={matches ? "210px" : "110px"} />
        {/* 마지막 이미지 */}
        {/* <div
          style={{
            position: "relative",
            minHeight: "890px",
            textAlign: "center",
            overflow: "hidden",
            boxSizing: "border-box",
            display: "block",
            minWidth: "100%",
            height: "100%",
          }}
        >
          <img
            style={{
              minWidth: "100%",
              margin: "0",
              height: "100%",
              minHeight: "100%",
              position: "absolute",
              top: "50%",
              left: "50%",
              msTransform: " translate(-50%, -50%)",
              transform: "translate(-50%, -50%)",
            }}
            src={new6}
            alt=""
          ></img>
        </div> */}
        <MainLikeImg height="890px" img={new6} />
        {/* 채용 정보 */}
        <CustomColorContainer>
          <PaddingColorBox>
            <EmptySpace height={matches ? "200px" : "60px"} />

            <Box sx={{}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FadeInSection>
                    <EmptySpace height={matches ? "0px" : "60px"} />

                    <Typography variant={"careerWhiteTitle"}>
                      {t("채용 정보")}
                    </Typography>
                  </FadeInSection>
                  <EmptySpace height={matches ? "0px" : "40px"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box>
                    {career &&
                      career
                        .slice(0)
                        .reverse()
                        .map((v, i) => {
                          if (lang === "ko" && !v.data.title) {
                            return <></>;
                          }
                          if (lang === "en" && !v.data.title_en) {
                            return <></>;
                          }
                          return (
                            <>
                              {itemsPerPage * careerPage >= i + 1 &&
                                itemsPerPage * careerPage - itemsPerPage <
                                  i + 1 && (
                                  <Accordion
                                    disableGutters={true}
                                    elevation={0}
                                    square={true}
                                    sx={{
                                      backgroundColor: "#1A1E24",
                                      "&.MuiAccordion-root": {
                                        borderTop: "1px solid #fff",
                                        borderBottom:
                                          i === career.length - 1 ||
                                          (i + 1) % 10 === 0
                                            ? "1px solid #fff"
                                            : "0px solid #fff",
                                      },
                                      "&.MuiAccordionSummary": {
                                        padding: "0",
                                        margin: "0",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      disableGutters={true}
                                      elevation={0}
                                      square={true}
                                      sx={{
                                        padding: "30px 0px ",
                                        margin: "0",

                                        "& .MuiAccordionSummary-content": {
                                          padding: "0",
                                          margin: "0",
                                          lineHeight: 0,
                                        },
                                      }}
                                      expandIcon={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontWeight: "bold",
                                            margin: "0",
                                            color: "#fff",
                                          }}
                                        >
                                          ∨
                                        </Typography>
                                      }
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <Typography
                                        sx={
                                          matches
                                            ? {
                                                padding: "0",
                                                margin: "0",
                                                fontWeight: "bold",
                                                color: "#c6c6c6",
                                                fontSize: "18px",
                                                lineHeight: 1,
                                              }
                                            : {
                                                letterSpacing: "-1px",
                                                fontWeight: "700",
                                                color: "#c6c6c6",
                                                fontSize: "13px",
                                                padding: "0",
                                              }
                                        }
                                      >
                                        {lang !== "ko" && v.data?.category_en
                                          ? v.data.category_en
                                          : v.data.category}
                                      </Typography>
                                      &nbsp; &nbsp;
                                      <Typography
                                        sx={
                                          matches
                                            ? {
                                                fontWeight: "bold",
                                                color: "#fff",
                                                fontSize: "18px",
                                                lineHeight: 1,
                                              }
                                            : {
                                                letterSpacing: "-1px",
                                                fontWeight: "700",
                                                color: "#fff",
                                                fontSize: "13px",
                                                padding: "5px 0px",
                                              }
                                        }
                                      >
                                        {lang !== "ko" && v.data?.title_en
                                          ? v.data.title_en
                                          : v.data.title}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={
                                        matches
                                          ? {
                                              padding: "30px 0px",
                                              fontWeight: "normal",
                                            }
                                          : {}
                                      }
                                    >
                                      <Viewer
                                        initialValue={
                                          lang !== "ko" && v.data?.contents_en
                                            ? v.data.contents_en
                                            : v.data.contents
                                        }
                                        plugins={[
                                          [
                                            codeSyntaxHighlight,
                                            { highlighter: Prism },
                                          ],
                                        ]}
                                        customHTMLRenderer={{
                                          htmlBlock: {
                                            iframe(node) {
                                              return [
                                                {
                                                  type: "openTag",
                                                  tagName: "iframe",
                                                  outerNewLine: true,
                                                  attributes: node.attrs,
                                                },
                                                {
                                                  type: "html",
                                                  content: node.childrenHTML,
                                                },
                                                {
                                                  type: "closeTag",
                                                  tagName: "iframe",
                                                  outerNewLine: false,
                                                },
                                              ];
                                            },
                                          },
                                        }}
                                      />
                                      {v.data.files &&
                                        v.data.files.map((f) => (
                                          <>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                color={"#fff"}
                                                underline="none"
                                                download
                                                component="button"
                                                onClick={() => {
                                                  download(f);
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <AttachFileIcon
                                                    sx={{ mr: 4 }}
                                                  />{" "}
                                                  <Typography
                                                    sx={{
                                                      fontWeight: "700",
                                                      fontSize: "15px",
                                                      marginRight: "15px",
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    다운로드
                                                  </Typography>
                                                  <Typography
                                                    sx={{
                                                      fontSize: "15px",
                                                      marginRight: "15px",
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    |{" "}
                                                  </Typography>
                                                  {getFileName(f)}
                                                </Box>
                                              </Link>
                                            </Box>
                                          </>
                                        ))}
                                    </AccordionDetails>
                                  </Accordion>
                                )}
                            </>
                          );
                        })}
                  </Box>
                  <Box>
                    <EmptySpace height={"20px"} />
                    {career?.length > 10 && (
                      <nav style={{ marginLeft: "-6px" }}>
                        <List>
                          {items.map(
                            (
                              { page, type, selected, disabled, ...item },
                              index
                            ) => {
                              let children = null;

                              if (
                                type === "start-ellipsis" ||
                                type === "end-ellipsis"
                              ) {
                                children = "…";
                              } else if (type === "page") {
                                children = (
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        border: "0px solid white",
                                        background: "none",
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                        textDecoration: selected
                                          ? "underline"
                                          : undefined,
                                        textDecorationThickness: "1px",
                                        textUnderlinePosition: "under",
                                        fontSize: "15px",
                                        color: selected ? "#fff" : "#5f5f5f",
                                      }}
                                      {...item}
                                    >
                                      {page}
                                    </button>
                                  </div>
                                );
                              } else {
                                if (type === "previous") {
                                  children = (
                                    <button
                                      type="button"
                                      style={{
                                        display: disabled ? "none" : undefined,
                                        border: "0px solid white",
                                        background: "none",
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                        textDecoration: selected
                                          ? "underline"
                                          : undefined,
                                        textDecorationThickness: "1px",
                                        textUnderlinePosition: "under",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        color: "#fff",
                                      }}
                                      {...item}
                                    >
                                      {"<"}
                                    </button>
                                  );
                                } else if (type === "next") {
                                  children = (
                                    <button
                                      type="button"
                                      style={{
                                        display: disabled ? "none" : undefined,
                                        border: "0px solid white",
                                        background: "none",
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                        textDecoration: selected
                                          ? "underline"
                                          : undefined,
                                        textDecorationThickness: "1px",
                                        textUnderlinePosition: "under",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        color: "#fff",
                                      }}
                                      {...item}
                                    >
                                      {">"}
                                    </button>
                                  );
                                } else {
                                  children = (
                                    <button type="button" {...item}>
                                      {type}
                                    </button>
                                  );
                                }
                              }

                              return <li key={index}>{children}</li>;
                            }
                          )}
                        </List>
                      </nav>
                    )}
                  </Box>
                </Grid>
              </Grid>

              {/* 라인 */}
              {/* 아코디언 */}
            </Box>
          </PaddingColorBox>
        </CustomColorContainer>
        <CustomColorContainer>
          <PaddingColorBox>
            <EmptySpace height={matches ? "100px" : "60px"} />

            <Box sx={{}}>
              <Grid container spacing={2}>
                {/* 지원방법 */}
                <Grid item xs={12} md={3}>
                  <FadeInSection>
                    <EmptySpace height={matches ? "0px" : "60px"} />

                    <Typography variant={"careerWhiteTitle"}>
                      {t("지원 방법")}
                    </Typography>
                  </FadeInSection>
                  <EmptySpace height={matches ? "0px" : "40px"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant={"carrerGray"} sx={{ color: "#fff" }}>
                    {t(
                      `궁금한 내용이 있으시면 편하게 연락 주세요.\n담당 \u00a0  | \u00a0 김경영 부장\n전화  \u00a0 | \u00a0 070-4164-7036\n이메일  \u00a0 | \u00a0 mariana.kim@hideep.com`
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* 라인 */}
              {/* 아코디언 */}
            </Box>
          </PaddingColorBox>
          <EmptySpace height={matches ? "200px" : "60px"} />
        </CustomColorContainer>
      </React.Fragment>
    );
  }
  function SmallPage() {
    return (
      <React.Fragment>
        {/* 빈공간 */}
        {/* 스티키 헤더 */}
        <BasicAppBar />
        <EmptySpace height={matches ? "300px" : "89px"} />
        {/* <ImgWidthFit src={hi} /> */}
        {/* <ImgScaleFit src={connect} /> */}
        <Box sx={{}}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <ImgWidthFit src={meeting1} />
            </Grid>
            <Grid item xs={6} md={6}>
              <ImgWidthFit src={meeting2} />
            </Grid>
          </Grid>
        </Box>
        <EmptySpace height={matches ? "200px" : "30px"} />
        <CustomContainer>
          <PaddingBox>
            <Typography
              style={{
                lineHeight: 1.52,
                whiteSpace: "pre-line",
                fontSize: "16px",
                letterSpacing: "-1px",
                color: "#1a1e24",
                borderColor: "#e5e7eb",
              }}
            >
              {t(
                `하이딥의 불꽃같은 여정에 당신을 초대합니다.\n언제나 근본적인 '어떻게?' 를 찾아가는 하이딥은\n함께하는 사람들의 가치를 가장 중요하게 생각합니다.`
              )}
            </Typography>
            <EmptySpace height={matches ? "100px" : "90px"} />

            <Typography variant="careerQuote">
              <Typography variant="careerQuote"></Typography>"
              {t(
                `\n누구나 이미 알고 있다고\n모두가 방법을 찾을 수는 없습니다.\n길이 보이지 않는다면\n내딛는 첫 걸음으로 길이 열립니다.`
              )}
            </Typography>
          </PaddingBox>
        </CustomContainer>
        <EmptySpace height={matches ? "200px" : "30px"} />
        <Box sx={{}}>
          <CustomContainer>
            <Grid
              container
              rowSpacing={0.625}
              columnSpacing={{ xs: 0, sm: 0, md: 0 }}
            >
              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",
                    aspectRatio: "1.88 / 1",

                    backgroundImage: `url(${test3})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                    padding: "0",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",
                    aspectRatio: "1.88 / 1",

                    backgroundImage: `url(${test4})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",
                    aspectRatio: "1.88 / 1",

                    backgroundImage: `url(${test6})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.88 / 1",
                    backgroundImage: `url(${test5})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>
        <EmptySpace height={matches ? "200px" : "110px"} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100vw",
          }}
        >
          <Box
            style={{
              overflow: "hidden",
              width: "100%",
              position: "relative",

              aspectRatio: "4.255",
              backgroundImage: `url(${office8})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 50%",
            }}
          ></Box>
        </Box>
        {/* 하이딥 주요 복지  */}
        <CustomColorContainer>
          <PaddingColorBox>
            <EmptySpace height={matches ? "200px" : "27px"} />
            <Typography variant="careerWhiteTitle">
              {t("하이딥 주요 복지")}
            </Typography>
            <EmptySpace height={matches ? "100px" : "26px"} />

            <Grid
              container
              rowSpacing={0}
              columnSpacing={{ xs: 12, sm: 0, md: 0 }}
            >
              {welfaresm.map((v) => {
                return (
                  <Grid sm={12} md={4} item>
                    <Typography
                      variant="careerWhiteName"
                      sx={{
                        fontSize: "15px",
                        color: "#a5a5a5",
                        lineHeight: "1.0",
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      variant="careerWhiteName"
                      sx={{
                        lineHeight: 0.9,
                        padding: "0px 3px",
                        lineHeight: "1.0",
                      }}
                      style={{ lineHeight: 0.9 }}
                    >
                      {t(v.name)}
                    </Typography>
                    <Typography variant="carrerGray">{t(v.content)}</Typography>
                    <EmptySpace height={matches ? "40px" : "16px"} />
                  </Grid>
                );
              })}
            </Grid>
            <EmptySpace height={matches ? "100px" : "10px"} />
          </PaddingColorBox>
        </CustomColorContainer>
        {/* 워크  */}
        <EmptySpace height={matches ? "200px" : "100px"} />
        <Box sx={{}}>
          <CustomContainer>
            <PaddingBox>
              <Typography variant="careerQuote">
                "
                {t(
                  `여러 사람들의 다양한 관점과 인사이트를 통해\n새로운 방법을 찾는 짜릿한 여정에 당신을 초대합니다.`
                )}
              </Typography>
              <EmptySpace height={matches ? "0px" : "28px"} />
            </PaddingBox>
            <Grid container spacing={0.65}>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${work8})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${nameCard})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${work10})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>

        {/* 새 사진 그리드 */}
        <Box sx={{}}>
          <CustomContainer>
            <PaddingBox>
              <Box
                sx={{
                  height: "100%",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "start",
                  flexDirection: "column",
                }}
              >
                <EmptySpace height={matches ? "0px" : "98px"} />

                <Typography variant="careerQuote" sx={{ textAlign: "left" }}>
                  <Typography
                    variant="careerQuote"
                    sx={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    "
                  </Typography>
                  {t(
                    `하루의 에너지를 충전할 수 있고, 편리하고 쾌적한\n공간은 집중력 있는 업무에 도움이 됩니다.\n하이딥에서는 누구나 자유롭게 아이디어를 나누고\n빠르게 실행하는 문화를 만들어 갑니다.`
                  )}
                </Typography>
                <EmptySpace height={matches ? "0px" : "30px"} />
              </Box>
            </PaddingBox>
            <Grid container spacing={0.65}>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${new1})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${new2})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${new3})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${new4})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
              <Grid item xs={12} md={7}>
                {/* <ImgWidthFit src={test3} /> */}
                <Box
                  style={{
                    overflow: "hidden",
                    width: "100%",
                    position: "relative",

                    aspectRatio: "1.87 / 1",
                    backgroundImage: `url(${new5})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 50%",
                  }}
                ></Box>
              </Grid>
            </Grid>
          </CustomContainer>
        </Box>
        <EmptySpace height={matches ? "200px" : "100px"} />
        {/* 채용 정보 */}
        <ImgWidthFit src={new6}></ImgWidthFit>
        <CustomColorContainer>
          <PaddingColorBox>
            <EmptySpace height={matches ? "200px" : "40px"} />
            <Typography variant={"careerWhiteTitle2"}>
              {t("채용 정보")}
            </Typography>
            <EmptySpace height={matches ? "0px" : "40px"} />
            <Box>
              <Grid container spacing={0}>
                <Grid item xs={12} md={9}>
                  <Box>
                    {career &&
                      career
                        .slice(0)
                        .reverse()
                        .map((v, i) => {
                          if (lang === "ko" && !v.data.title) {
                            return <></>;
                          }
                          if (lang === "en" && !v.data.title_en) {
                            return <></>;
                          }
                          return (
                            <>
                              {itemsPerPage * careerPage >= i + 1 &&
                                itemsPerPage * careerPage - itemsPerPage <
                                  i + 1 && (
                                  <Accordion
                                    disableGutters={true}
                                    elevation={0}
                                    square={true}
                                    sx={{
                                      backgroundColor: "#1A1E24",
                                      "&.MuiAccordion-root": {
                                        borderTop: "1px solid #393c40",
                                        borderBottom: "1px solid #393c40",

                                        margin: "0 -10px",
                                      },
                                      "&.MuiAccordionSummar": {
                                        padding: "0",
                                        margin: "0",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      disableGutters={true}
                                      elevation={0}
                                      square={true}
                                      sx={{
                                        padding: "15px 0px ",
                                        margin: "0",

                                        "& .MuiAccordionSummary-content": {
                                          padding: "1px 0",
                                          margin: "0",
                                          lineHeight: 0.7,
                                        },
                                      }}
                                      expandIcon={
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontWeight: "bold",
                                            margin: "0",
                                            color: "#fff",
                                            marginRight: "10px",
                                          }}
                                        >
                                          ∨
                                        </Typography>
                                      }
                                      aria-controls="panel2a-content"
                                      id="panel2a-header"
                                    >
                                      <Typography
                                        sx={
                                          matches
                                            ? {
                                                padding: "0",
                                                margin: "0",
                                                fontWeight: "bold",
                                                color: "#a5a5a5",
                                                fontSize: "18px",
                                              }
                                            : {
                                                letterSpacing: "-1px",
                                                fontWeight: "700",
                                                color: "#c6c6c6",
                                                fontSize: "13px",
                                                padding: "0 10px",
                                              }
                                        }
                                      >
                                        {lang !== "ko" && v.data?.category_en
                                          ? v.data.category_en
                                          : v.data.category}
                                      </Typography>
                                      &nbsp; &nbsp;
                                      <Typography
                                        sx={
                                          matches
                                            ? {
                                                fontWeight: "bold",
                                                color: "white",
                                                fontSize: "18px",
                                              }
                                            : {
                                                letterSpacing: "-1px",
                                                fontWeight: "700",
                                                color: "#fff",
                                                fontSize: "13px",
                                              }
                                        }
                                      >
                                        {lang !== "ko" && v.data?.title_en
                                          ? v.data.title_en
                                          : v.data.title}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      sx={
                                        matches
                                          ? {
                                              padding: "30px 0px",
                                              fontWeight: "normal",
                                            }
                                          : { fontWeight: "normal" }
                                      }
                                    >
                                      <Viewer
                                        initialValue={
                                          lang !== "ko" && v.data?.contents_en
                                            ? v.data.contents_en
                                            : v.data.contents
                                        }
                                        plugins={[
                                          [
                                            codeSyntaxHighlight,
                                            { highlighter: Prism },
                                          ],
                                        ]}
                                        customHTMLRenderer={{
                                          htmlBlock: {
                                            iframe(node) {
                                              return [
                                                {
                                                  type: "openTag",
                                                  tagName: "iframe",
                                                  outerNewLine: true,
                                                  attributes: node.attrs,
                                                },
                                                {
                                                  type: "html",
                                                  content: node.childrenHTML,
                                                },
                                                {
                                                  type: "closeTag",
                                                  tagName: "iframe",
                                                  outerNewLine: false,
                                                },
                                              ];
                                            },
                                          },
                                        }}
                                      />
                                      {v.data.files &&
                                        v.data.files.map((f) => (
                                          <>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Link
                                                color={"#fff"}
                                                underline="none"
                                                download
                                                component="button"
                                                onClick={() => {
                                                  download(f);
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "flex-start",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <AttachFileIcon
                                                    sx={{
                                                      height: "22px",
                                                      mr: 3,
                                                    }}
                                                  />{" "}
                                                  <Typography
                                                    sx={{
                                                      fontWeight: "700",
                                                      fontSize: "10px",
                                                      marginRight: "15px",
                                                      color: "#fff",
                                                      minWidth: "44px",
                                                    }}
                                                  >
                                                    다운로드
                                                  </Typography>
                                                  <Typography
                                                    sx={{
                                                      fontSize: "10px",
                                                      marginRight: "15px",
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    |{" "}
                                                  </Typography>
                                                  {getFileName(f)}
                                                </Box>
                                              </Link>
                                            </Box>
                                          </>
                                        ))}
                                    </AccordionDetails>
                                  </Accordion>
                                )}
                            </>
                          );
                        })}
                  </Box>
                  <Box>
                    <EmptySpace height={"20px"} />
                    {career?.length > 10 && (
                      <nav style={{ marginLeft: "-11px" }}>
                        <List>
                          {items.map(
                            (
                              { page, type, selected, disabled, ...item },
                              index
                            ) => {
                              let children = null;

                              if (
                                type === "start-ellipsis" ||
                                type === "end-ellipsis"
                              ) {
                                children = "…";
                              } else if (type === "page") {
                                children = (
                                  <div
                                    style={{
                                      position: "relative",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        border: "0px solid white",
                                        background: "none",
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                        textDecoration: selected
                                          ? "underline"
                                          : undefined,
                                        textDecorationThickness: "1px",
                                        textUnderlinePosition: "under",
                                        fontSize: "15px",
                                        color: selected ? "#fff" : "#5f5f5f",
                                      }}
                                      {...item}
                                    >
                                      {page}
                                    </button>
                                  </div>
                                );
                              } else {
                                if (type === "previous") {
                                  children = (
                                    <button
                                      type="button"
                                      style={{
                                        display: disabled ? "none" : undefined,
                                        border: "0px solid white",
                                        background: "none",
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                        textDecoration: selected
                                          ? "underline"
                                          : undefined,
                                        textDecorationThickness: "1px",
                                        textUnderlinePosition: "under",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        color: "#fff",
                                      }}
                                      {...item}
                                    >
                                      {"<"}
                                    </button>
                                  );
                                } else if (type === "next") {
                                  children = (
                                    <button
                                      type="button"
                                      style={{
                                        display: disabled ? "none" : undefined,
                                        border: "0px solid white",
                                        background: "none",
                                        fontWeight: selected
                                          ? "bold"
                                          : undefined,
                                        textDecoration: selected
                                          ? "underline"
                                          : undefined,
                                        textDecorationThickness: "1px",
                                        textUnderlinePosition: "under",
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                        color: "#fff",
                                      }}
                                      {...item}
                                    >
                                      {">"}
                                    </button>
                                  );
                                } else {
                                  children = (
                                    <button type="button" {...item}>
                                      {type}
                                    </button>
                                  );
                                }
                              }

                              return <li key={index}>{children}</li>;
                            }
                          )}
                        </List>
                      </nav>
                    )}
                  </Box>
                </Grid>
              </Grid>

              {/* 라인 */}
              {/* 아코디언 */}
            </Box>
          </PaddingColorBox>
        </CustomColorContainer>
        <CustomColorContainer>
          <PaddingColorBox>
            <EmptySpace height={matches ? "100px" : "50px"} />

            <Box sx={{}}>
              <Grid container spacing={2}>
                {/* 지원방법 */}
                <Grid item xs={12} md={3}>
                  <EmptySpace height={matches ? "0px" : "60px"} />

                  <Typography variant={"careerWhiteTitle2"}>
                    {t("지원 방법")}
                  </Typography>
                  <EmptySpace height={matches ? "0px" : "24px"} />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant={"carrerGrayApply"}>
                    {t(
                      `궁금한 내용이 있으시면 편하게 연락 주세요.\n담당 \u00a0 |\u00a0  김경영 부장\n전화 \u00a0 | \u00a0 070-4164-7036\n이메일 \u00a0 | \u00a0 mariana.kim@hideep.com`
                    )}
                  </Typography>
                </Grid>
              </Grid>

              {/* 라인 */}
              {/* 아코디언 */}
            </Box>
          </PaddingColorBox>
          <EmptySpace height={matches ? "200px" : "105px"} />
        </CustomColorContainer>
      </React.Fragment>
    );
  }
  return matches ? <LargePage /> : <SmallPage />;
}

const FixedText = styled(Typography)(({ theme }) => ({
  fontFamily: "NanumBarunGothic",

  fontSize: "2.5vw",

  whiteSpace: "nowrap",
  wordBreak: "keep-all",
  lineHeight: "4.2vw",
  fontWeight: "700",
  letterSpacing: "-0.02vw",
}));

export default Company;

import React, { useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import axios from "axios";
import Main from "page/main";
import Company from "page/company";
import Tech from "page/tech";
import Careers from "page/careers";
// 20240601 Inverstor Relations 추가
import IR from "page/ir";
import IRDetail from "page/irDetail";

import News from "page/news";
import NewsDetail from "page/newsDeatail";

import Test from "page/test";

import Touch from "page/touch";
import D3dtouch from "page/3dtouch";
import Stylus from "page/stylus";

import Login from "page/admin/login";
import AdminMain from "page/admin/main";
// import Post from "page/admin/post";
import Reset from "page/admin/reset";

import CareerPost from "page/admin/careerPost";
import CareerEdit from "page/admin/careerEdit";

import CompanyPost from "page/admin/companyPost";
import CompanyEdit from "page/admin/companyEdit";

import NewsRoomPost from "page/admin/newsroomPost";
import NewsRoomEdit from "page/admin/newsroomEdit";

import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { AuthProvider, useAuth } from "authContext";

const GuestRoutes = (auth) => {
  const { isLogin } = useAuth();
  const lg = localStorage.getItem("auth");
  React.useEffect(() => {}, [lg]);

  // const token = sessionStorage.getItem("AuthToken");
  return (
    <Switch>
      <Route exact path="/" component={Main} />
      <Route exact path="/company" component={Company} />
      <Route exact path="/test" component={Test} />
      <Route exact path="/careers" component={Careers} />
      // 20240601 Inverstor Relations 추가
      <Route exact path="/ir" component={IR} />
      <Route exact path="/InvestorRelations" component={IR} />
      <Route exact path="/InvestorRelations/:id" component={IRDetail} />
      <Route exact path="/news/:id" component={NewsDetail} />
      <Route exact path="/newsroom" component={News} />
      <Route exact path="/technology" component={Tech} />
      <Route exact path="/touch" component={Touch} />
      <Route exact path="/3dtouch" component={D3dtouch} />
      <Route exact path="/stylus" component={Stylus} />
      <Route exact path="/login" component={Login} />
      {isLogin && <Route exact path="/adminmain" component={AdminMain} />}
      {isLogin && <Route exact path="/career/post" component={CareerPost} />}
      {isLogin && (
        <Route exact path="/career/post/:id" component={CareerEdit} />
      )}
      {isLogin && <Route exact path="/company/post" component={CompanyPost} />}
      {isLogin && (
        <Route exact path="/company/post/:id" component={CompanyEdit} />
      )}
      {isLogin && (
        <Route exact path="/newsroom/post" component={NewsRoomPost} />
      )}
      {isLogin && (
        <Route exact path="/newsroom/post/:id" component={NewsRoomEdit} />
      )}
      <Route exact path="/reset" component={Reset} />
      <Redirect from="*" to="/" />
    </Switch>
  );
};

const UserRoutes = () => {
  return (
    <Switch>
      {/* <Route exact path="/" component={UserHome} /> */}

      <Redirect from="*" to="/" />
    </Switch>
  );
};

const AppRouter = () => {
  return <GuestRoutes />;
};
export default AppRouter;
